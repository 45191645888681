/*
    概要：Admin.jsのユーザ一覧設定部分のコンポーネント
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect,memo} from 'react';
import { monotonicFactory } from 'ulid';
import {
    ListKanri,
    ListKanriDetail,
}
from '../../../ui-components';
import { ConvPhoneNumber } from "../../../hooks/misc/common";

export const UserSettingTable = memo((props) =>{
    const {
        InsertShow,
        setInsertShow,
        DeleteShow,
        setDeleteShow,
        EditShow,
        setEditShow,
        UserList,
        setEditShowAndArgs,
        setDeleteShowAndArgs
    } = props;

    const ulid = monotonicFactory()//ulidの生成
    const [UserListOpen, setUserListOpen] = useState({});//ユーザリストが開いているかどうかを管理する。
    const [update, setupdate] = useState(false);

    /*
        概要：トグルに指定の要素を追加する機能
    */
    const UserListOpenChange = (id, value) =>{
        setUserListOpen({...UserListOpen, [id]: value})
    }

    /*
        概要：トグルステートから指定の要素を削除する機能
    */
    const UserListOpenDelete = (id,OldUserListOpen) => {
        let UserListOpen = OldUserListOpen
        delete UserListOpen[id]
        setUserListOpen(UserListOpen)
        setupdate(!update)
    }

    /* 
        概要：ユーザの情報をリストで表示するコンポーネント
    */
    const UserListDisplay = () =>{

      return(
          UserList.map((value)=>{
              return(
                  <div key={ulid()}>
                      {/*ユーザ一覧画面の名前のみ*/}
                      <ListKanri
                          overrides = {
                              {
                                  "\u7AF9\u4E0B \u535A\u4E4B":{
                                      "children": value["name"]
                                  }
                              }
                          }
                          onClick = {()=>{UserListOpenChange(value.id, value)}}
                          width = {"90vw"}
                          marginBottom = {"4px"}
                          marginLeft   = {"auto"}
                          marginRight  = {"auto"}
                          key={ulid()}
                          style = {UserListOpen[[value.id]] ? {display:"none"}:{}}
                      />
                      {/*ユーザ一覧画面の名前、メールアドレス、電話番号、削除、編集*/}
                      <ListKanriDetail
                          overrides = {
                              {
                                  "\u7AF9\u4E0B \u535A\u4E4B":{//名前
                                      children: value["name"]
                                  },
                                  "take101@trafficsim.co.jp":{//メールアドレス
                                      children: value["mail"]
                                  },
                                  "090-3858-9402":{//電話番号
                                      children: ConvPhoneNumber(value["phone"])
                                  },
                                  "Frame 1953":{//削除
                                      onClick : () => {setDeleteShowAndArgs(true, value)}
                                  },
                                  "Frame 194":{//編集
                                      onClick: () => {setEditShowAndArgs(true, value)}
                                  },
                                  "Frame 1983":{//ListKanriDetailコンポーネントを閉じる
                                      onClick: () => {UserListOpenDelete(value.id, UserListOpen)}
                                  },
                                  "Frame 1984":{//ListKanriDetailコンポーネントを閉じる
                                      onClick: () => {UserListOpenDelete(value.id, UserListOpen)}
                                  },
                                  "Frame 1985":{//ListKanriDetailコンポーネントを閉じる
                                      onClick: () => {UserListOpenDelete(value.id, UserListOpen)}
                                  }
                              }
                          }
                          width = {"90vw"}
                          style = {UserListOpen[[value.id]] ? {display:"block", margin:"auto", marginBottom:"4px"}:{display:"none", margin:"auto", marginBottom:"4px"}}
                          key={ulid()}
                      />
                  </div>
              )
          })
  
      )
  }



  if (UserList) {
      return (
          <div>
              <UserListDisplay/>
          </div>
      );
  } 
  else {
      return null;
  }
})