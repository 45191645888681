/*
    概要：安否確認ページの備考欄 コンポーネント
*/

import React, { memo, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //←react-bootstrap
import {Form} from 'react-bootstrap';          //←react-bootstrap
import { RemarkText } from '../../../ui-components';
import { useReport } from '../../../hooks/providers/useReportProvider';

export const Remarks = memo((props) => {
    const { TextBox, setTextBox } = useReport();

    return (
        <Form style={{width:"100%"}}>
            <RemarkText
                style = {{marginBottom: "8px",marginLeft: "5%",marginTop: "8px"}}
            />
            <Form.Group className='lg' controlId="exampleForm.ControlInput1" style={{width: "90%", margin: "auto"}}>
                <Form.Control 
                    type="text"
                    as="textarea"
                    name="comment"
                    rows={2}
                    value={TextBox}
                    onChange={(e) => setTextBox(e.target.value)}
                    style={{width:"100%",fontFamily:"YuGothic",fontSize:"12px",fontWeight:"700"}}
                    maxLength="250"//入力文字数の制限
                />
            </Form.Group>
        </Form>
    )
})