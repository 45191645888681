/*Voozy使い方ページ*/
import React,{memo, useState} from "react";
import { HowtoDefault, HowtoSelect } from "../../../ui-components";
import { useHistory } from "react-router-dom";
export const HowtouseAdmin = memo(() => {
    return(
        <div style={{backgroundColor:"rgba(245,245,245,1)", width:"100%", height:"100vh"}}>
            <HowtouseList
                style={{marginTop:"24px"}}
            />
        </div>
    )
});

/*使い方の一覧部分の画面*/
const HowtouseList = memo(()=>{
    const PROPERTYIES = ["Kanri01", "Kanri02", "Kanri03"];
    return (
        <div style={{backgroundColor:"rgba(245,245,245,1)", width:"100%", paddingTop:"24px"}}>
            {PROPERTYIES.map((property) => (
                <HowtoSwitchComponent
                    key={property}
                    type = {property}
                />
            ))}
        </div>
    );
})

/*クリックされたら切り替えるコンポーネント*/
const HowtoSwitchComponent = memo((props)=>{
    const history = useHistory();
    const {type} = props;
    const [flag, setflag] = useState(false); 
    

    /*display切り替えfunction*/
    const DisplaySwitchFunc = (flag) => {
        if(flag){
            return "block";
        }
        else{
            return "none";
        }
    }

    return(
        <div>
            <HowtoDefault
                default = {type}
                margin="auto"
                width="90%"
                display = {DisplaySwitchFunc(!flag)}
                overrides = {
                    {
                        Frame:{
                            onClick:()=>{setflag(!flag);}
                        },
                        HowtoDefault:{
                            width: "90%"
                        }
                    }
                }
            />
            <HowtoSelect
                select = {type}
                margin="auto"
                width="90%"
                height= "auto"
                display = {DisplaySwitchFunc(flag)}
                overrides = {
                    {
                        Frame39243384:{
                            onClick:()=>{setflag(!flag);}
                        },
                        btnSousa:{
                            margin: "auto",
                            marginBottom: "17px",
                            onClick:()=>{history.push(ClickLinkFunc(type))}
                        },
                        //下の設定はAmplifyStudioに書いたほうがいいかもしれん。。
                        text39243390:{
                            marginBottom: "16px"
                        },
                        text39243385:{
                            marginBottom: "16px"
                        }
                    }
                }
            />
        </div>
    )
})


/*安否登録をするをクリックしたときの処理*/
// "/howtouse/admin/earthquakesetting"
// "/howtouse/admin/menbersetting"
// "/howtouse/admin/adminsubmit"
const ClickLinkFunc = (property) => {
    const PROPERTYIES = ["Kanri01", "Kanri02", "Kanri03"];
    const LINKPATH = ["/howtouse/admin/earthquakesetting", "/howtouse/admin/membersetting", "/howtouse/admin/adminsubmit"];
    switch(property){
        case PROPERTYIES[0]:
            return LINKPATH[0]
        case PROPERTYIES[1]:
            return LINKPATH[1]
        case PROPERTYIES[2]:
            return LINKPATH[2]
    }
}