/*
    概要：登録ボタン
    引数：
        CallBackFunc :ボタンをクリックしたときのコールバック関数
        omission     :入力漏れしているかどうかのフラグ
        text         :ボタン部分の文字
        args         :コールバック関数の引数
*/
import React, { memo } from 'react';
import { ErrorText, BtnText } from '../../../ui-components';

export const MultiButton = memo((props) => {
    const {CallBackFunc, omission, text, pattern, omissionText, ...args} = props;
    return (
        <div style = {{width:"100%", marginBottom:"8px"}}>
            <OmissionText
                omission     = {omission}
                omissionText = {omissionText}
            />
            <BtnText
                onClick={()=>{CallBackFunc(args)}}
                overrides = {
                    {
                        "text":{
                            children: text
                        }
                    }
                }
                style  = {{margin: "auto"}}
                width  = {"90%"}
                status = {pattern}
            />
        </div>
    )
});

/**コンポーネント群**********************************************************************************************************/
/*
    概要: 入力漏れがあったときに表示する文章コンポーネント
    引数：
        omission:入力漏れしたかどうかのフラグ
*/
const OmissionText = memo((props) => {
    const {omission, omissionText} = props;
    let text = ["入力に不備があります"];
    if(omissionText !== undefined)
    {
        if(omissionText.length > 0)
        {
            text = "";
            omissionText.forEach(function(omissiontex){
                text += omissiontex+"\n";
            })        
        }
    }

    const displayOn  = 1;
    const displayOff = 0;
    let opacityCSSparam;
    if(omission)
    {
        opacityCSSparam = displayOn
    }
    else
    {
        opacityCSSparam = displayOff
    }

    return (
        <ErrorText
            overrides = {
                {
                    "\u5165\u529B\u306B\u4E0D\u5099\u304C\u3042\u308A\u307E\u3059":{
                        children: text,
                        wordwrap: "break-word",
                        height  : "unset",
                        position: "unset"
                    }
                }
            }
            opacity    = {opacityCSSparam}
            width      = {"90%"}
            marginLeft = {"Auto"}
            marginRight= {"Auto"}
            height     = {"fit-content"}
        />
    )        
});

