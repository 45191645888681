/*
  概要：Voozy使い方のヘッダ
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React,{ memo, useEffect } from "react";
import { HeaderHowtoKanri, HeaderHowtoUser } from "../../../ui-components";
import { useLocation, useHistory } from "react-router-dom";

export const HeaderHowtouse = memo(() => {
  const history    = useHistory();
  const {pathname} = useLocation();
  const ADMINPATH  = "/admin";
  const USERPATH   = "/home";
  let HeaderHowtoComponent = null;//初期化

  if(pathname.includes(ADMINPATH)){
    HeaderHowtoComponent  = 
    <HeaderHowtoKanri
      width="100%"
      overrides = {
        {
          "MyIcon39313515":{
            onClick: () => {history.push("/")}
          },
          "text39313523": {
            onClick: () => {history.push("/howtouse/home/")},
          },
          "text39313521": {
            onClick: () => {history.push("/howtouse/admin/")}
          }
        }
      }
    />
  }
  else if(pathname.includes(USERPATH)){
    HeaderHowtoComponent = 
    <HeaderHowtoUser
      width="100%"
      overrides = {
        {
          "MyIcon39313488":{
            onClick: () => {history.push("/")}
          },
          "text39313494": {
            onClick: () => {history.push("/howtouse/home/")},
          },
          "text39313496": {
            onClick: () => {history.push("/howtouse/admin/")}
          }
        }
      }
    />
  }
  return(
    <div>
      {HeaderHowtoComponent}
    </div>
  );

});