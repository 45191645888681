/* eslint-disable react-hooks/exhaustive-deps */

/*
    概要：安否結果ページ表示時に実行する関数
*/

import { useCallback, useState } from "react";
import { API, graphqlOperation} from 'aws-amplify';
import { listAnswers} from "../../src/graphql/queries";
import { useMessage } from "./useMessage";
import { useResult } from "./providers/useResultProvider";
import { useOtherData } from "./providers/useOtherDataProvider";
import { Auth } from 'aws-amplify';
import { fetchCurrentUserInfo, fetchListUsers, fetchListCustomQuestion, fetchListEarthquakes, fetchListCustoms } from "./misc/common";
import { useLoginUser } from "../hooks/providers/useLoginUserProvider";
import { useHistory } from 'react-router-dom';
import { adminChcker } from "../hooks/misc/common";

//stateだと反映されるのが遅いため変数に入れています
let tmplistAnswer      = null; // 回答情報を一時保存
let tmpearthquake      = null; // 地震情報を一時保存
let tmplistUserSetting = null; // ユーザ情報を一時保存
let tmpCustomQuestions = null; // 質問情報を一時保存
let listAnswerInput    = null; //

const MAX_FETCH = 2; //Fetchを行う回数の最大
let   fetch_num = 0; //Fetchを実行した回数

export const useSafetyResultData = () => {
    const history = useHistory();
    const { showMessage } = useMessage();
    const [listAnswer, setlistAnswer] = useState([]);//回答内容
    const {listUserSetting     , setlistUserSetting     ,  //全ユーザ情報
           listEarthquake      , setlistEarthquake      ,  //地震情報情報
           TimeFilteredReports , setTimeFilteredReports    //時間でフィルターされた回答結果が入っている
    } = useResult();
    const [loading, setLoading] = useState(false);//loadingを管理
    const [open, setopen] = useState(false);
    const {listQuestion, setlistQuestion} = useOtherData();
    const { loginUser, setLoginUser } = useLoginUser();
    const MaxList = 5;
    

    /*
        概要：安否結果ページを表示する前に実行する関数
    */
    const GetData = useCallback(async() => {
        //Loading画面の表示
        setLoading(true);
        // 初期化
        fetch_num = 0;

        // Authの認証ユーザの情報を取得
        let   resCurrentAuthUser = await Auth.currentAuthenticatedUser();
        const               mail = resCurrentAuthUser.attributes.email;
        
        if(resCurrentAuthUser === null)
        {
            showMessage({message:"不明なユーザ"});
            // Loading画面の終了
            setLoading(false);
            // 画面を戻る
            history.push("/");
            return;
        }
        else
        {
            await FetchData(mail, MaxList);
            
            
        }
    })


    /*
        概要：データ取得
    */
    const FetchData = async(mail, MaxList) => {
        let do_final_flag = false;
        
        await Promise.all([
            fetchListCustomQuestion(),     // 質問項目の取得
            fetchCurrentUserInfo(mail),    // ユーザ情報の取得
            fetchListUsers(),              // ユーザ一覧リストを取得する
            fetchListEarthquakes(MaxList), // 地震情報をリストで取得する
            fetchListCustoms(MaxList)      // カスタム情報をリストで取得する
        ]).then((response)=>{
            console.log(response);
            do_final_flag = true;
            let [listQuestions, userInfo, listUseInfo, listEarthquakes, listCustoms] = response;
            
            // 管理者であるかどうかの確認処理
            const checkedRet = adminChcker(loginUser);
            if(checkedRet.status === "unknown")
            {
                showMessage({message:checkedRet.message});
                history.push("/");
                console.log("unknown")
            }


            // 質問項目の取得
            tmpCustomQuestions = listQuestions.data.listCustomQuestion;
            setlistQuestion(listQuestions.data.listCustomQuestion);
            
            // ユーザ情報の取得
            setLoginUser(userInfo.data.getUserSetting);

            // ユーザリストの取得
            tmplistUserSetting = listUseInfo.data.listUserSettinglight;
            setlistUserSetting(listUseInfo.data.listUserSettinglight);

            // 上位5件分の通知データの取得
            setTopSliceData(listEarthquakes, listCustoms);

        }).catch((e) => {
            do_final_flag = false; 
            //エラーが発生したときにはアラートバーを表示する。
            console.error(e);
            let message_text = "Invoke API Error";

            if(typeof e.errors !== "undefined")
            {
                if((fetch_num < MAX_FETCH)&&
                (e.errors[0].errorType === "Lambda:Unhandled")&&
                (e.errors[0].message   === "An error occurred (BadRequestException) when calling the ExecuteStatement operation: Communications link failure\n\nThe last packet sent successfully to the server was 0 milliseconds ago. The driver has not received any packets from the server.; SQLState: 08S01"))
                {
                    fetch_num++;
                    setTimeout(()=>{FetchData(mail, MaxList)}, 4000);//40秒待つように変更する
                    message_text = "Start DB Running";
                }
            }
        
            showMessage({message:message_text});

        }).finally(()=>{
            if(do_final_flag)
            {
                //回答結果リストを取得する
                listAnswerInput = {"time": tmpearthquake[tmpearthquake.length-1]["datetime"]};
            }
        });


        if(do_final_flag)
        {
            await API.graphql(graphqlOperation(listAnswers, listAnswerInput))
            .then((data)=>{
                tmplistAnswer = data.data.listAnswers;
                setlistAnswer(data.data.listAnswers)
            }).catch((e)=>{
                //エラーが発生したときにはアラートバーを表示する。
                console.error(e);
                showMessage({message: "Invoke API Error"});    
            }).finally(()=>{
                let TimeFilterReports = null;
                //地震の発生時間でユーザの回答内容にフィルタを掛ける
                TimeFilterReports = TimeFilterReport(tmplistAnswer, tmpearthquake);
                setTimeFilteredReports(TimeFilterReports)
            
                setopen(true)
                //Loading画面の終了
                setLoading(false);
            })
        }  
    }



    /*
        概要：地震情報とカスタムデータを保存します
    */
    const setTopSliceData = (listEarthquakes, listCustoms) => {
        let AllListData = [];
        const SHOWMAX   = 5;

        //AllListDataに保存
        AllListData = AllListData.concat(listEarthquakes.data.listEarthquakes);
        //parseしてAllListDataに保存
        listCustoms.data.listCustoms.forEach(function(value, index){
            listCustoms.data.listCustoms[index].precautions = JSON.parse(JSON.parse(value.precautions));
        })
        AllListData = AllListData.concat(listCustoms.data.listCustoms);
        //datetimeの値に従ってソートする
        AllListData.sort((a, b) => b.datetime - a.datetime);
        //上から5件分だけ返す
        AllListData = AllListData.slice(0, SHOWMAX);
        tmpearthquake = AllListData;
        setlistEarthquake(AllListData);
    }


    /*
        概要：
            存在しない場合は-1を返します。
            それ以外の場合は存在するindexの番号を返します。
        引数：
            userId : ユーザID
            report : 回答内容の連想配列
    */
    const serchIndexFunc = (userId, summary, report) => {
        const serchIndex = report.findIndex(
            (repo) => repo.userId === userId && repo.summary !== summary
        );
        return serchIndex;
    }


    /*
        概要：地震の発生時間でユーザの回答内容にフィルタを掛けます
        戻り値：
          [
            {
                id      : 20221225202336
                datetime: 20221225202336,
                report  : [
                    {answer: "3", comment: null, createdAt: "20221230113725", id: "01GNGE899B8YHYZHN3H9517MM3", name: "竹下博之", question: "会社の状況を確認できますか？", questionId: "2", summary: "01GNGE899ADB5QB8ZNF9SQENKX", text: "確認可能", userId: "1"},
                    {answer: "1", comment: null, createdAt: "20221230113725", id: "01GNGE899B8YHYZHN3H9517MM2", name: "竹下博之", question: "安否報告をしてください"      , questionId: "1", summary: "01GNGE899ADB5QB8ZNF9SQENKX", text: "無事"   , userId: "1"}
                ]
            }
          ]
    */
    const TimeFilterReport= (listAnswer, earthquake) =>{//引数：datetime;地震の発生時間が書いてある。EarthquakeInfo;地震情報が書いてある。AnswerList;回答時間が書いてある。
        let TimeFilteredReports = []
        earthquake.forEach(function(Earthquake, indexEarthquake){//earthquake//最新のやつから出てる、0から出てる

            let TimeFilteredReport = {
                id      : Earthquake.id,
                datetime: Earthquake.datetime,
                report  : []
            }

                 
            listAnswer.forEach(function(Answer){
                if( indexEarthquake > 0){
                    if((Number(earthquake[indexEarthquake-1].datetime) > Number(Answer.createdAt))&&(Number(Answer.createdAt) >= Number(earthquake[indexEarthquake].datetime))){
                        //TimeFilterReportsの同じ人の回答が時間内に複数ある場合最新のものだけ入れます。
                        if(serchIndexFunc(Answer.userId, Answer.summary, TimeFilteredReport.report) === -1)
                        {
                            TimeFilteredReport.report.push(Answer)
                        }
                    }
                }
                else if(indexEarthquake === 0){
                    if(Number(Answer.createdAt) >= Number(earthquake[indexEarthquake].datetime)){
                        //TimeFilterReportsの同じ人の回答が時間内に複数ある場合最新のものだけ入れます。
                        if(serchIndexFunc(Answer.userId, Answer.summary, TimeFilteredReport.report) === -1)
                        {
                            TimeFilteredReport.report.push(Answer)
                        }
                    }
                }
            })

            TimeFilteredReports.push(TimeFilteredReport);

        })
        return TimeFilteredReports
    }


    return { GetData, listAnswer, listEarthquake, listUserSetting, TimeFilteredReports, listQuestion, loading, open};
};

