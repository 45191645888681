/*
  概要：Loginページのコンポーネント
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import {Auth,Hub, Amplify} from 'aws-amplify';
import { useStateIfMounted } from "use-state-if-mounted";
import { useHistory, useLocation } from "react-router-dom";
import { useMessage } from "../../../hooks/useMessage";
import awsmobile from "../../../modules/amplify/awsmobile";
Amplify.configure(awsmobile);

export const Login = memo(() => {
  const { showMessage } = useMessage(); 
  const queryparam = useLocation().search
  const history = useHistory();
  const [user, setUser] = useStateIfMounted(false);/*React Memory leak メモリリーク対策*/
  
  /*
    概要：ログイン済みかどうかを判定する
  */
  const getLoginInfo =async()=>{
    // ユーザがログイン済みかの確認
    const userData = await Auth.currentAuthenticatedUser();
  
    // ログイン済みならば安否報告ページへと遷移する
    if(userData){
        history.push("/safetyconfirm")
    }
  }

  useEffect(()=>{
    getLoginInfo();
  },[])

  //useEffectはコンポーネントの初期化中のマウント後に外部からデータを取得する際に利用することができる。
  useEffect(() => {
      Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
          case 'signIn':
              getUser().then((data)=>{
                  if(data){
                      history.push("/safetyconfirm");
                  }
              });
              break;
              
          case 'cognitoHostedUI':
              getUser().then(userData => setUser(userData));
              break;

          case 'signOut':
              setUser(null);
              break;

          case 'signIn_failure':
              break;

          case 'cognitoHostedUI_failure':
              console.log('Sign in failure', data);
              break;

          default:
              break;
            
      }
      });
  }, []);

  /*
    概要：指定のドメインでログインしなかった場合にエラーを表示する
  */
  useEffect(()=>{
    if(queryparam.includes('error_description=PreAuthentication+failed+with+error+Invalid+email')){
        showMessage({message:"許可されていないメールアドレス"})
    }
  },[queryparam])

  /*
    概要：Authからデータを取得してsetUserする
  */
  const getUser = async () => {
      try 
      {
          const userData = await Auth.currentAuthenticatedUser();
          return userData
      } 
      catch (e) 
      {
          return null;
      }
  }

  const GoogleButtonClick = () => {
    Auth.federatedSignIn({ customProvider: "Google" })
  }

  return(
    <div style={{backgroundColor:"#F5F5F5", display:"flex", flexDirection: "column",minHeight: "100%", height: "100vh"}}>
      <div style = {{display: "block", marginTop: "100px", marginLeft: "0%"}}>
          <button style = {{backgroundColor: "white", width: "100%", height: "100px", border: "none", fontFamily: "Yugothic", fontWeight: "700", color: '#2B85FF'}} onClick = {GoogleButtonClick}>
              <svg style = {{marginRight: "5px"}} aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z" fill="#EA4335"></path></svg>
              Log in with Google
          </button>
      </div>
    </div>
  )
})

