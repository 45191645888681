/* eslint-disable react-hooks/exhaustive-deps */

/*
    概要：安否回答ページ表示時に実行する関数
*/
import { useCallback, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useMessage   } from "../hooks/useMessage";
import { useOtherData } from "../hooks/providers/useOtherDataProvider";
import { useReport    } from "./providers/useReportProvider";
import { fetchCurrentUserInfo, fetchLatestEarthquake, fetchLatestCustom, fetchListCustomQuestion} from "./misc/common";
import { useLoginUser } from "../hooks/providers/useLoginUserProvider";
import { Auth } from 'aws-amplify';
import { adminChcker } from "../hooks/misc/common";

const MAX_FETCH = 2; //Fetchを行う回数の最大
let   fetch_num = 0; //Fetchを実行した回数

export const useSafeConfrimData = () => {
    const history = useHistory();
    const { listQuestion, setlistQuestion, }  = useOtherData(); //Questionテーブルから取得したデータ
    const {
        LatestEarthquakeInfo, setLatestEarthquakeInfo,          //最新地震情報
        Custommode, setCustommode                               //Customデータかどうかを判断する
    } = useReport();       
    const [loading, setLoading]       = useState(false);        //loadingを管理
    const [open, setopen]             = useState(false);        //データがそろったかどうかを確認する
    const { showMessage }             = useMessage();
    const { loginUser, setLoginUser } = useLoginUser();

    /*
        概要：データの取得
    */
    const GetData = async() =>{
        // Loading画面の表示
        setLoading(true);
        // 初期化
        fetch_num = 0;

        // Authの認証ユーザの情報を取得
        let   resCurrentAuthUser = await Auth.currentAuthenticatedUser();
        const               mail = resCurrentAuthUser.attributes.email;
        
        if(resCurrentAuthUser === null)
        {
            showMessage({message:"不明なユーザ"});
            // Loading画面の終了
            setLoading(false);
            // 画面を戻る
            history.push("/");
            // 終了
            return;
        }
        else
        {
            await FatchData(mail);
        } 
    }

    /*
        概要: データベースからデータを取得する失敗すればリトライを行う。
    */
    const FatchData = async(mail) => {
        let do_final_flag = false;
        
        await Promise.all([
            fetchCurrentUserInfo(mail),  // ユーザ情報の取得
            fetchLatestEarthquake(),     // Earthquakeテーブルからデータを取得
            fetchLatestCustom(),         // Customテーブルからデータを取得
            fetchListCustomQuestion()    // Questionからデータを取得する関数
        ]).then((response) => {
            console.log(response);
            let [userInfo, earthquakeData, customData, questionData] = response;

            // 管理者であるかどうかの確認処理
            const checkedRet = adminChcker(loginUser);
            if(checkedRet.status === "unknown")
            {
                showMessage({message:checkedRet.message});
                history.push("/");
                return;
            }
    
            // ユーザ情報を取得
            if (userInfo.data.getUserSetting !== null)
            {
                setLoginUser(userInfo.data.getUserSetting);
            }

            // タイトルの情報を取得します       
            setTitleInfo(earthquakeData, customData);
            setQuestion(questionData);
            
            do_final_flag = true;
            
        }).catch((e)=>{
            console.log(e);
            console.log(e.errors[0].errorType);
            console.log(e.errors[0].message);
            let message_text = "Invoke API Error";
            
            if(typeof e.errors !== "undefined")
            {
                console.log("fetch_num");
                console.log(fetch_num);
                if(( fetch_num < MAX_FETCH)&&
                (e.errors[0].errorType === "Lambda:Unhandled")&&
                (e.errors[0].message   === "An error occurred (BadRequestException) when calling the ExecuteStatement operation: Communications link failure\n\nThe last packet sent successfully to the server was 0 milliseconds ago. The driver has not received any packets from the server.; SQLState: 08S01"))
                {
                    fetch_num++;
                    // 特定のエラーが発生した場合の処理
                    setTimeout(()=>{FatchData(mail)}, 40000);
                    message_text = "Start DB Running";
                    
                }
            }
  
            showMessage({message:message_text});
    
        }).finally(()=>{

            if(do_final_flag)
            {
                //データがそろったときに処理
                setopen(true);

                //Loading画面の終了
                setLoading(false);
            }
        })
    }

       

    /*
        概要：Customテーブルからデータを取得する関数
    */
    const setTitleInfo = (earthquakeData, customData) => {
        let customFlag   = false;
        let titleInfo    = null;
        earthquakeData   = earthquakeData.data.listEarthquakeOne[0];

        //undefinedだった時の処理
        if(earthquakeData === undefined){
            earthquakeData             = {};
            earthquakeData["id"]       = 0;
            earthquakeData["datetime"] = 0;
        }

        customData     = customData.data.listCustoms[0]
        //undefinedだった時の処理
        if(customData === undefined){
            customData                = {}
            customData["id"]          = 0;
            customData["datetime"]    = 0;
            customData["precautions"] = null
        }

        //datetimeを比較。customFlagを立てる
        if(earthquakeData.datetime > customData.datetime){
            customFlag = false
            titleInfo = earthquakeData
        }
        else{
            //Customテーブルから取得したデータが2回jsonStringfyしたような形で取得されてしまうのでjson.parseする
            customFlag = true
            customData.precautions = JSON.parse(JSON.parse(customData.precautions))
            titleInfo = customData
        }

        //stateに保存
        setLatestEarthquakeInfo(titleInfo)
        setCustommode(customFlag)
    }

    /*
      概要：Questionから取得したデータを登録します
    */
    const setQuestion = (questionData) => {
        //Buttonsdictのデータとくっつけます
        let tmpQuestionData = questionData.data.listCustomQuestion;
        tmpQuestionData.forEach(function(Question, QuestionIndex){
            Question.answerPattern.forEach(function(answer, answerIndex){
                let dictIndex = serchIndexFunc(answer.detailId, Buttonsdict);
                Object.assign(tmpQuestionData[QuestionIndex].answerPattern[answerIndex], Buttonsdict[dictIndex]);
            })
        })

        //Questionテーブルのデータを保存
        setlistQuestion(tmpQuestionData);
    }
    
    return { GetData, setLoading, loading, LatestEarthquakeInfo, Custommode, listQuestion, open };
};

/*関数*****************************************************************************************************************************/
/*
    概要：
        存在しない場合は-1を返します。
        それ以外の場合はindex番号を返します。
*/
const serchIndexFunc = (detailid, Buttonsdict) => {
    const serchIndex = Buttonsdict.findIndex(
        (button) => button.id === detailid
    );
    return serchIndex;
}




/*辞書***************************************************************************************************************************/
/*
    ボタンの色の辞書ファイル
*/
const Buttonsdict = [
    {
        id             : "1"        ,
        color          : "#01B985"  ,
        icon           : "Buji"     ,
        Buttonid       : "1"        ,
        NextQuestionId : "2"        
    },
    {
        id             : "2"        ,
        color          : "#FF4C6C"  ,
        icon           : "Higai"    ,
        Buttonid       : "2"        ,
        NextQuestionId : "3"        
    },
    {
        id             : "3"        ,
        color          : "#01B985"  ,
        icon           : "eye"      , 
        Buttonid       : "3"        ,
        NextQuestionId : null        
    },
    {
        id             : "4"        ,
        color          : "#FF4C6C"  ,
        icon           : "eye-slash",
        Buttonid       : "4"        ,
        NextQuestionId : null           
    },
    {
        id             : "5"        ,
        color          : "#FF4C6C"  ,
        icon           : null       ,
        Buttonid       : "5"        ,
        NextQuestionId : null          
    },
    {
        id             : "6"        ,
        color          : "#FF4C6C"  ,
        icon           : null       ,
        Buttonid       : "6"        ,
        NextQuestionId : null       
    },
    {
        id             : "7"        ,
        color          : "#FF4C6C"  ,
        icon           : null       , 
        Buttonid       : "7"        ,
        NextQuestionId : null       
    }
]
