/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function HeaderHowtoUser(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="16px 0px 8px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "HeaderHowtoUser")}
    >
      <Flex
        gap="26px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 16px 0px 16px"
        {...getOverrideProps(overrides, "top")}
      >
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="angle-left"
          {...getOverrideProps(overrides, "MyIcon39313488")}
        ></MyIcon>
        <Text
          fontFamily="``"
          fontSize="14px"
          fontWeight="``"
          color="rgba(0,0,0,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="18px"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Voozyの使い方"
          {...getOverrideProps(overrides, "text39313489")}
        ></Text>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="xmark02"
          {...getOverrideProps(overrides, "MyIcon39313490")}
        ></MyIcon>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(245,245,245,1)"
        {...getOverrideProps(overrides, "Rectangle 44")}
      ></View>
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 98px 0px 98px"
        {...getOverrideProps(overrides, "Frame 2003")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="60px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 187")}
        >
          <Text
            fontFamily="``"
            fontSize="12px"
            fontWeight="``"
            color="rgba(0,0,0,1)"
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="60px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="25%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="ユーザー"
            {...getOverrideProps(overrides, "text39313494")}
          ></Text>
          <Icon
            width="4px"
            height="4px"
            viewBox={{ minX: 0, minY: 0, width: 4, height: 4 }}
            paths={[
              {
                d: "M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z",
                fill: "rgba(43,133,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="20px"
            left="28px"
            {...getOverrideProps(overrides, "Ellipse 7")}
          ></Icon>
        </Flex>
        <Text
          fontFamily="``"
          fontSize="12px"
          fontWeight="``"
          color="rgba(160,160,160,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="60px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="管理者"
          {...getOverrideProps(overrides, "text39313496")}
        ></Text>
      </Flex>
    </Flex>
  );
}
