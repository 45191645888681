/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function Header(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        MyIcon: {},
        text46524879: {},
        "Ellipse 746524880": {},
        "Frame 2016": {},
        text46524882: {},
        "Ellipse 746524883": {},
        "Frame 2017": {},
        "Frame 2018": {},
        "Frame 2021": {},
        Header: {},
      },
      variantValues: { status: "Anpi" },
    },
    {
      overrides: {
        MyIcon: {},
        text46524879: { color: "rgba(160,160,160,1)" },
        "Ellipse 746524880": { display: "none" },
        "Frame 2016": {},
        text46524882: { color: "rgba(0,0,0,1)" },
        "Ellipse 746524883": { display: "block" },
        "Frame 2017": {},
        "Frame 2018": {},
        "Frame 2021": {},
        Header: {},
      },
      variantValues: { status: "Jokyo" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="60px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      padding="16px 16px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Header")}
    >
      <MyIcon
        width="20px"
        height="20px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="menu"
        {...getOverrideProps(overrides, "MyIcon")}
      ></MyIcon>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 2018")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 2016")}
        >
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(0,0,0,1)"
            lineHeight="12px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="60px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="安否登録"
            {...getOverrideProps(overrides, "text46524879")}
          ></Text>
          <Icon
            width="4px"
            height="4px"
            viewBox={{ minX: 0, minY: 0, width: 4, height: 4 }}
            paths={[
              {
                d: "M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z",
                fill: "rgba(43,133,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Ellipse 746524880")}
          ></Icon>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 2017")}
        >
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(160,160,160,1)"
            lineHeight="12px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="60px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="状況確認"
            {...getOverrideProps(overrides, "text46524882")}
          ></Text>
          <Icon
            width="4px"
            height="4px"
            viewBox={{ minX: 0, minY: 0, width: 4, height: 4 }}
            paths={[
              {
                d: "M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z",
                fill: "rgba(43,133,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Ellipse 746524883")}
          ></Icon>
        </Flex>
      </Flex>
      <View
        width="20px"
        height="20px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Frame 2021")}
      ></View>
    </Flex>
  );
}
