/*
  概要：安否確認項目送信ページで使用するuseContext
*/
import React, { createContext, useContext, useState } from "react";
import { monotonicFactory } from 'ulid';
const AdminContext = createContext({});

export const AdminProvider = (props) => {
    const ulid = monotonicFactory()//ulidの生成 
    const { children }                = props;
    const [InsertShow, setInsertShow] = useState(false); //InsertModalの表示、非表示の管理
    const [DeleteShow, setDeleteShow] = useState(false); //DeleteModalの表示、非表示の管理
    const [EditShow  , setEditShow  ] = useState(false); //EditModalの表示、非表示の管理
    const [InsertKey , setInsertKey ] = useState(ulid());
    const [EditKey   , setEditKey   ] = useState(ulid());
    const [EditSetting  , setEditSetting  ] = useState("");//EditUserSettingに渡す引数//リストでパラメータが入っている
    const [DeleteSetting, setDeleteSetting] = useState("");//DeleteUserSettingに渡す引数//idが入っている
    const [EarthquakeIntensitySelectShow, setEarthquakeIntensitySelectShow] = useState(false);//EarthquakeIntensitySelectModalの表示、非表示の管理
    const [ObservationSelectShow, setObservationSelectShow] = useState(false);//observationSelectModalの表示、非表示の管理

    const value = {
        InsertShow                   , setInsertShow    ,
        DeleteShow                   , setDeleteShow    ,
        EditShow                     , setEditShow      ,
        InsertKey                    , setInsertKey     ,
        EditKey                      , setEditKey       ,
        DeleteSetting                , setDeleteSetting ,
        EditSetting                  , setEditSetting   ,
        EarthquakeIntensitySelectShow, setEarthquakeIntensitySelectShow,
        ObservationSelectShow        , setObservationSelectShow
    }

    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdminContext = () => useContext(AdminContext);