/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function HowtoExplain(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, HowtoExplain: {} },
      variantValues: { explain: "User01" },
    },
    {
      overrides: {
        text: { children: "\u5B89\u5426\u767B\u9332\u30DC\u30BF\u30F3" },
        HowtoExplain: { width: "116px" },
      },
      variantValues: { explain: "User02" },
    },
    {
      overrides: {
        text: { children: "\u5099\u8003\u5165\u529B\u6B04" },
        HowtoExplain: { width: "92px" },
      },
      variantValues: { explain: "User03" },
    },
    {
      overrides: {
        text: { children: "\u767B\u9332\u30DC\u30BF\u30F3" },
        HowtoExplain: { width: "92px" },
      },
      variantValues: { explain: "User04" },
    },
    {
      overrides: {
        text: {
          children:
            "\u6700\u65B0\u306E\u88AB\u5BB3\u72B6\u6CC1\u304C\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "User05" },
    },
    {
      overrides: {
        text: {
          children:
            "\u904E\u53BB\u306E\u88AB\u5BB3\u72B6\u6CC1\u304C\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "User06" },
    },
    {
      overrides: {
        text: {
          children:
            "\u88AB\u5BB3\u72B6\u6CC1\u306E\u56DE\u7B54\u4EBA\u6570\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "User07" },
    },
    {
      overrides: {
        text: {
          children:
            "\u5730\u9707\u306E\u767A\u751F\u65E5\u6642\u3068\u898F\u6A21\u304C\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: { width: "247px" },
      },
      variantValues: { explain: "User08" },
    },
    {
      overrides: {
        text: {
          children:
            "\u56DE\u7B54\u8005\u6570\u304C\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: { width: "164px" },
      },
      variantValues: { explain: "User09" },
    },
    {
      overrides: {
        text: {
          children:
            "\u793E\u54E1\u4E00\u89A7\u3068\u56DE\u7B54\u72B6\u6CC1\u304C\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "User10" },
    },
    {
      overrides: {
        text: {
          children:
            "\u78BA\u8A8D\u3057\u305F\u3044\u56DE\u7B54\u72B6\u6CC1\u3092\u9078\u629E\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "User11" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u88AB\u5BB3\u72B6\u6CC1\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "User12" },
    },
    {
      overrides: {
        text: {
          children:
            "\u88AB\u5BB3\u72B6\u6CC1\u304C\u30A2\u30A4\u30B3\u30F3\u3067\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "User13" },
    },
    {
      overrides: {
        text: {
          children:
            "\u4F1A\u793E\u306E\u72B6\u6CC1\u3092\u78BA\u8A8D\u3067\u304D\u308B\u304B\u30A2\u30A4\u30B3\u30F3\u3067\u8868\u793A\u3055\u308C\u307E\u3059",
        },
        HowtoExplain: { width: "308px" },
      },
      variantValues: { explain: "User14" },
    },
    {
      overrides: {
        text: {
          children:
            "\u5099\u8003\u6B04\u306B\u5165\u529B\u3055\u308C\u305F\u30B3\u30E1\u30F3\u30C8\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "272px" },
      },
      variantValues: { explain: "User15" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u767B\u9332\u5185\u5BB9\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "Kanri05" },
    },
    {
      overrides: {
        text: {
          children:
            "\u767B\u9332\u3057\u305F\u30E1\u30F3\u30D0\u30FC\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "212px" },
      },
      variantValues: { explain: "Kanri04" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E6\u30FC\u30B6\u30FC\u767B\u9332\u30DA\u30FC\u30B8\u306B\u9032\u307F\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "Kanri03" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u767B\u9332\u5185\u5BB9\u3092\u7DE8\u96C6\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "224px" },
      },
      variantValues: { explain: "Kanri07" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u767B\u9332\u3092\u524A\u9664\u3057\u307E\u3059",
        },
        HowtoExplain: { width: "188px" },
      },
      variantValues: { explain: "Kanri06" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306B\u901A\u77E5\u3059\u308B\u6700\u4F4E\u306E\u9707\u5EA6\u3092\u8A2D\u5B9A\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "284px" },
      },
      variantValues: { explain: "Kanri01" },
    },
    {
      overrides: {
        text: {
          children:
            "\u7BA1\u7406\u8005\u306F\u3053\u3061\u3089\u3067\u9078\u629E\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "Kanri09" },
    },
    {
      overrides: {
        text: {
          children:
            "\u767B\u9332\u306B\u5FC5\u8981\u306A\u60C5\u5831\u3092\u5165\u529B\u3057\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "Kanri08" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306B\u901A\u77E5\u3059\u308B\u89B3\u6E2C\u5730\u3092\u8A2D\u5B9A\u3067\u304D\u307E\u3059",
        },
        HowtoExplain: { width: "260px" },
      },
      variantValues: { explain: "Kanri02" },
    },
    {
      overrides: {
        text: {
          children:
            "\u8FFD\u52A0\u3057\u305F\u6CE8\u610F\u4E8B\u9805\u3092\u524A\u9664\u3057\u307E\u3059",
        },
        HowtoExplain: {},
      },
      variantValues: { explain: "Kanri13" },
    },
    {
      overrides: {
        text: {
          children:
            "\u6CE8\u610F\u4E8B\u9805\u3092\u8FFD\u52A0\u3057\u307E\u3059",
        },
        HowtoExplain: { width: "152px" },
      },
      variantValues: { explain: "Kanri11" },
    },
    {
      overrides: {
        text: {
          children:
            "\u5B89\u5426\u9805\u76EE\u306E\u30BF\u30A4\u30C8\u30EB\u3092\u5165\u529B\u3057\u307E\u3059",
        },
        HowtoExplain: { width: "212px" },
      },
      variantValues: { explain: "Kanri10" },
    },
    {
      overrides: {
        text: {
          children:
            "\u6CE8\u610F\u4E8B\u9805\u3092\u5165\u529B\u3057\u307E\u3059",
        },
        HowtoExplain: { width: "152px" },
      },
      variantValues: { explain: "Kanri12" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="200px"
      height="28px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(43,133,255,1)"
      padding="7px 15px 7px 15px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "HowtoExplain")}
    >
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(43,133,255,1)"
        lineHeight="12px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ユーザーの名前が表示されます"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
