/*
    Admin.jsのユーザ追加部分のモーダル
*/
// eslint-disable-next-line react-hooks/exhaustive-deps
import React, {useState, memo} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import { monotonicFactory } from 'ulid';
import { createUserSetting} from '../../../graphql/mutations';
import { Btncancel        } from '../../../ui-components';
import { ConvInternationalCall, SubscriptionCreateFunc, SubscriptionDeleteFunc } from "../../../hooks/misc/common";
import { useMessage  }  from "../../../hooks/useMessage";
import { TopHeader   }  from '../../molecules/top/TopHeader';
import { MultiButton }  from '../../molecules/buttons/MultiButton';
import { InputText   }  from '../../atoms/Input/InputText';
import { ToggleButton}  from '../../atoms/button/ToggleButton';


export const InsertUserSetting = memo((props) =>{
    const { showMessage } = useMessage();
    const { InsertShow, setInsertShowFalse, fetchAndSetSettingUsers, setLoading,  BrokenInsertKey } = props;
    //idを生成する(Auto Incrementできないため)
    const ulid = monotonicFactory(); //ulidの生成

    //ユーザ登録用の変数
    const [Insertdict, setInsertdict] = useState({
        "name"          : "",
        "mail"          : "",
        "phone"         : "",
        "employeeNumber": "",
        "adminFlag"     : ""
    })
    const [InputError, setInputError] = useState(false); //入力漏れ


    /*辞書***********************************************************************************************/
    //入力欄のフォームの辞書
    const InputFormDict = [
        {
            //名前の入力フォーム
            "status"  : "name"           ,
            "kome"    : true             ,
            "dictkey" : "name"           ,
            "setvalue": setInsertdict    ,
            "type"    : "text"           ,
            "dict"    : Insertdict       ,
            "text"    : "名前"    
        },
        {
            //電話番号の入力フォーム
            "status"  : "PhoneNumber"     ,
            "kome"    : true              ,
            "dictkey" : "phone"           ,
            "setvalue": setInsertdict     ,
            "type"    : "tel"             ,
            "dict"    : Insertdict        ,
            "text"    : "電話番号" 
        },
        {
            //メールアドレスの入力フォーム
            "status"  : "mailaddress"     ,
            "kome"    : true              ,
            "dictkey" : "mail"            ,
            "setvalue": setInsertdict     ,
            "type"    : "email"           ,
            "dict"    : Insertdict        ,
            "text"    : "メールアドレス"
        },
        {
            //社員番号の入力フォーム
            "status"  : "EmployeeNumber"  ,
            "kome"    : true              ,
            "dictkey" : "employeeNumber"  ,
            "setvalue": setInsertdict     ,
            "type"    : "text"            ,
            "dict"    : Insertdict        ,
            "text"    : "社員番号"
        }
    ]

    //トグルボタンの辞書
    const ToggleFormDict = [
        {
            //管理者トグル
            "status"   : "admintoggle" ,
            "dictkey"  : "adminFlag"   ,
            "setvalue" : setInsertdict ,
            "property" : "Admin"       ,
            "dict"     : Insertdict  
        }
    ]
    /**************************************************************************************************/

    /*
        概要：trueとflaseを1と0に変換する処理(登録するときに1と0じゃないとうまくいかないため)
    */
    const BooleanConv = (flag) =>{
        if(flag){
            return 1
        }
        else{
            return 0
        }
    }
    

  

    /*
        概要：ユーザの登録処理
    */
    const UserInsert = async() => {
        //finally文を実行するかどうかを決める
        let DofinallyFlag = true;

        //追加するデータの定義をする
        let createUserSettingInput = {
            "mail"          : Insertdict["mail"]                         ,
            "id"            : ulid()                                     ,
            "name"          : Insertdict["name"]                         ,
            "phone"         : ConvInternationalCall(Insertdict["phone"]) ,
            "adminFlag"     : BooleanConv(Insertdict["adminFlag"])       , //管理者フラグがonなら1,offなら0
            "employeeNumber": Insertdict["employeeNumber"]
        }

        // 入力漏れの確認処理
        if(inputCheck()){
            // load画面を見せる
            setLoading(true)

            try{
                // ユーザ情報を追加する
                await API.graphql(graphqlOperation(createUserSetting, {createUserSettingInput: createUserSettingInput}))
                .catch((e)=>{
                    if(e.errors[0].message.includes("'phone' has an invalid value.")){
                        throw new Error("電話番号が適切ではないです。");
                    }
                    else if(e.errors[0].message.includes("'mail' has an invalid value.")){
                        throw new Error("メールアドレスが適切ではないです。");
                    }
                    else{
                        console.error(e);
                        throw new Error("API Invoke Error");
                    }
                })

                // subscriptionへの登録をする
                await Promise.all([
                    SubscriptionCreateFunc("sms", ConvInternationalCall(Insertdict["phone"])), 
                    SubscriptionCreateFunc("email", Insertdict["mail"])
                ]).then((response)=>{
                    const [smsSubscriptionRet, emailSubscriptionRet] = response;
                    if(smsSubscriptionRet.data.ResultStatus === false)
                    {
                        throw new Error(smsSubscriptionRet.data.ResultLog.message);
                    }

                    if(emailSubscriptionRet.data.ResultStatus === false)
                    {
                        throw new Error(emailSubscriptionRet.data.ResultLog.message);
                    }

                }).catch((e)=>{
                    console.error(e);
                    throw new Error("サブスクリプションの登録に失敗しました");
                })

                //※データを取得するタイミングが早すぎて取得したデータが古いことがあるため
                //200msだけ待ちます
                await new Promise(resolve=>setTimeout(resolve, 200));

                // ユーザリストをリストで取得する 
                await fetchAndSetSettingUsers()
                .catch((e)=>{
                    console.error(e);
                    throw new Error("API Invoke Error");
                })

            }
            catch(e){
                DofinallyFlag = false
                showMessage({Message:e.message});   
            }
            finally{
                // load画面の終了をする
                setLoading(false)
                if(DofinallyFlag){
                    // モーダルを閉じる
                    setInsertShowFalse()
                    // キーを破壊することでコンポーネントを丸ごと初期化をする
                    BrokenInsertKey()
                }
            }
        }
    }

    /*
        概要：入力漏れの確認
    */
    const inputCheck = () => {
        if((Insertdict["name"] === "")||(Insertdict["mail"] === "")||
           (Insertdict["phone"] === "")||(Insertdict["employeeNumber"] === ""))
        { 
            setInputError(true)
            return false
        }
        else{
            setInputError(false)
            return true
        }
    }



    return (
        <div style = {{width:"100%", height:"100vh", padding: "0", overflowY: "auto", backgroundColor: "#F5F5F5"}}>
            {/*ヘッダー部分*/}
            <>
                <TopHeader
                    key          = {"Top"}
                    text         = {"ユーザ登録"}
                    clickFunc    = {()=>{setInsertShowFalse()}}
                    status       = {"UserAdd"}
                    paddingTop   = {"16px"}
                    width        = {"90vw"}
                    marginTop    = {"0px"}
                    marginLeft   = {"auto"}
                    marginRight  = {"auto"}
                    marginBottom = {"24px"}
                />
            </>
            <form key={"Inputform"}>
                {
                    //入力フォーム欄
                    InputFormDict.map((inputform)=>{
                        return(
                            <div key={inputform["status"]}>
                                <InputText
                                    dictkey  = {inputform["dictkey" ]}
                                    setValue = {inputform["setvalue"]}
                                    status   = {inputform["status"  ]}
                                    kome     = {inputform["kome"    ]}
                                    Type     = {inputform["type"    ]}
                                    dict     = {inputform["dict"    ]}
                                    text     = {inputform["text"    ]}
                                />
                            </div>
                        )
                    })
                }
                            
                <div style={{width:"90%", margin:"auto", marginBottom:"70px"}}>
                    {
                        //トグルボタン
                        ToggleFormDict.map((toggleform)=>{
                            return(
                                <div key={toggleform["status"]}>
                                    <ToggleButton
                                        dictkey  = {toggleform["dictkey" ]}
                                        setValue = {toggleform["setvalue"]}
                                        property = {toggleform["property"]}
                                        dict     = {toggleform["dict"    ]}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {/*登録ボタンコンポーネント*/}
                    <MultiButton
                        CallBackFunc = {UserInsert}
                        omission = {InputError}
                        text     = {"登録"}
                        pattern  = {"default"}
                    />
                    <Btncancel
                        width = {"90%"}
                        margin = {"auto"}
                        onClick = {()=>{setInsertShowFalse()}}
                    />
                </div>
            </form>
        </div>
    );
});