import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import {AmplifyProvider} from "@aws-amplify/ui-react";
import Amplify from 'aws-amplify';
import awsmobile from "./modules/amplify/awsmobile";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(awsmobile);

ReactDOM.render(
    <StrictMode>
        <AmplifyProvider>
            <App />
        </AmplifyProvider>
    </StrictMode>,
    document.getElementById('root')
);
