/* eslint-disable react-hooks/exhaustive-deps */
/*
    概要：安否結果ページ
*/


import React, {useState, useEffect, useRef, memo} from 'react';
import { ulid } from 'ulid';
import {motion, AnimatePresence} from "framer-motion"
import {
    TxtSaishinHigai                  ,
    TxtKakoHigai                     , //過去の被害状態
    DamageSituation01                , //地震を一覧で表示するために使用している
    DamageSituationDetail            , //地震一覧をクリックしたときに表示する詳細部分
    HeadJoukyoukakunin02             , //モーダル内の詳細部分
    Choice                           , //被害あり、被害なし、未回答を選択するボタン
    ListJoukyoukakunin01             , //表で表示する部分
    ListJoukyoukakunin02             , //表で表示する部分
    ListJoukyoukakunin03             , //表で表示する部分
    Middle                           , //真ん中部分
    Layoutsituationcheckdetailcomment, //コメント詳細モーダル部分
    Layoutsituationcheckdetailicon02 , //会社確認可能//アイコンをクリックしたときの説明
    Layoutsituationcheckdetailicon01 , //被害アイコンをクリックしたときに出る説明部分
    HeadSyain                        , //社員一覧の表のヘッド部分
    ListSyain                        , //社員一覧の表のボディ部分
    Order                            , //回答順ボタンをクリックしたときのドロップダウンリスト
    OrderSyain                         //社員の人数とボタンが表示されているとこ
} from '../../../ui-components'
import { useSafetyResultData } from '../../../hooks/useSafetyResultData';
import { HeaderFooterLayout } from '../../templates/HeaderFooterLayout';
import { RingLoadingModalWhite } from '../../organisms/loading/RingLoadingModalWhite';
import { useResult } from '../../../hooks/providers/useResultProvider';
import { TopHeader } from '../../molecules/top/TopHeader';
import { useOtherData } from '../../../hooks/providers/useOtherDataProvider';

export const SafetyResultScreen = memo(() =>{
     const { GetData, listUserSetting, loading, TimeFiltedReport, open} = useSafetyResultData();
    
    const {
           showCommentDetailModal ,
           ShowEmployeeList       ,
           ResultDetailShow       ,
    } = useResult();

    //初期描画時の処理
    useEffect(()=>{
      GetData()
    },[])

    if(open){
      return(  
        <>
        {loading ? (
            <RingLoadingModalWhite/>
        ):(
          <div>
            {(() => {
                if(!ResultDetailShow&&!ShowEmployeeList)
                {
                    {/*回答結果をリストで表示するページ*/}
                    return(
                        <ResultListPage/>
                    )
                }
                else if(ResultDetailShow){
                    return (
                        <>
                        {/*回答結果を詳細に表示するページ*/}
                        <ResultDetailPage/>

                        {/*コメントボタンクリック時に表示するモーダル */}
                        <div id = "CommentModal">
                            <AnimatePresence>
                            {showCommentDetailModal && (
                                <CommentDetailModal/>
                            )}
                            </AnimatePresence>
                        </div>

                        {/*被害アイコンをクリックしたときに表示するモーダル*/}
                        <IconDamageExplainModal/>

                        {/*確認アイコンをクリックしたときに表示するモーダル*/}
                        <IconCheckExplainModal/>
                        </>
                    )
                }
                else if(ShowEmployeeList){
                    // 社員一覧ページ
                    return (
                        <EmployeeListPage/>
                    )
                }      
            })()}
          </div>
        )}
        </>
      )
    }
    else{
      return <RingLoadingModalWhite/>;
    }
})
/*辞書ファイル**********************************************************************************************************************************************************************************************************************************/
/*
    概要：文字に対応するアイコン
*/
const IconDict = {
    "無事"             : "line"        ,
    "家族<br>被害あり"  : "higai-family",
    "家に<br>被害あり"  : "higai-house" ,
    "自分に<br>被害あり": "higai_k"     ,
    "未回答"           : "line"        
}

const ConfirmIconDict = {
    "確認可能"         : "eye"         ,
    "確認不可能"       : "eye-slash"
}

const IconWhiteDict = {
    "無事"             : "line-white"    ,
    "家族<br>被害あり"  : "higai-family-w",
    "家に<br>被害あり"  : "higai-house-w" ,
    "自分に<br>被害あり": "higai_w"       ,
}

/*
    社員一覧ページの選択部分の文字との扱っている文字とを対応させるため
*/
const ShowTextDict = {
    "回答順"   : "回答順"         ,
    "被害あり" : "被害ありのみ表示",
    "無事"     : "被害なしのみ表示",
    "未回答"   : "未回答のみ表示"  , 
}


/*関数群****************************************************************************************************************************************************************************************************************************/

/*
    概要：ユーザのStatusを文章に変換する関数
*/
const UserStatusChangeText = (report, dict) => {
    let Text = "";
    report.forEach(function(repo){
        if((Object.keys(dict)).includes(repo.text))
        {
            Text = deleteBr(repo.text);
        }
    })
    return Text;
}

/*
    概要：ユーザのStatusをアイコンに変換する関数
*/
const UserStatusChangeIcon = (report, dict) => {
    let type = "";
    report.forEach(function(repo){
        if((Object.keys(dict)).includes(repo.text))
        {
            type = dict[repo.text];
        }
    })
    return type;
}

/*
    概要：<br>があったらbrタグに変換する
*/
const JudgeComment =(comment)=>{
    let items=[]
    while(comment.includes('<br>')){
        let idx = comment.indexOf('br')
        let text=comment.slice(0, idx - 1);
        comment = comment.slice(idx + 3);
        items.push(text)
        items.push(<br key={ulid()}/>)
    }
    items.push(comment)
    return(items);
}

/*
    概要：brタグを削除する
*/
const deleteBr = (text) => {
    text = text.replace(/<br>/g, "");
    return text
}   

/*
    概要：回答日時を指定のフォーマットに変換。モーダルないで使用する関数
*/
const AnswerTimeConv = (datetime) => {
    if(datetime !== null)
    {
        let month_str  = datetime.substr(4,2);
        let day_str    = datetime.substr(6,2);
        let hour_str   = datetime.substr(8,2);
        let minute_str = datetime.substr(10,2);
        
        let format_str = 'MM/DD hh:mm';
        format_str = format_str.replace(/MM/g, month_str);
        format_str = format_str.replace(/DD/g, day_str);
        format_str = format_str.replace(/hh/g, hour_str);
        format_str = format_str.replace(/mm/g, minute_str);
        return format_str;
    }
    else
    {
        return null;
    }
}

/*
    概要：地震の文章フォーマット通りに成形する関数
*/
const EarthquakeFormatConv = (EarthquakeInfo) => {
    let format_str = null; //指定のフォーマット

    if(EarthquakeInfo.type === "カスタム")
    {
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let title = EarthquakeInfo.title
        format_str = 'datetime title';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/title/g, title);
    }
    else if(EarthquakeInfo.type === "地震")
    {
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let spot = EarthquakeInfo.spot
        let seismicIntensity = EarthquakeInfo.seismicIntensity
        format_str = 'datetime <br> spot 震度seismicIntensity';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/spot/g, spot);
        format_str = format_str.replace(/seismicIntensity/g, seismicIntensity);
    }

    return format_str
}


/*
    概要：日付から文字列に変換する関数
*/
//T.B.D.安否登録部分でも同じ関数を使っていた気がする。commonにおいて共有した方がいいかも
const getStringFromDate = (date) => {
    let year_str   = date.substr(0,4);
    let month_str  = date.substr(4,2);
    let day_str    = date.substr(6,2);
    let hour_str   = date.substr(8,2);
    let minute_str = date.substr(10,2);
    
    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
}


/*
    概要：
        SortedUserReportから回答者数、無事、家族の健康被害、自分の健康被害、家被害、未回答計算する。
    戻り値：
        const UserStatusdict = {
            "回答者数"          : 0,
            "未回答者数"         : 0,
            "無事"              : 0,
            "被害あり"          : 0,
            "確認可能"          : 0,
            "確認不可能"        : 0,
            "家族<br>被害あり"  : 0,
            "家に<br>被害あり"  : 0,
            "自分に<br>被害あり": 0,
            "ユーザ数"         : 0 
        }
*/
const UserStatusFunc = (TimeFilteredReport, listUserSetting, listQuestion) => {
 
    //回答の項目が入った辞書ファイルを作成します。
    let UserStatusdict = {
        "回答者数"  : 0,
        "未回答者数": 0,
        "ユーザ数"  : 0,
    };
    if(listQuestion !== null)
    {
        listQuestion.forEach(function(Question){
            Question.answerPattern.forEach(function(pattern){
                UserStatusdict[pattern.text] = 0;
            })
        })
    }
  
    //回答者数とそれぞれの回答内容を計算します。
    if(TimeFilteredReport !== null)
    {
        let summaryList = []
        TimeFilteredReport.report.forEach(function(report){
            UserStatusdict[report.text] += 1; 
            if(!summaryList.includes(report.summary))
            {
                summaryList.push(report.summary);
            }
        })
        UserStatusdict["回答者数"] = summaryList.length;
    }

    //ユーザ数を計算します
    if(listUserSetting !== null )
    {
        UserStatusdict["ユーザ数"]   = listUserSetting.length;
        UserStatusdict["未回答者数"] = listUserSetting.length - UserStatusdict["回答者数"];
    }

    return UserStatusdict;
}


//reportのsummaryで回答結果をまとめる
/*
    //回答ありの場合
    const ReportSummary = {
        comment   : null, 
        createdAt : "20221230113725", 
        id        : "01GNGE899B8YHYZHN3H9517MM2",
        name      : "竹下博之", 
        report    : 
        [
            {
                question  : "安否報告をしてください", 
                questionId: "1", 
                summary   : "01GNGE899ADB5QB8ZNF9SQENKX", 
                text      : "無事", 
                answer    : "1",
            },
            {
                question  : "会社の状況を確認できますか？", 
                questionId: "2", 
                summary   : "01GNGE899ADB5QB8ZNF9SQENKX", 
                text      : "確認可能", 
                answer    : "3",
            },
        ]
    }

    //回答なしの場合
    const ReportSummary = {
        comment   : null, 
        createdAt : null, 
        id        : null,
        name      : "竹下博之", 
        report    : []
    }
*/
const ReportSummary = (Report, listUserSetting) => {
    let SummaryReports = [];
    let SummaryReport  = {
        comment        : null,
        createdAt      : null,
        Id             : null,
        employeeNumber : null,
        name           : null,
        report         : []
    }
    if(Report.report.length !== 0)
    {
        Report.report.forEach(function(repo){
            if(SummaryReport.name === repo.name)
            {
                //コメントを入れます
                if(repo.comment != null)
                {
                    SummaryReport.comment = repo.comment
                }
                //データを入れる
                SummaryReport.report.push({
                    question  : repo.question, 
                    questionId: repo.questionId, 
                    text      : repo.text, 
                    answer    : repo.answer
                })
            }
            else
            {
                //SummaryReportsに入れる
                SummaryReports.push(SummaryReport);

                //初期化する
                SummaryReport.comment        = null;
                SummaryReport.createdAt      = null;
                SummaryReport.Id             = null;
                SummaryReport.employeeNumber = null;
                SummaryReport.name           = null;
                SummaryReport.report         = [];

                //データを入れる
                SummaryReport.comment        = repo.comment;
                SummaryReport.createdAt      = repo.createdAt;
                SummaryReport.Id             = repo.id;
                SummaryReport.employeeNumber = repo.userId
                SummaryReport.name           = repo.name;
                SummaryReport.report.push({
                    question  : repo.question, 
                    questionId: repo.questionId, 
                    text      : repo.text, 
                    answer    : repo.answer
                })
            }
        })
    }


    //未回答のデータも追加する
    let unAnswerReports = []
    listUserSetting.filter(function(user){
        let pushFlag = true;
        this.forEach(function(report){
            if(report.name === user.name)
            {
               pushFlag = false;
            }
        })

        if(pushFlag)
        {
            unAnswerReports.push({
                comment        : null,
                createdAt      : null,
                Id             : null,
                employeeNumber : user.employeeNumber,
                name           : user.name,
                report         : []
            }) 
        }
    }, SummaryReports)

    //未回答のデータと回答データをくっつける
    let ReportsALL = SummaryReports.concat(unAnswerReports);
    return ReportsALL;
}


/*
    概要：statusで指定した回答をした人だけ選出する
*/
const SelectStatusReport = (SummaryReports, status) => {
    let selectReport = []
    if(status === "未回答")
    {
        SummaryReports.forEach(function(Report){
            if(Report.createdAt === null)
            {
                selectReport.push(Report);
            }
        })
    }
    else if(status === "回答順")
    {
        //createdAtでソートします。
        const createdAtSort = (a, b) => {
            let A = "0";
            let B = "0";

            if(a.createdAt !== null)
            {
                A = a.createdAt;
            }
            if(b.createdAt !== null)
            {
                B = b.createdAt;
            }
            return B - A;
        }

        //employeeNumberでソートします。
        const employeeNumberSort = (a ,b) => {
            return a.employeeNumber - b.employeeNumber;
        }

        let employeeNumberSorted = SummaryReports.sort(employeeNumberSort);
        selectReport             = employeeNumberSorted.sort(createdAtSort);
    }
    else
    {
        SummaryReports.forEach(function(Report){
            Report.report.forEach(function(repo){
                if(repo.text === status)
                {
                    selectReport.push(Report);
                }
            })
        })
    }
    return selectReport
}


/*コンポーネント群*******************************************************************************************************************************************************************************************************************/
/*
    概要：確認アイコンをクリックしたときに表示する説明モーダル
*/
const IconCheckExplainModal = memo(() => {
    const {IconCheckExplainShow, setIconCheckExplainShow } = useResult();
    if(IconCheckExplainShow){
        return (
            <div style={{position:"fixed", top:"0", left:"0", width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.5)", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={()=>{}}>
                <Layoutsituationcheckdetailicon02
                    overrides={
                        {
                            btnOk:{
                                onClick: () => setIconCheckExplainShow(false)
                            }
                        }
                    }
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        )
    }
    else{
        return null
    }
});

/*
    概要：被害アイコンクリック時に説明画面を表示する。
*/
const IconDamageExplainModal = memo(() => {
    const { IconDamageExplainShow, setIconDamageExplainShow } = useResult();

    if(IconDamageExplainShow){
        return (
            <div style={{position:"fixed", top:"0", left:"0", width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.5)",display: "flex", alignItems: "center",justifyContent: "center"}} onClick={()=>{}}>
                <Layoutsituationcheckdetailicon01
                    overrides={
                        {
                            "btnOk":{
                                onClick : () => setIconDamageExplainShow(false)
                            }
                        }
                    }
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        )
    }
    else{
        return null
    }
});


/*
    概要：コメントボタンクリック時に表示するモーダル
*/
const CommentDetailModal = () => {
    const { 
        showCommentDetailModal , setShowCommentDetailModal,
        CommentModalReport     , 
    } = useResult();

    let elem_commentModal = document.getElementById("CommentModal");
    let rect_commentModal = elem_commentModal.getBoundingClientRect();
    const window_h        = rect_commentModal.bottom + window.pageYOffset; //画面の高さ

    const ShowCommentDetailRef = useRef();//コメントモーダルで指定の要素以外をクリックしたときに検知できるようにする
    useEffect(()=>{
        if(showCommentDetailModal){//コメントモーダルが開いているとき処理する
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    },[showCommentDetailModal])

    //指定要素以外をクリックしたことを検知
    const handleClickOutside = (e) => {
        //下モーダルがオンになっているとき
        if(ShowCommentDetailRef){
            if (!ShowCommentDetailRef?.current?.contains(e.target)) {
                setShowCommentDetailModal(false);//motionを閉じる
            }
        }
    };

    const thresholdY = window_h/2; //Y軸閾値
    const OffsetY    = 100;
    const handleDragEnd = (y) => {
        if(y > thresholdY + OffsetY){
            setShowCommentDetailModal(false);
        }
    }


    //アイコンを探します
    let CommentIcon = UserStatusChangeIcon(CommentModalReport.report, IconWhiteDict);
    //被害のテキストを探します。
    let damageText  = UserStatusChangeText(CommentModalReport.report, IconDict);

    return (
        <div style={{position:"fixed",top:"0",left:"0",width:"100%",height:"100%",backgroundColor:"rgba(0,0,0,0.5)",zIndex: "1000"}} key="child">
            <motion.div
                key       = "CommentAnimationPresenceChildrenmotiondiv"
                initial   = {{ opacity: 1, y: window_h + window_h*1/3 }}  //初期状態
                animate   = {{ opacity: 1, y: 0 }}                        //マウント時
                exit      = {{ opacity: 1, y: window_h + window_h*1/3 }}  //アンマウント時
                transition= {{ duration: 0.5}}                            //0.5秒かけて立ち上がる
                style     = {{position: "absolute", top: "50vh", zIndex:"1001", minHeight: "50vh", width: "100%", height: "100vh", backgroundColor: "#2B85FF", borderRadius: "50px 50px 0px 0px"}}
                onClick   = {(e) => e.stopPropagation()}
                ref       = {ShowCommentDetailRef}
                drag       = "y"
                dragConstraints = {{
                    top   : 0,
                    left  : 0,
                    right : 0,
                    bottom: 0
                }}
                //ドラッグ要素が解放されたときの中心に戻る動き
                dragElastic={{
                    top   : 0.0000001,
                    left  : 0,
                    right : 0,
                    bottom: 1 
                }}
                //ドラッグ終了時に起動する
                onDragEnd={
                    (event, info) => handleDragEnd(info.point.y)
                }
                dragMomentum={true}//ドラッグ終了時に勢いをつける
            >
                <div>
                    <Layoutsituationcheckdetailcomment
                        overrides = {
                            {
                                "\u90FD\u7BC9 \u68A8\u592E":{                                 
                                    //名前部分
                                    children: CommentModalReport.name
                                },
                                "\u81EA\u5206\u306B\u88AB\u5BB3\u3042\u308A":{                
                                    //被害状況
                                    children: damageText
                                },
                                "MyIcon":{                                                    
                                    //アイコン
                                    type: CommentIcon
                                },
                                "\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002":{
                                    //コメント部分
                                    children: JudgeComment(CommentModalReport.comment)
                                } 
                            }
                        }
                        width={"100%"}
                    />
                </div>
            </motion.div>
        </div>
    )
}

/*
    概要：結果をリストで表示しているページ
*/
const ResultListPage = () => {
    const { listEarthquake } = useSafetyResultData();
    return (
        <HeaderFooterLayout>
        {           
            listEarthquake.map((earthquake, index)=>{
                return(
                    <EarthquakeListDisplay
                        earthquake = {earthquake}
                        index      = {index}
                        key        = {index}
                        width      = {"100%"}
                    />
                );
            })
        }
        </HeaderFooterLayout>
    )
}


/*
    概要：地震リストを作るコンポーネント
*/
const EarthquakeListDisplay = memo((props) =>{
    const {earthquake, index} = props;
    const {settoggle, setupdate, update, toggle, TimeFilteredReports} = useResult();

    //トグルに指定の要素を追加する機能
    const toggleChange = (id, value) => {
        settoggle({...toggle, [id]: value})
        setupdate(!update)//なぜか更新されないので更新するステートを更新するための関数
    }

    /*
        概要:トグルステートから指定の要素を削除する機能
    */
    const toggleDelete = (id,Oldtoggle) => {
        let toggle = Oldtoggle
        delete toggle[id]
        settoggle(toggle)
        setupdate(!update)//なぜか更新されないので更新するステートを更新するための関数
    }

    /*
        概要：
            存在しない場合は-1を返します。
            それ以外の場合は0を返します。
        引数：

    */
    const serchIndexFunc = (id, TimeFilteredReports) => {
        const serchIndex = TimeFilteredReports.findIndex(
            (repo) => repo.id === id
        );
        return TimeFilteredReports[serchIndex];
    }


    const format_str =deleteBr(EarthquakeFormatConv(earthquake))
    let MyIconStatus = ""; //MyIconがangle-upかangle-downか判断する。Click動作も入れる
    let ClickAction;       //クリックアクション
    let displayJudge = ""; //下の線の有無
    if(toggle[index]){
        MyIconStatus = "angle-up"
        ClickAction = ()=> {toggleDelete(index, toggle)}
        displayJudge = "none"
    }
    else{
        MyIconStatus = "angle-down"
        ClickAction = ()=> {toggleChange(index, true)}
        displayJudge = "block"
    }

    let text = "";//「最新の被害状況」or「過去の被害状況」の文字列コンポーネントが入ります。
    if(index === 0)
    {   
        //「最新の被害状況」の文字列
        text = 
        <TxtSaishinHigai
            width = {"90%"}
            style = {{margin:"auto", marginBottom:"12px"}}
        />
    }
    else if(index === 1)
    {
        //「過去の被害状況」の文字列
        text = 
        <TxtKakoHigai
            width = {"90%"}
            style = {{margin:"auto", marginBottom:"12px"}}
         />
    }
    else{
        text = null;
    }

    //一覧で表示するリストコンポーネント
    const resultList =
    <DamageSituation01
        overrides = {
            {
                "text":{
                    children: format_str
                },
                "MyIcon": {
                    type: MyIconStatus
                }
            }
        }
        width = {"90%"}
        style = {{margin:"auto"}}
        onClick = {ClickAction}
    />

    //集計した結果のコンポーネント
    const TallyResult = 
    <TallyReportResult
        toggle             = {toggle[index]}
        earthquake         = {earthquake}
        TimeFilteredReport = {serchIndexFunc(earthquake.id, TimeFilteredReports)}
    />

    
    if(index === 0){
        return (
            <div style = {{marginTop:"24px", marginBottom:"32px"}}>
                {/*「最新の被害状況」or「過去の被害状況」の文字列*/}
                {text}

                {/*一覧で表示している地震情報*/}
                {resultList}

                {/*右端のボタンをクリックしたときに表示する情報*/}
                {TallyResult}
            </div>
        )
    }
    else {
        return (
            <div style = {{marginBottom:"16px"}}>
                {/*「最新の被害状況」or「過去の被害状況」の文字列*/}
                {text}

                {/*一覧で表示している地震情報*/}
                {resultList}

                {/*右端のボタンをクリックしたときに表示する情報*/}
                {TallyResult}
            </div>
        )
    }
});


/*
    概要：下矢印ボタンクリック時に回答結果を表示するコンポーネント
    引数：toggle;表示非表示を管理する変数,ReportResult;時間でフィルターした回答結果はここに引数として入れておく。
*/
const TallyReportResult = memo((props) => {
    const {TimeFilteredReport, toggle, earthquake} = props;
    const {
        setResultDetailShow ,
        listUserSetting     ,
        setReport           ,
        setUserStatus       ,
        setEarthquake       ,
        setSummaryReports   ,
        setSelectReport     ,          
        setSelectReportButton
     } = useResult();

    const {listQuestion} = useOtherData();
    const UserStatusdict = UserStatusFunc(TimeFilteredReport, listUserSetting, listQuestion);
    
    /*
        概要：地震回答結果を詳細に表示するページを管理します。必要なデータをstateに登録します。
    */
    const TallyReportResultHandler = (UserStatusdict, TimeFilteredReport, earthquake, listUserSetting) => {
        setReport(TimeFilteredReport)
        setUserStatus(UserStatusdict)
        setEarthquake(earthquake)
        setResultDetailShow(true)

        let tmpSummaryReport = ReportSummary(TimeFilteredReport, listUserSetting)
        setSummaryReports(tmpSummaryReport);
        setSelectReport(SelectStatusReport(tmpSummaryReport, "被害あり"));
        setSelectReportButton("被害あり");
    }
    
    if(toggle){
        return (
            <div>
                <DamageSituationDetail
                    overrides = {
                        {
                            "number37054675":{
                                //回答者数
                                children: UserStatusdict["回答者数"]
                            },
                            "number37054696":{
                                //家族の健康被害
                                children: UserStatusdict["家族<br>被害あり"]
                            },
                            "number37054682":{
                                //無事
                                children: UserStatusdict["無事"]
                            },
                            "number37054689":{
                                //自分の健康被害
                                children: UserStatusdict["自分に<br>被害あり"]
                            },
                            "number37054703":{
                                //家被害
                                children: UserStatusdict["家に<br>被害あり"]
                            },
                            "number37054710":{
                                //未回答
                                children: UserStatusdict["未回答者数"]
                            }
                        }
                    }
                    width = {"90vw"}
                    style = {{margin:"auto"}}
                    onClick={()=>{TallyReportResultHandler(UserStatusdict, TimeFilteredReport, earthquake, listUserSetting)}}
                />
            </div>
        )
    }
    else{
        return null
    }
});

/*
    概要：地震回答結果を詳細に表示するページ
*/
const ResultDetailPage = memo(() => {
    const {
        setResultDetailShow            ,
        setSelectReportButton          ,
        setShowEmployeeList            ,
        Earthquake                     ,       
        UserStatus                     ,
        SummaryReports                 , 
        setEmployeeSelectBtn           ,
        setEmployeeListSelectReport
     } = useResult();

    //ヘッダーの左矢印と×ボタンをクリックしたときの処理
    const CloseFunc = () => {
        setResultDetailShow(false);
        setSelectReportButton("被害あり");
    }

    //社員一覧ボタンをクリックしたときに呼び出します
    const EmployeeListBtnHandler = () => {
        setShowEmployeeList(true);   //表示、非表示を管理する
        setEmployeeListSelectReport(SelectStatusReport(SummaryReports, "回答順"));
        setEmployeeSelectBtn("回答順");
        setResultDetailShow(false);
    }

    //ページのヘッダー部分の地震情報を変更します。
    const EarthquakeInfoTitle = JudgeComment(EarthquakeFormatConv(Earthquake))
 
    return (
        <div style = {{overflowY: "scroll", background:"#fff", height: "100vh", width: "100%"}}>
            {/*ページのヘッダー部分*/}
            <TopHeader
                text         = {EarthquakeInfoTitle}
                clickFunc    = {()=>{CloseFunc()}}
                status       = {"Joukyoukakunin"}
                marginBottom = {"24px"}
                paddingTop   = {"16px"}
            />

            {/*回答者数と社員一覧ボタンの部分*/}
            <Middle
                overrides = {
                    {
                        "0036332745":{
                            // 回答者数
                            children: UserStatus["回答者数"]
                        },
                        "0036332772":{
                            // ユーザ数
                            children: UserStatus["ユーザ数"]
                        },
                        btnSyain:{
                            onClick : () => {EmployeeListBtnHandler()}
                        }
                    }
                }
                width = {"90vw"}
                style = {{margin:"auto",marginBottom:"24px"}}
            />
            <div style = {{paddingTop:"18px", width:"100%", minHeight:`calc(100% - 106px)`, backgroundColor:"rgba(245,245,245,1)", borderRadius: "50px 50px 0px 0px"}}>
                {/*ボタン部分*/}
                <SelectButtons/>
                {/*表*/}
                <ResultDetailTable/>
            </div>
        </div>
    )
});  


/*
    概要：切り替えを行うボタンコンポーネント
*/
const SelectButtons = () => {
    let bGStatusSafe       = "white"; //ボタンの色を切り替えるプロパティの値
    let bGStatusDamage     = "white"; //ボタンの色を切り替えるプロパティの値
    let bGStatusUnAnswered = "white"; //ボタンの色を切り替えるプロパティの値
    const {
        setSelectReport   ,
        SelectReportButton, setSelectReportButton,
        SummaryReports    
     } = useResult();

  
    //ボタンをクリックしたときの処理
    const SelectButtonHandler = (SummaryReports, status) => {
        setSelectReport(SelectStatusReport(SummaryReports, status))
        setSelectReportButton(status);
    }

    switch(SelectReportButton){
        case "無事":
            bGStatusSafe = "blue"
            break;

        case "被害あり":
            bGStatusDamage = "blue"
            break;

        case "未回答":
            bGStatusUnAnswered = "blue"
            break;
        
        default:
            break;
    }

    return (
        <Choice
            overrides = {
                {
                    btnChoice34533985:{
                        //被害あり
                        bg: bGStatusDamage,
                        onClick: ()=> {SelectButtonHandler(SummaryReports, "被害あり")}
                    },
                    btnChoice34533986:{
                        //被害なし
                        bg: bGStatusSafe,
                        onClick: ()=> {SelectButtonHandler(SummaryReports, "無事")}
                    },
                    btnChoice34533987:{
                        //未回答
                        bg: bGStatusUnAnswered,
                        onClick: ()=> {SelectButtonHandler(SummaryReports, "未回答")}
                    }

                }
            }
            margin       = {"auto"}
            marginBottom = {"24px"}
        />
    )
}   

/*
    概要：resultDetailでの表のコンポーネント
*/
const ResultDetailTable = () => {
    const {
        setIconCheckExplainShow   , 
        setIconDamageExplainShow  ,
        setShowCommentDetailModal ,
        SelectReport              , 
        SelectReportButton        ,        
        setCommentModalReport     ,
    } = useResult();

    //コメントボタンクリック時に呼び出される関数
    const CommentBtnHandler = (report) => {
        setShowCommentDetailModal(true);
        setCommentModalReport(report);
    }

    return (
        <div>
            {/*表のヘッダー部分*/}
            <HeadJoukyoukakunin02
                width  = "90vw"
                margin = "auto"
            />
            {/*表データ部分*/}
            <>    
            {
                SelectReport.map((repo, index)=>{
    
                    //コメント回答の有無
                    let haveComment 
                    if(repo.comment === "" || repo.comment === null){
                        //コメントなし
                        haveComment = {              
                            type: "comment_g",
                            onClick: ()=> {}
                        }
                    }
                    else{
                        //コメントあり
                        haveComment = {
                            type: "comment_b",
                            onClick: ()=> CommentBtnHandler(repo)
                        }
                    }

                    //奇数番目偶数番目の背景色
                    let BGColor    = null; //componentのプロパティ
                    let BGColorDiv = null; //Componentの背景色をwidth100%まで伸ばすために必要
                    if (index % 2 === 0){
                        BGColor = "gray"
                        BGColorDiv = "rgba(245,245,245,1)"
                    }
                    else{
                        BGColor = "white"
                        BGColorDiv = "rgba(255,255,255,1)"
                    }

                    //アイコンを探す
                    let damageIcon  = UserStatusChangeIcon(repo.report, IconDict);
                    let confirmIcon = UserStatusChangeIcon(repo.report, ConfirmIconDict);
                    
                    if(SelectReportButton === "無事"){
                        //被害なし
                        return (
                            <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin02"+index}>
                                <ListJoukyoukakunin02
                                    overrides={
                                        {
                                            name:{
                                                //名前
                                                children: repo.name
                                            },
                                            day:{
                                                //回答日時
                                                children: AnswerTimeConv(repo.createdAt)
                                            },
                                            MyIcon34592321:haveComment,//コメント部分
                                            MyIcon34592318:{
                                                //被害のアイコン部分
                                                type : damageIcon
                                            },
                                            MyIcon34592374:{
                                                //会社確認アイコン部分
                                                type : confirmIcon,
                                                onClick : ()=> setIconCheckExplainShow(true)
                                            }
                                        }
                                    }
                                    bg     = {BGColor}
                                    width  = "90vw" 
                                    margin = "auto"
                                />
                            </div>
                        )
                    }
                    else if(SelectReportButton === "被害あり"){//被害あり
                        return (
                            <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin01"+index}>
                                <ListJoukyoukakunin01
                                    overrides={
                                        {
                                            name:{
                                                //名前部分
                                                children: repo.name
                                            },
                                            day:{
                                                //回答日時
                                                children: AnswerTimeConv(repo.createdAt)
                                            },
                                            MyIcon37242838:{
                                                //被害アイコン部分
                                                type: damageIcon,
                                                onClick: () => setIconDamageExplainShow(true)
                                            },
                                            //コメントアイコン部分
                                            MyIcon37242841: haveComment
                                        }
                                    }
                                    bg = {BGColor}
                                    width = "90vw" 
                                    margin = "auto"
                                />
                            </div>
                        )
                    }
                    else if(SelectReportButton === "未回答"){//未回答
                        return (
                            <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin03"+index}>
                                <ListJoukyoukakunin03
                                    overrides={
                                        {
                                            MyIcon34602664: {
                                                marginRight: "65px"//80px被害ありでの回答日時部分のwidth、15pxアイコンのwidth(80px-15px)
                                            },
                                            name:{
                                                children: repo.name
                                            }
                                        }
                                    }
                                    bg     = {BGColor}
                                    width  ="90vw"
                                    margin = "auto"
                                />
                            </div>
                        )
                    }
                    return null;
                })
            }
            </>
    </div>
    )
}


/*
    概要：社員一覧モーダル
*/
const EmployeeListPage = memo(() => {
    const { 
        setShowEmployeeList ,    
        DropDownOpen        , setDropDownOpen   ,  
        Earthquake          ,
        UserStatus          ,
        EmployeeSelectBtn   ,
        setResultDetailShow ,
     } = useResult();

     //ヘッダに表示する内容を作ります
    const EarthquakeInfoTitle = JudgeComment(EarthquakeFormatConv(Earthquake))

    //ドロップダウンが開いているかどうかの判定
    let OrderSyainStatus="";
    if(DropDownOpen)
    {
        OrderSyainStatus = "select"
    }
    else
    {
        OrderSyainStatus = "Default" 
    }

    const TopHeaderHandler = () => {
        setShowEmployeeList(false);
        setResultDetailShow(true);
    }


    return (
        <div style = {{overflowY:"scroll", width:"100%", height:"100%", background:"#fff"}}>
                {/*ヘッダー部分*/}
                <TopHeader
                    status       = {"Joukyoukakunin"}
                    clickFunc    = {()=>{TopHeaderHandler()}}
                    text         = {EarthquakeInfoTitle}
                    width        = {"90%"}
                    margin       = {"auto"}
                    marginBottom = {"24px"}
                    paddingTop   = {"16px"}
                />

                {/*社員数の表示部分*/}
                <OrderSyain
                    overrides={
                        {
                            "Status":{
                                children : ShowTextDict[EmployeeSelectBtn],
                                onClick  : () => setDropDownOpen(!DropDownOpen)
                            },
                            "name34522468":{
                                children : UserStatus["ユーザ数"]+"人"
                            }
                        }
                    }
                    status = {OrderSyainStatus}
                    width = {"100%"}
                />

                {/*社員の順番を決めるボタンを選択する部分*/}
                <div style = {{position: "relative", height:"fit-content"}}> 
                    {/*順番を決めるボタン*/}
                    <OrderChangeButtons/>
                    {/*社員一覧テーブル*/}
                    <EmployeeListTable/>
                </div>
        </div>
    )
});

/*
    概要：順番変更するボタンのコンポーネント
*/
const OrderChangeButtons = () => {
    const { 
        DropDownOpen                , setDropDownOpen   ,
        SummaryReports              , 
        setEmployeeListSelectReport ,
        EmployeeSelectBtn           , setEmployeeSelectBtn
     } = useResult();

    const DropDownRef = useRef(); //コメントモーダルで指定の要素以外をクリックしたときに検知できるようにする
    useEffect(()=>{
        if(DropDownOpen){//コメントモーダルが開いているとき処理する
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    },[DropDownOpen])

    //指定要素以外をクリックしたことを検知
    const handleClickOutside = (e) => {
        //下モーダルがオンになっているとき
        if(DropDownRef){
            console.log("mousedown")
            if (!DropDownRef?.current?.contains(e.target)) {
                console.log("指定の要素以外クリックしました。")
                setDropDownOpen(false)
            }
        }
    };

    //ドロップダウンリストで選択されている文字の色を変更する
    const DropDownfontColor = {
        color: "rgba(43,133,255,1)"  
    };
    //ドロップダウンリストのアイコンのチェックボタンの色
    const DropDownIconProperty = {
        type: "check"
    }
    let DropDownSelect = null
    let DropDownIcon   = null

    //Order.jsxのコンポーネント内の文字部分の数字が入っている。
    //クリックされた部分の色が切替をするため。
    switch(EmployeeSelectBtn){
        case "回答順":
            //回答順
            DropDownSelect = "text34533532"
            DropDownIcon   = "MyIcon34533533"
            break;

        case "被害あり":
            //被害ありのみ表示
            DropDownSelect = "text39853397"
            DropDownIcon   = "MyIcon39853398"
            break;

        case "無事":
            //被害なしのみ表示
            DropDownSelect = "text39853455"
            DropDownIcon   = "MyIcon39853456"
            break;

        case "未回答":
            //未回答のみ表示
            DropDownSelect = "text39853498"
            DropDownIcon   = "MyIcon39853499"
            break;
        
        default:
            break;
    }

    /*
        概要：指定した順番にソートします。
    */
    const OrderBtnHandler = (SummaryReports, status) => {
        setDropDownOpen(false);
        setEmployeeSelectBtn(status);
        setEmployeeListSelectReport(SelectStatusReport(SummaryReports, status));
    }


    if(DropDownOpen)
    {
        return(
            <div style = {{position:"absolute", width:"100vw", minHeight:"148px", backgroundColor:"rgba(0, 0, 0, 0.2)", zIndex:"500"}}>
                <div ref={DropDownRef}>
                    <Order
                        overrides = {
                            {
                                "Frame 502" : {
                                    //回答順
                                    onClick : () => OrderBtnHandler(SummaryReports, "回答順")
                                },
                                "Frame 505" : {
                                    //被害ありのみ表示
                                    onClick : () => OrderBtnHandler(SummaryReports, "被害あり")
                                },
                                "Frame 506" : {
                                    //被害なしのみ表示
                                    onClick : () => OrderBtnHandler(SummaryReports, "無事")
                                },
                                "Frame 507" : {
                                    //未回答のみ表示
                                    onClick : () => OrderBtnHandler(SummaryReports, "未回答")
                                },
                                "text34533532":{
                                    //回答順：//デフォルトで青色になっているので銀色に初期化
                                    color: "rgba(160,160,160,1)"
                                },
                                [DropDownSelect]:DropDownfontColor,//ドロップダウンリストの文字の色
                                "MyIcon34533533":{
                                    //回答順のチェックアイコン。見えないように初期化。
                                    type: "xmark02"
                                },
                                "MyIcon39853398":{
                                    //被害ありのみ表示のチェックアイコン。見えないように初期化。
                                    type: "xmark02"
                                },
                                "MyIcon39853456":{
                                    //被害なしのみ表示のチェックアイコン。見えないように初期化。
                                    type: "xmark02"
                                },
                                "MyIcon39853499":{
                                    //未回答のみ表示のチェックアイコン。見えないように初期化。
                                    type: "xmark02"
                                },
                                [DropDownIcon]: DropDownIconProperty//選択されたアイコンを表示する
                            }
                        }
                        width  = {"100%"}
                        height = {"148px"}
                    />
                </div>
            </div>   
        )
    }
    else{
        return null;
    }
}


/*
    概要：社員一覧画面のテーブル部分のコンポーネント
*/
const EmployeeListTable = () => {
    const { EmployeeListSelectReport } = useResult();

    return (
        <>
            {/*社員一覧表のヘッダー部分*/}
            <HeadSyain
                width = {"100%"}
            />

            {/* 社員一覧表のボディ部分 */}
            <div style={{minHeight:"300px"}}>
                {
                    EmployeeListSelectReport.map((repo, index)=>{

                        //BGカラーの変化を付ける
                        let BGColor = ""
                        if(index % 2 === 0){
                            BGColor = "gray"
                        }
                        else{
                            BGColor = "white" 
                        }


                        let overridesAnswer = {};
                        let ListSyainStatus = "";
                        if(repo.createdAt === null){
                            //未回答のとき
                            ListSyainStatus = "NoAnswer"
                            overridesAnswer = {}
                        }
                        else{
                            //回答済みのとき
                            ListSyainStatus = "Answer"
                            overridesAnswer = {
                                children : AnswerTimeConv(repo.createdAt)
                            } 
                        }   

                        return(
                            <ListSyain
                                key = {"ListSyain"+index}
                                overrides={
                                    {
                                        name:{
                                            children: repo.name
                                        },
                                        answer: overridesAnswer
                                    }
                                }
                                bg     = {BGColor}
                                status = {ListSyainStatus}
                                width  = {"100%"}
                            />
                        )
                    })
                }
            </div>
        </>
    )

}
