/*
    概要：管理者画面などで使用されるHeader代わりのTopコンポーネント
*/
import { Top } from "../../../ui-components"

export const TopHeader = (props) => {
    const { status, clickFunc, text, ...rest} = props;

    return (
        <Top
            overrides = {
                {
                    "text":{
                        children : text
                    },
                    "MyIcon33471830":{//右のボタン
                        onClick : () => {clickFunc()}
                    },
                    "MyIcon33471831":{//左の矢印ボタン
                        onClick: () => {clickFunc()}
                    }
                }
            }
            status = {status}
            width  = {"90vw"}
            margin = {"auto"}
            {...rest}
        />
    )
}