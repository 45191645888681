/* eslint-disable react-hooks/exhaustive-deps */
/*
  概要：エラーメッセージに使用します。
*/
import { useCallback } from "react";
import { useToast } from "../hooks/providers/useToastProvider";

export const useMessage = () => {
  const { setshowToast, setmessageToast } = useToast();

  const showMessage = useCallback((props) => {
    const {message} = props;
    const show = true;
    setshowToast(show);
    setmessageToast(message);
  }, []);

  return { showMessage };
};
