/*
    概要：文字列のボタンコンポーネント
    引数：
        Content　　　　　 : 文字列
        RGB    　　　　　 : 選択されたときの色
*/
import React, { memo, useState } from 'react';
import {
    BtnHigaijoukyou,     //無事ボタン
} from '../../../ui-components';
import { monotonicFactory } from 'ulid';

export const ContentButton = (props) => {
    const {bgColor, textColor, Content, ...rest} = props;
    const ulid  = monotonicFactory();//ulidの生成
    let id      = ulid();

    return (
        <BtnHigaijoukyou
            overrides = {
                {
                    "text":{
                        children : Content,
                        color    : textColor
                    },
                    "BtnHigaijoukyou":{
                        backgroundColor : bgColor
                    },
                }
            }
            status = {"default"}
            {...rest}
        />
    );
}
