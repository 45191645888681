/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function InputShindochi(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: {},
        MyIcon: {},
        "Frame 2015": {},
        "Rectangle 19": {},
        InputShindochi: {},
      },
      variantValues: { situation: "Default" },
    },
    {
      overrides: {
        text: { children: "5\u5F31" },
        MyIcon: {},
        "Frame 2015": {},
        "Rectangle 19": {},
        InputShindochi: {},
      },
      variantValues: { situation: "select" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="4px"
      direction="column"
      width="unset"
      height="31px"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      padding="12px 0px 0px 0px"
      backgroundColor="rgba(245,245,245,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "InputShindochi")}
    >
      <Flex
        gap="37px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 2015")}
      >
        <Text
          fontFamily="YuGothic"
          fontSize="14px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="14px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="240px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="震度値"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="angle-down02"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(224,224,224,1)"
        {...getOverrideProps(overrides, "Rectangle 19")}
      ></View>
    </Flex>
  );
}
