import {memo} from "react";
import {HeaderJokyo,Footer} from "../../ui-components";

export const NoneFuncHeaderFooterLayoutJokyo = memo((props)=>{
    const { children } = props;
    return(
        <div style={{backgroundColor:"#F5F5F5", display:"flex",flexDirection:"column", minHeight:"100%", height:"100vh"}}>
            <HeaderJokyo 
                width = "100%"
            />
            {children}
        </div>
    );
});