import { API, graphqlOperation} from 'aws-amplify';
import { listEarthquakeOne,listCustomQuestion, listCustoms, getUserSetting,  
        listUserSettinglight, listEarthquakes, listAnswers, listUserSettings } from "../../../src/graphql/queries";
import { Auth } from 'aws-amplify';


/*関数群*******************************************************************************************************************/

/* 概要：電話番号を国際電話番号に変更する */
export const ConvInternationalCall = (phone) =>{
    let phoneSplit   = phone.substr(1);
    let phoneConbine = "+81"+phoneSplit
    return phoneConbine
}

/* 概要：国際電話番号を電話番号に変更する */
export const ConvPhoneNumber = (phone) =>{
    if(phone !== undefined){
        const ContryCode = "+81"
        const indexNum = phone.indexOf(ContryCode)+ContryCode.length
        let phoneSplit = phone.substr(indexNum);
        let phoneConbine = "0" + phoneSplit
        return phoneConbine
    }
}

/* 概要：メールアドレスを引数としてadminFlagを返す */
export const fetchCurrentUserInfo = async(mail) =>{
    let Input = {"mail": mail}
    return await API.graphql(graphqlOperation(getUserSetting, Input));
}

/* 概要：Authの認証ユーザの情報を取得します */ 
export const fetchCurrentAuthenticatedUser = async() => {
    return await Auth.currentAuthenticatedUser()
}

/* 概要：Earthquakeテーブルから最新のデータを取得 */
export const fetchLatestEarthquake = async() => {
    return await API.graphql(graphqlOperation(listEarthquakeOne))
}

/* 概要： Customテーブルから最新のデータを一件取得 */
export const fetchLatestCustom = async() => {
    return await API.graphql(graphqlOperation(listCustoms, {"limit": 1}))
}

/* 概要：Questionからデータを取得します */
export const fetchListCustomQuestion = async() => {
    const customtype = "0";
    return await API.graphql(graphqlOperation(listCustomQuestion, {"customtype": customtype}))
}


/* 概要：ユーザ一覧リストを取得する関数 */
export const fetchListUsers = async() => {
    return await API.graphql(graphqlOperation(listUserSettinglight));
}

/*概要：地震情報を取得します*/
export const fetchListEarthquakes = async(limit) => {
    return await API.graphql(graphqlOperation(listEarthquakes, {"limit":limit}));
}

/*概要：カスタム情報を取得します*/
export const fetchListCustoms = async(limit) => {
    return await API.graphql(graphqlOperation(listCustoms, {"limit": limit}));
}

/*
  概要：回答結果リストを取得します
　引数：{ "time" : datetime }
*/
export const fetchListAnswers = async(inputTime) => {
    const limit = 5;
    return await API.graphql(graphqlOperation(listAnswers, inputTime));
}

/* 概要：震度の設定値を取得します */
export const fetchSettingSeismicIntensity = async()=>{
    const apiName = "SafetyConfirmRest";
    const path    = "/getParameterValue";
    const my_parameter_key = "Seismic_Intensity";
    const myInit = {
        response: true,
        queryStringParameters: {
            "param_name": my_parameter_key
        }
    };

    return  await API.get(apiName, path, myInit);
}

/* 概要：震度の観測地の設定値を取得します。*/
export const fetchEarthQuaqeSettingSpot = async() =>{
    const apiName = "SafetyConfirmRest" ;
    const path    = "/getParameterValue";
    const my_parameter_key = "Detected_Prefectures";

    const myInit = {
        response: true,
        queryStringParameters: {
            "param_name": my_parameter_key
        }
    };
    return await API.get(apiName, path, myInit);    
}

/* 概要：ユーザ情報をリストで取得する */
export const fetchListUserSetting = async() => {
    return await API.graphql(graphqlOperation(listUserSettings));
}

/*
    概要：APIGatewayにリクエストを送り、/backend/function/SubscriptionResolverでサブスクリプションの登録処理を行います
    引数：
        Protocol: "sms"か"email"
        EndPoint: "sms"なら電話番号、"email"ならメールアドレス
*/
export const SubscriptionCreateFunc = async(Protocol, Endpoint) => {
    const apiName = "SafetyConfirmRest";
    const path    = "/subscription";

    const myInit = {
        response: true,
        queryStringParameters: {
            "Status"  : "Create",
            "Protocol": Protocol,
            "Endpoint": Endpoint
        }
    };

    return await API.get(apiName, path, myInit);
}


/*
    概要：APIGatewayにリクエストを送り、/backend/function/SubscriptionResolverでサブスクリプションの削除処理を行います
    引数：
        Protocol: "sms"か"email"
        EndPoint: "sms"なら電話番号、"email"ならメールアドレス
*/
export const SubscriptionDeleteFunc = async(Protocol, Endpoint) => {
    const apiName = "SafetyConfirmRest";
    const path    = "/subscription";
    const myInit  = {
        response: true,
        queryStringParameters: {
            "Status"  : "Delete",
            "Protocol": Protocol,
            "Endpoint": Endpoint
        }
    };

    return await API.get(apiName, path, myInit);
}



/* 概要: 管理者権限を持っているかのチェックを行います */
export const adminChcker = (userInfo) => {
    let ResMessage = {
        "status" : "normal",
        "message": ""
    }
    
    if(userInfo === null)
    {
        ResMessage["message"] = "不明なユーザです";
        ResMessage["status"]  = "unknown";
    }
    else if(userInfo.adminFlag === false)
    {
        ResMessage["message"] = "ユーザは管理者権限を持っていません";
        ResMessage["status"]  = "normal";
    }
    else if (userInfo.adminFlag === true)
    {
        ResMessage["message"] = "管理者がページに入りました";
        ResMessage["status"]  = "admin";
    }

    return ResMessage;
}



/*------------------------------------------------------------------------------------------------------------------------------------------ */