/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import BtnToggle from "./BtnToggle";
import { Text, View } from "@aws-amplify/ui-react";
export default function InputToggle(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { btnToggle: {}, text: {}, txtKanri: {}, InputToggle: {} },
      variantValues: { property1: "Admin" },
    },
    {
      overrides: {
        btnToggle: {},
        text: { children: "SMS\u901A\u77E5" },
        txtKanri: {},
        InputToggle: {},
      },
      variantValues: { property1: "SMS" },
    },
    {
      overrides: {
        btnToggle: {},
        text: { children: "\u901A\u77E5" },
        txtKanri: {},
        InputToggle: {},
      },
      variantValues: { property1: "Tuuchi" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="288px"
      height="42px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "InputToggle")}
    >
      <BtnToggle
        width="42px"
        height="22px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="47.62%"
        bottom="0%"
        left="0%"
        right="85.42%"
        padding="0px 0px 0px 0px"
        toggle="off"
        {...getOverrideProps(overrides, "btnToggle")}
      ></BtnToggle>
      <View
        width="288px"
        height="12px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="71.43%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "txtKanri")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(160,160,160,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="288px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="管理者"
          {...getOverrideProps(overrides, "text")}
        ></Text>
      </View>
    </View>
  );
}
