/*
    概要：HowtoUseのページで使用する機能のないページ。安否確認項目送信ページ
*/
import React, {useState, memo} from 'react';
import {
    Top           ,//ページの一番上の部分
    BtnAdd        ,
    ListAttention ,
    Btn           ,
    Input02       ,
}
from '../../../ui-components';
import { AdminSubmitLayout } from '../../templates/AdminSubmitLayout';

export const AdminSubmit = memo(()=>{
    const Title = "2022/08/31 10:00 愛知県 津波";
    const Precautioninit = {
        "1": "",
        "2": "",
        "3": "",
    }
    const [Precautions, setPrecautions] = useState(Precautioninit)//注意事項
  
  
    return (
        <AdminSubmitLayout>
            <div>
            <div style={{paddingTop: "16px"}}>
                <Top
                    overrides = {
                        {
                            "MyIcon33471830":{//左の矢印ボタン
                            }
                        }
                    }
                    status = "Anpikoumoku"
                    width = {"90vw"}
                    style = {{margin:"auto", marginBottom:"32px"}}
                />
            </div>
            <div id = "title">
                <Input02
                    overrides = {
                        {
                            "text36392805":{
                                children: Title
                            },
                            "hissu":{
                                display:"none"
                            }
                        }
                    }
                    status = "EmployeeNumber"
                    kome ="on"
                    width = {"90vw"}
                    style = {{margin:"auto",marginBottom:"40px"}}
                />
            </div>
            <div style = {{width:"97%", margin: "auto"}}>
                <BtnAdd
                    id="noteAddition"
                    style = {{marginLeft: "1em",marginBottom:"8px"}}
                    property1 = "Attention"
                />
            </div>
    
            <div>
                {
                    Object.keys(Precautions).map((ID, index)=>{
                            let note = null;
                            let noteDelete = null;
                            if(index === 0){
                                note = "note"
                                noteDelete = "noteDelete"
                            }
                            return(
                                <div key = {ID} style={{marginBottom:"5px"}}>
                                    <ListAttention
                                        id = {note}
                                        key = {ID+"ListAttention"}
                                        overrides={
                                            {
                                                "MyIcon":{
                                                    id: noteDelete,
                                                },
                                                "text":{
                                                    display:"block",
                                                    fontSize:"12px",
                                                    marginBottom:"13px",
                                                    children : [<input type="text" placeholder="注意事項を入力" value={Precautions[ID]}  style = {{border: "none", outline: "none", width:"80vw", backgroundColor:"white"}}/>]
                                                }
                                            }
                                        }
                                        width={"90%"}
                                        margin={"auto"}
                                    />
                                </div>
                            )
                        }
                    )
                }
            </div>
            <div style = {{position:"absolute", bottom:"90px",left:"5%"}}>
                <Btn
                    property1 = "Touroku"
                    width = {"90vw"}
                />
            </div>
        </div>
      </AdminSubmitLayout>
    );
});