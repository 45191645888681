/*
  Voozy使い方ページのルーティング方法：
  　ユーザ説明部分のルーティング
*/
import { Page404 } from "../components/pages/Page404";
import { HowtouseHome } from "../components/pages/howtouse/HowtouseHome";
import { HowtouseExplain } from "../components/pages/howtouse/HowtouseExplain";
import { HowtouseLayout } from "../components/templates/HowtouseLayout";
import { OperationLayout } from "../components/templates/OperationLayout";

export const HowtouseHomeRoutes = [
  {
    //"/howtouse/home/"
    path: "/",
    exact: true,
    children: <HowtouseLayout><HowtouseHome/></HowtouseLayout>
  },
  {
    //"/howtouse/home/safetyconfirm"
    path: "/safetyconfirm",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    //"/howtouse/home/safetyresult"
    path: "/safetyresult",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    //"/howtouse/home/memberdetail"
    path: "/memberdetail",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    //"/howtouse/home/resultdetail"
    path: "/resultdetail",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    path: "*",
    exact: false,
    children: <Page404 />
  }
];

