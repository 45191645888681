/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ListKanriDetail(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "ListKanriDetail")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1988")}
      >
        <Flex
          gap="4px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1983")}
        >
          <Flex
            gap="96px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 214")}
          >
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="60px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="名前"
              {...getOverrideProps(overrides, "\u540D\u524D")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="100px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="竹下 博之"
              {...getOverrideProps(overrides, "\u7AF9\u4E0B \u535A\u4E4B")}
            ></Text>
          </Flex>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            {...getOverrideProps(overrides, "line36862954")}
          ></View>
        </Flex>
        <Flex
          gap="4px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1984")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 216")}
          >
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="60px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="メール"
              {...getOverrideProps(overrides, "\u30E1\u30FC\u30EB")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="take101@trafficsim.co.jp"
              {...getOverrideProps(overrides, "take101@trafficsim.co.jp")}
            ></Text>
          </Flex>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            {...getOverrideProps(overrides, "line36892576")}
          ></View>
        </Flex>
        <Flex
          gap="4px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1985")}
        >
          <Flex
            gap="96px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1987")}
          >
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="60px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="電話番号"
              {...getOverrideProps(overrides, "\u96FB\u8A71\u756A\u53F7")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="100px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="090-3858-9402"
              {...getOverrideProps(overrides, "090-3858-9402")}
            ></Text>
          </Flex>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            {...getOverrideProps(overrides, "line36892595")}
          ></View>
        </Flex>
        <Flex
          gap="184px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 221")}
        >
          <Flex
            gap="6px"
            direction="row"
            width="36px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1953")}
          >
            <MyIcon
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="trash02"
              {...getOverrideProps(overrides, "MyIcon34572390")}
            ></MyIcon>
            <Text
              fontFamily=""
              fontSize="10px"
              fontWeight=""
              color="rgba(255,76,108,1)"
              lineHeight="10px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="削除"
              {...getOverrideProps(overrides, "\u524A\u9664")}
            ></Text>
          </Flex>
          <Flex
            gap="6px"
            direction="row"
            width="36px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 194")}
          >
            <MyIcon
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="edit"
              {...getOverrideProps(overrides, "MyIcon34572393")}
            ></MyIcon>
            <Text
              fontFamily=""
              fontSize="10px"
              fontWeight=""
              color="rgba(160,160,160,1)"
              lineHeight="10px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="編集"
              {...getOverrideProps(overrides, "\u7DE8\u96C6")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
