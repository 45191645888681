/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function HowtoDefault(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: {},
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: {},
      },
      variantValues: { default: "User01" },
    },
    {
      overrides: {
        text: {
          children:
            "\u6700\u65B0\u306E\u88AB\u5BB3\u72B6\u6CC1\u3068\u904E\u53BB\u306E\u88AB\u5BB3\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: {},
      },
      variantValues: { default: "User02" },
    },
    {
      overrides: {
        text: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u56DE\u7B54\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: {},
      },
      variantValues: { default: "User03" },
    },
    {
      overrides: {
        text: {
          children:
            "\u56DE\u7B54\u72B6\u6CC1\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: {},
      },
      variantValues: { default: "User04" },
    },
    {
      overrides: {
        text: { children: "\u30E1\u30F3\u30D0\u30FC\u306E\u8FFD\u52A0" },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: { width: "unset", height: "unset" },
      },
      variantValues: { default: "Kanri02" },
    },
    {
      overrides: {
        text: {
          children:
            "\u5B89\u5426\u767B\u9332\u9805\u76EE\u3092\u30AB\u30B9\u30BF\u30E0\u3059\u308B",
        },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: { width: "unset", height: "unset" },
      },
      variantValues: { default: "Kanri03" },
    },
    {
      overrides: {
        text: {
          children:
            "\u901A\u77E5\u3059\u308B\u9707\u5EA6\u3068\u89B3\u6E2C\u5024\u3092\u8A2D\u5B9A\u3059\u308B",
        },
        MyIcon: {},
        Frame: {},
        Rectangle: {},
        HowtoDefault: { width: "unset", height: "unset" },
      },
      variantValues: { default: "Kanri01" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="288px"
      height="41px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="8px 0px 0px 0px"
      backgroundColor="rgba(245,245,245,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "HowtoDefault")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="安否登録をする"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="circle-chevron-down-solid 1"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(224,224,224,1)"
        {...getOverrideProps(overrides, "Rectangle")}
      ></View>
    </Flex>
  );
}
