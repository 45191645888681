/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function TxtAttention(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="288px"
      height="36px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "TxtAttention")}
    >
      <Text
        fontFamily=""
        fontSize="10px"
        fontWeight=""
        color="rgba(0,0,0,1)"
        lineHeight="18px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="288px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="※ 余震に気をつけてください&#xA;※ 無理はしないでください"
        {...getOverrideProps(
          overrides,
          "\u203B \u4F59\u9707\u306B\u6C17\u3092\u3064\u3051\u3066\u304F\u3060\u3055\u3044 \u203B \u7121\u7406\u306F\u3057\u306A\u3044\u3067\u304F\u3060\u3055\u3044"
        )}
      ></Text>
    </View>
  );
}
