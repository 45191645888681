/*
    概要：Admin.jsの震度値の設定をするモーダル
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { updateEarthQuaqeSettingSeismicIntensity } from '../../../graphql/mutations';
import {
    LayoutShindochiSentaku//震度選択モーダル
}
from '../../../ui-components';

export const EarthquakeIntensitySelectModal = memo((props) =>{
    const {
        EarthquakeIntensitySelectShow, setEarthquakeIntensitySelectShow, 
        setLoading, 
        GetEarthQuaqeSettingSeismicIntensity, 
        seismicIntensity 
    } = props;

    const [EarthquakeIntensity, setEarthquakeIntensity] = useState(""); //選択された震度値が入る
    const SeismicIntensityRef = useRef();                               //震度地選択モーダルで指定の要素以外をクリックしたときに検知できるようにする


    useEffect(()=>{
        setEarthquakeIntensity(seismicIntensity)
    },[seismicIntensity])


    useEffect(()=>{
        if(EarthquakeIntensitySelectShow){
            //モーダルが開いているとき処理する
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    },[EarthquakeIntensitySelectShow])

    /*
        概要：指定要素以外をクリックしたことを検知
    */
    const handleClickOutside = (e) => {
        if(SeismicIntensityRef){
            console.log("mousedown")
            if (!SeismicIntensityRef?.current?.contains(e.target)) {
                console.log("指定の要素以外クリックしました。")
                setEarthquakeIntensitySelectShow(false)
            }
        }
    };

    /*
        概要：完了ボタンをクリックしたときの処理
    */
    const completeClick = async() => {
        //load画面を見せる
        setLoading(true)
        
        
        try{

            //データベースへ選択した震度値を登録する
            const apiName          = "SafetyConfirmRest";
            const path             = "/updateParameterValue";
            const my_parameter_key = "Seismic_Intensity";
            const myInit           = {
                response             : true,
                queryStringParameters: {
                    "param_name" : my_parameter_key,
                    "param_value": EarthquakeIntensity
                }
            };

            await API.get(apiName, path, myInit)
            .then((data)=>{
                if(data.data.status === "error")
                {
                    throw new Error("API Internal Error");
                } 
            }).catch((e)=>{
                throw new Error("API Invoke Error");
            })


            //データベースへ登録した震度値を取得する
            await GetEarthQuaqeSettingSeismicIntensity()
            .then((data)=>{
                
            }).catch((e)=>{
                throw new Error("API Invoke Error");
            })
        }
        catch(e){
            console.error(e);
        }
        finally{
            //このモーダルを閉じる
            setEarthquakeIntensitySelectShow(false)
            //ロード画面を閉じる
            setLoading(false)
        }
    }


    if (EarthquakeIntensitySelectShow) 
    {
        let EarthquakeSettingSelect   = null; //LayoutShindochiSentakuコンポーネントの中の選択された部分
        let EarthquakeSettingProperty = null; //選択されたプロパティ
        for(var seismicIntensityNum = 0; seismicIntensityNum < Earthquakedict.length; seismicIntensityNum++){
            if(EarthquakeIntensity === Earthquakedict[seismicIntensityNum]["EarthquakeIntensity"]){
                EarthquakeSettingSelect   = Earthquakedict[seismicIntensityNum]["SelectElement"];
                EarthquakeSettingProperty = Earthquakedict[seismicIntensityNum]["selectAfterVar"];
            }
        }

        return (
            <div style={{position:"fixed", top:"0", left:"0", width:"100%", height:"100vh", backgroundColor:"rgba(0,0,0,0.5)", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={()=>{}}>
                <div ref = {SeismicIntensityRef}>
                    <LayoutShindochiSentaku
                        style = {{width:"175px"}}
                        overrides = {
                            {
                                listShindochi38283159:{
                                    property1: Earthquakedict[Earthquakedict_it_0]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_0]["EarthquakeIntensity"])}
                                },
                                listShindochi38283507:{
                                    property1: Earthquakedict[Earthquakedict_it_1]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_1]["EarthquakeIntensity"])}
                                },
                                listShindochi38283523:{
                                    property1: Earthquakedict[Earthquakedict_it_2]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_2]["EarthquakeIntensity"])}                            
                                },
                                listShindochi38283531:{
                                    property1: Earthquakedict[Earthquakedict_it_3]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_3]["EarthquakeIntensity"])}                            
                                },
                                listShindochi38283541:{
                                    property1: Earthquakedict[Earthquakedict_it_4]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_4]["EarthquakeIntensity"])}                            
                                },
                                listShindochi38283553:{
                                    property1: Earthquakedict[Earthquakedict_it_5]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_5]["EarthquakeIntensity"])}                            
                                },
                                listShindochi38283567:{
                                    property1: Earthquakedict[Earthquakedict_it_6]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_6]["EarthquakeIntensity"])}                            
                                },
                                listShindochi38283583:{
                                    property1: Earthquakedict[Earthquakedict_it_7]["selectbeforeVar"],
                                    onClick: () => {setEarthquakeIntensity(Earthquakedict[Earthquakedict_it_7]["EarthquakeIntensity"])}                            
                                },
                                btnKanryo:{
                                    onClick: () => {completeClick()}
                                },
                                /*色を変えるプログラムを書いて上書きする*/
                                [EarthquakeSettingSelect]:{
                                    property1: EarthquakeSettingProperty,
                                    onClick: () => {}
                                }
                            }
                        }
                    />
                </div>
            </div>
        );
    } 
    else {
        return null;
    }
});


/*辞書***************************************************************************************/
/*
    Earthquakedictの要素の何番目かを示しています
*/
const Earthquakedict_it_0 = 0;
const Earthquakedict_it_1 = 1;
const Earthquakedict_it_2 = 2;
const Earthquakedict_it_3 = 3;
const Earthquakedict_it_4 = 4;
const Earthquakedict_it_5 = 5;
const Earthquakedict_it_6 = 6;
const Earthquakedict_it_7 = 7;

/*
    Earthquakedict
        EarthquakeIntensity : 震度値
        selectbeforeVar     : 選択前のvariant
        selectAfterVar      : 選択後のvariant
        SelectElement       : 要素
*/
const Earthquakedict = [
    {
        EarthquakeIntensity     : "2",
        selectbeforeVar         : "Default02",
        selectAfterVar          : "Select02",
        SelectElement           : "listShindochi38283159"
    },
    {
        EarthquakeIntensity     : "3",
        selectbeforeVar         : "Default03",
        selectAfterVar          : "Select03",
        SelectElement           : "listShindochi38283507"
    },
    {
        EarthquakeIntensity     : "4",
        selectbeforeVar         : "Default04",
        selectAfterVar          : "Select04",
        SelectElement           : "listShindochi38283523"
    },
    {
        EarthquakeIntensity     : "5弱",
        selectbeforeVar         : "Default05W",
        selectAfterVar          : "Select05W",
        SelectElement           : "listShindochi38283531"
    },
    {
        EarthquakeIntensity     : "5強",
        selectbeforeVar         : "Default05S",
        selectAfterVar          : "Select05S",
        SelectElement           : "listShindochi38283541"
    },
    {
        EarthquakeIntensity     : "6弱",
        selectbeforeVar         : "Default06W",
        selectAfterVar          : "Select06W",
        SelectElement           : "listShindochi38283553"
    },
    {
        EarthquakeIntensity     : "6強",
        selectbeforeVar         : "Default06S",
        selectAfterVar          : "Select06S", 
        SelectElement           : "listShindochi38283567"
    },
    {
        EarthquakeIntensity     : "7",
        selectbeforeVar         : "Default07",
        selectAfterVar          : "Select07",
        SelectElement           : "listShindochi38283583"
    }
]