import { memo } from "react";
import { Top } from "../../ui-components";
import { useHistory } from "react-router-dom";
export const OperationLayout = memo((props)=>{
    const { children } = props;
    const hisotry = useHistory();
    return(
        //  無理やりスクロールできないようにしている
        <div style={{overflow: "hidden", height:"100vh"}}>
            <div style={{width:"100%", height:"50px", display: "flex", justifyContent: "center"}}>
                <Top
                    status = "howtoVoozy"
                    width = "90%"
                    margin = "auto"
                    overrides = {
                        {
                            MyIcon33471830:{
                                onClick: () => {hisotry.goBack()}
                            }
                        }
                    }
                />
            </div>
            {children}
            
        </div>
    );
});