/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function BtnAnpiBuji(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { MyIcon: {}, text: {}, BtnAnpiBuji: {} },
      variantValues: { status: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="10px 8px 10px 8px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "BtnAnpiBuji")}
    >
      <MyIcon
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="buji_g"
        {...getOverrideProps(overrides, "MyIcon")}
      ></MyIcon>
      <Text
        fontFamily=""
        fontSize="14px"
        fontWeight=""
        color="rgba(1,185,133,1)"
        lineHeight="14px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="124px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="無事"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
