/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ListShindochi(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, ListShindochi: {} },
      variantValues: { property1: "Default02" },
    },
    {
      overrides: { text: { children: "3" }, ListShindochi: {} },
      variantValues: { property1: "Default03" },
    },
    {
      overrides: { text: { children: "4" }, ListShindochi: {} },
      variantValues: { property1: "Default04" },
    },
    {
      overrides: { text: { children: "5\u5F37" }, ListShindochi: {} },
      variantValues: { property1: "Default05S" },
    },
    {
      overrides: { text: { children: "5\u5F31" }, ListShindochi: {} },
      variantValues: { property1: "Default05W" },
    },
    {
      overrides: { text: { children: "6\u5F37" }, ListShindochi: {} },
      variantValues: { property1: "Default06S" },
    },
    {
      overrides: { text: { children: "6\u5F31" }, ListShindochi: {} },
      variantValues: { property1: "Default06W" },
    },
    {
      overrides: { text: { children: "7" }, ListShindochi: {} },
      variantValues: { property1: "Default07" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select02" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "3" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select03" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "4" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select04" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "5\u5F37" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select05S" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "5\u5F31" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select05W" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "6\u5F37" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select06S" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "6\u5F31" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select06W" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "7" },
        ListShindochi: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { property1: "Select07" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="168px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="12px 8px 12px 8px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ListShindochi")}
    >
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(0,0,0,1)"
        lineHeight="12px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="160px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="2"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
