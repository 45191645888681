/*Voozy使い方ページのLayout*/
import { memo } from "react";
import { HeaderHowtouse } from "../organisms/layout/HeaderHowtouse"; 

export const HowtouseLayout = memo((props) => {
  const { children } = props;
  return (
    <div style={{overflow:"hidden",height:"100vh"}}>
      <HeaderHowtouse/>
      {children}
    </div>
  );
});
