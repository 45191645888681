/*
    概要：安否回答ページ
*/

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, memo} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { createAnswer} from '../../../graphql/mutations';
import { monotonicFactory } from 'ulid';
import {
    MyText,
    MyIcon,
} from '../../../ui-components';

import { useSafeConfrimData } from '../../../hooks/useSafeConfirmData';//必要なデータを取得するカスタムフック
import { useLoginUser } from "../../../hooks/providers/useLoginUserProvider";
import { RingLoadingModalWhite } from "../../organisms/loading/RingLoadingModalWhite";
import { useMessage } from "../../../hooks/useMessage";
import { SafetyBottons } from "../../molecules/buttons/SafetyBottons"
import { useReport } from '../../../hooks/providers/useReportProvider';

import { MultiButton } from '../../molecules/buttons/MultiButton';

export const SafetyReport = () =>{
    const history                 = useHistory();
    const { showMessage }         = useMessage();
    const [inputError, setinputError] = useState(false); //入力漏れがあるかどうかの確認をする
    const { GetData, setLoading, loading, LatestEarthquakeInfo, Custommode, open } = useSafeConfrimData();//データの取得
    const { loginUser }           = useLoginUser();
    const { TextBox, report, setReport }  = useReport();


    //初回実行時に動作
    useEffect(() => {
        GetData();
    }, []);

    //条件を満たしたら入力漏れの表示を消します
    useEffect(()=>{
        if(report.length === 2 && inputError)
        {
            setinputError(false);
        }
    },[report])

    /*関数群*************************************************************************************************************/
    /*
        概要：入力漏れ対応する処理
    */
    const Reportomission = (report) =>{
        const LimitAnswerLen = 2;
        if(report.length === LimitAnswerLen){
            return true
        }
        else{
            setinputError(true)
            return false
        }
    }

    /*
        概要：登録ボタンクリック時の処理
        引数：
            report : 回答内容
            TextBox: 備考欄の入力内容
            userId : ユーザのID
    */
    const RegisterButtonClickFunc = async(args) =>{
        const {report, TextBox, userId} = args;
        
        //ulidの生成
        const ulid = monotonicFactory()
        // create_dataで登録するsummary
        const summary_data = ulid()
        
        // createAnswerで登録するデータがすべてここへ入る
        let answer_data_all = []
        
        // 入力漏れの確認
        if(Reportomission(report)){
            // ロード画面の表示
            setLoading(true)

            if(report){
                for(var questionNum=0; questionNum < report.length; questionNum++){
                    if(questionNum === 0 && TextBox !== "")
                    {

                        let answer_data = {
                            "id"        : ulid(),
                            "answer"    : report[questionNum].answer,
                            "userId"    : userId,
                            "questionId": report[questionNum].questionId,
                            "summary"   : summary_data,
                            "comment"   : CTRL_CHAR(TextBox)
                        }
                        answer_data_all.push(answer_data)
                    }
                    else
                    {
                        let answer_data = {
                            "id"        : ulid(),
                            "answer"    : report[questionNum].answer,
                            "userId"    : userId,
                            "questionId": report[questionNum].questionId,
                            "summary"   : summary_data,
                            "comment"   : null
                        }
                        answer_data_all.push(answer_data)
                    }
                }
            }

            // 回答データの登録
            await API.graphql(graphqlOperation(createAnswer, {createAnswerInput: answer_data_all }))
            .catch((e)=>{
                console.error(e);
                showMessage({message:"API登録時エラー"});
            })
            .finally(()=>{
                // ロード画面を閉じる
                setLoading(false);
                // 画面遷移or完了モーダル
                history.push('/safetyresult');
            })
        }
    }

    /**********************************************************************************************************************/

    if(open){
        return(    
            <>
                {loading ? (
                    <RingLoadingModalWhite/>
                ):(
                    //ヘッダー(48px)+フッター(50px)=98px
                    <div style = {{overflowY:"auto", height:`calc(100vh - 98px)`}}>
                        {(() => {
                            return (                
                                <div style={{display:"flex", flexDirection:"column", height:"100%"}}>
                                    <div style={{minHeight:"364px", maxHeight:"100%", marginBottom:"0px"}}>
                                        <TimeAndContent
                                            LatestEarthquakeInfo = {LatestEarthquakeInfo}
                                            loginUser            = {loginUser} 
                                            Custommode           = {Custommode}
                                        />
                                        <SafetyBottons
                                        />
                                    </div>
                                    <div style={{width:"100%", minHeight:"fit-content", marginTop:"auto",marginBottom:"200px"}}>
                                        <MultiButton
                                            CallBackFunc = {RegisterButtonClickFunc}
                                            omission     = {inputError} 
                                            pattern      = {"default"}
                                            text         = {"登録"}
                                            report       = {report}
                                            TextBox      = {TextBox}
                                            userId       = {loginUser.id}
                                        />
                                    </div>
                                </div>
                            )
                        })()}
                    </div>
                )}
            </> 
        );
    }
    else{
        return <RingLoadingModalWhite/>;
    }
}


/**コンポーネント群******************************************************************************************************************************************************************/
/*
    概要： 発生時間、内容を表示するコンポーネント
*/
const TimeAndContent = memo((props) => {
    const {LatestEarthquakeInfo, loginUser, Custommode} = props;
    let format_str = FormatContent(Custommode, LatestEarthquakeInfo);

    return (
        <div style={{display:"flex", flexDirection:"column", width:"90%", margin:"auto"}}>
            <MyText
                overrides = {
                    {
                        "text":{
                            children: format_str,
                            wordwrap: "break-word",
                            height  : "unset",
                            position: "unset",
                        },
                        "MyText":{
                            height: "fit-content"
                        }
                    }
                }
                status       = {"medium"}
                marginTop    = {"24px"}
                marginBottom = {"22px"}
                width        = {"100%"}
                height       = {"fit-content"}
            />
            <div style={{display:"flex", flexFlexDirection:"row", marginBottom:"22px"}}>
                <MyIcon
                    type = {"user"}
                />
                <MyText
                    overrides = {
                        {
                            "text":{
                                children: loginUser.name,
                            }
                        }
                    }
                    status = {"medium"}
                />   
            </div>
        </div>   
    )
});




/**関数群******************************************************************************************************************************************************************/
/*
    概要：日付から文字列に変換する関数
*/
const  getStringFromDate = (date) => {
    let year_str   = date.substr(0,4);
    let month_str  = date.substr(4,2);
    let day_str    = date.substr(6,2);
    let hour_str   = date.substr(8,2);
    let minute_str = date.substr(10,2);
    
    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
}

/*
    概要：どうやら/nとかのエスケープシークエンスキーは使えないのでreplaceで/nを空白に置き換える
*/
const CTRL_CHAR = (sentence) => {
    let res = sentence;
    res = res.replace(/\r?\n/g, '<br>');//改行はbr変換
    res = res.replace(/\s+/g, '');//空白は''に変換
    res = res.replace(/"/g, "'");//""を''に変換
    return res;
}

/*
    概要：地震情報、Customの時間、内容を表示する部分を作る関数
*/
const FormatContent = (Custommode, LatestEarthquakeInfo) => {
    if(Custommode)
    {
        //指定のフォーマットになるように変形をする
        let format_str = 'datetime title';//指定のフォーマット
        let datetime = getStringFromDate(LatestEarthquakeInfo.datetime)//datetimeを指定の形に変換
        let title = LatestEarthquakeInfo.title//タイトルを取得
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/title/g, title);
        return format_str;
    }
    else
    {
        let format_str = 'datetime spot 震度seismicIntensity';//指定のフォーマット
        let datetime=getStringFromDate(LatestEarthquakeInfo.datetime)//datetimeを指定の形に変換
        let spot = LatestEarthquakeInfo.spot
        let seismicIntensity = LatestEarthquakeInfo.seismicIntensity
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/spot/g, spot);
        format_str = format_str.replace(/seismicIntensity/g, seismicIntensity);
        return format_str;
    }
}