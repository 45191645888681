/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ListMenu(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { MyIcon: {}, text: {}, "Frame 1980": {}, ListMenu: {} },
      variantValues: { property1: "AccountSettings" },
    },
    {
      overrides: {
        MyIcon: { type: "key" },
        text: { children: "\u7BA1\u7406\u8005\u8A2D\u5B9A" },
        "Frame 1980": {},
        ListMenu: {},
      },
      variantValues: { property1: "KanriSetup" },
    },
    {
      overrides: {
        MyIcon: { type: "square-pen" },
        text: {
          children:
            "\u5B89\u5426\u78BA\u8A8D\u9805\u76EE\u3092\u30AB\u30B9\u30BF\u30E0",
        },
        "Frame 1980": {},
        ListMenu: {},
      },
      variantValues: { property1: "anpikakuninkoumoku" },
    },
    {
      overrides: {
        MyIcon: { type: "howto" },
        text: { children: "Voozy\u306E\u4F7F\u3044\u65B9" },
        "Frame 1980": {},
        ListMenu: {},
      },
      variantValues: { property1: "HowtoVoozy" },
    },
    {
      overrides: {
        MyIcon: { type: "logout" },
        text: { children: "\u30B5\u30A4\u30F3\u30A2\u30A6\u30C8" },
        "Frame 1980": {},
        ListMenu: {},
      },
      variantValues: { property1: "SignOut" },
    },
    {
      overrides: {
        MyIcon: { type: "feedback" },
        text: { children: "\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF" },
        "Frame 1980": {},
        ListMenu: {},
      },
      variantValues: { property1: "Feedback" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="240px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="8px 32px 8px 32px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ListMenu")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 1980")}
      >
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="gear"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(160,160,160,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="156px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="アカウント設定"
          {...getOverrideProps(overrides, "text")}
        ></Text>
      </Flex>
    </Flex>
  );
}
