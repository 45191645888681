/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function Layoutsituationcheckdetailcomment(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="40px 40px 0px 0px"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(43,133,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Layoutsituationcheckdetailcomment")}
    >
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 487")}
      >
        <View
          width="40px"
          height="4px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="2px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Rectangle 14")}
        ></View>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="232px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 215")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 486")}
          >
            <Text
              fontFamily=""
              fontSize="14px"
              fontWeight=""
              color="rgba(255,255,255,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="都築 梨央"
              {...getOverrideProps(overrides, "\u90FD\u7BC9 \u68A8\u592E")}
            ></Text>
            <Flex
              gap="8px"
              direction="row"
              width="172px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 452")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(255,255,255,1)"
                lineHeight="14px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="被害状況："
                {...getOverrideProps(
                  overrides,
                  "\u88AB\u5BB3\u72B6\u6CC1\uFF1A"
                )}
              ></Text>
              <MyIcon
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="higai_w"
                {...getOverrideProps(overrides, "MyIcon")}
              ></MyIcon>
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(255,255,255,1)"
                lineHeight="14px"
                textAlign="right"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="自分に被害あり"
                {...getOverrideProps(
                  overrides,
                  "\u81EA\u5206\u306B\u88AB\u5BB3\u3042\u308A"
                )}
              ></Text>
            </Flex>
          </Flex>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Rectangle 15")}
          ></View>
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="185px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="左足を骨折をしました。病院で診てもらう予定です。左足を骨折をしました。病院で診てもらう予定です。左足を骨折をしました。病院で診てもらう予定です。"
            {...getOverrideProps(
              overrides,
              "\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002\u5DE6\u8DB3\u3092\u9AA8\u6298\u3092\u3057\u307E\u3057\u305F\u3002\u75C5\u9662\u3067\u8A3A\u3066\u3082\u3089\u3046\u4E88\u5B9A\u3067\u3059\u3002"
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
