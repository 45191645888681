/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ListKanri(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="288px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      fontFamily=""
      fontWeight=""
      {...rest}
      {...getOverrideProps(overrides, "ListKanri")}
    >
      <Text
        fontFamily="YuGothic"
        fontSize="12px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="80px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="名前"
        {...getOverrideProps(overrides, "\u540D\u524D")}
      ></Text>
      <Text
        fontFamily="YuGothic"
        fontSize="12px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="12px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="160px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="竹下 博之"
        {...getOverrideProps(overrides, "\u7AF9\u4E0B \u535A\u4E4B")}
      ></Text>
    </Flex>
  );
}
