/*
    概要：トグルボタン
*/
import { memo } from "react";
import React, {useState,useEffect} from 'react';
import {InputToggle} from "../../../ui-components";

export const ToggleButton = (props) => {
    const {dictkey, setValue, property, dict , ...rest }= props;
        
    let toggleFlag = "off";
    if(dict[dictkey] === true)
    {
        toggleFlag = "on";
    }
    else if(dict[dictkey] === false)
    {
        toggleFlag = "off";
    }

    return(
        <InputToggle
            overrides = {
                {
                    "btnToggle":{
                        toggle : toggleFlag
                    }
                }
            }
            property1    = {property}
            onClick      = {()=>{setValue({...dict, [dictkey]: !dict[dictkey]})}}
            marginBottom = {"14px"}
            {...rest}
        />
    )
};