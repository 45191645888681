/*
    概要：入力フォームの入力欄
*/
import { memo } from "react";
import React, {useState,useEffect} from 'react';
import {Input02} from "../../../ui-components";

export const InputText = (props) => {
    const {dict, dictkey, setValue, status, kome, Type, text, ...rest} = props;
    
    let komeCSS = "none";
    if(kome === true)
    {
        komeCSS = "inline-block"
    }
    else if(kome === false)
    {
        komeCSS = "none";
    }

    return (
        <Input02
            key    = {status+"Input02"}
            status = {status}
            kome   = {"on"}
            overrides = {
                {
                    "text36392805":{
                        children: [<input key={status+"input"} type={Type} placeholder="" value={dict[dictkey]} onChange={(e) => setValue({...dict, [dictkey]: e.target.value})} style = {{border: "none", outline: "none",   width:"90vw",backgroundColor:"#F5F5F5"}}/>]
                    },
                    "hissu":{
                        display: komeCSS
                    },
                    "text36392801":{
                        children: text
                    }
                }
            }
            width        = {"90vw"}
            margin       = {"auto"}
            marginBottom = {"14px"}
            {...rest}
        />
    )
};

