/*
    概要：HowtoUseのページで使用する機能のないページ。震度値と観測値を設定するページ。
*/

import React, {memo} from "react";
import {
    Top             ,   //ページの一番上の部分
    TxtShindo       ,   //震度
    InputShindochi  ,   //震度値
    TxtKnansokuchi  ,   //観測値
    InputKansokuchi ,   //観測地を選択する
    TxtUser         ,   //ユーザ
    BtnAdd          ,
}
from '../../../ui-components';
import { UserSettingTable } from "./UserSettingTable";
import { ObservationSelected } from "../AdminLayout/ObservationSelected";


export const EarthquakeSettingPage = memo(()=>{
    const spot             = "";
    const seismicIntensity = "";
    const UserList = 
        [
            {
                adminFlag: true,
                employeeNumber: 0,
                id: "1",
                mail: "take101@trafficsim.co.jp",
                mailStatus: true,
                name: "山田太郎",
                phone: "+819038589402",
                smsStatus: true
            },
            {
                adminFlag: true,
                employeeNumber: 0,
                id: "2",
                mail: "take101@trafficsim.co.jp",
                mailStatus: true,
                name: "山田次郎",
                phone: "+819038589402",
                smsStatus: true
            },
            {
                adminFlag: true,
                employeeNumber: 0,
                id: "3",
                mail: "take101@trafficsim.co.jp",
                mailStatus: true,
                name: "山田三郎",
                phone: "+819038589402",
                smsStatus: true
            }
        ]

    //関数でまとめたい。
    /*観測地コンポーネントにどちらを使用するか判定する*/
    let observation = ""
    if(spot === ""){
        {/*観測地コンポーネント(観測地が設定されていないとき)*/}
        observation = <InputKansokuchi
                        style = {{marginLeft:"5%", marginBottom:"34px"}}
                        spot = {spot}
                    />
    }
    else{
        {/*観測地コンポーネント(観測地の登録がされているとき)*/}
        observation = <ObservationSelected
                        spot = {spot}    
                    />
    }

    /*震度値の設定値からコンポーネントにどちらを使用するか判定する*/
    let seismicIntensityComponent = ""
    //震度値が設定されていないとき
    if(seismicIntensity === ""){
        seismicIntensityComponent = 
            <InputShindochi
                property1 = "Default"
                width = {"90vw"}
                style = {{margin: "auto"}}
                overrides = {
                    {
                        MyIcon: {
                        }
                    }
                }
            />
    }
    //設定されている震度値を取得したとき
    else{
        seismicIntensityComponent = 
            <InputShindochi
                property1 = "select"
                width = {"90vw"}
                style = {{margin: "auto"}}
                overrides = {
                    {
                        MyIcon:{
                        },
                        text:{
                            children: seismicIntensity
                        }
                    }
                }
            />
    }
    return (
        <div style = {{display: "Flex", flexDirection: "column", height: "fit-content", minHeight:"100vh", backgroundColor: "#F5F5F5"}}>
            <Top
                status       = {"KanriSetting"}
                width        = {"90vw"}
                margin       = {"auto"}
                paddingTop   = {"16px"}
                marginBottom = {"24px"}
                marginTop    = {"0px"}
            />
            <main>
                {/*震度設定コンポーネント*/}
                <div style = {{marginBottom: "24px"}} id = "earthquakeIntensity">
                    <TxtShindo
                        width = {"90vw"}
                        style = {{margin: "auto"}}
                    />
                    {/*震度設定コンポーネント*/}
                    {seismicIntensityComponent}
                </div>

                {/*観測地のコンポーネント*/}
                <div style = {{marginBottom:"32px"}} id = "ObservationSite">
                    <div style = {{display:"block", marginBottom: "8px"}}>
                        <TxtKnansokuchi
                            width = {"90vw"}
                            style = {{marginLeft: "auto", marginRight:"auto"}}
                        />
                    </div>
                    <div>
                        {/*観測地設定コンポーネント*/}
                        {observation}
                    </div>
                </div>

                
                {/*ユーザリストコンポーネント*/}
                <div style = {{display:"Flex", width:"90vw", margin:"auto", justifyContent: "space-between", marginBottom:"8px"}}>
                    <TxtUser
                    />
                    <BtnAdd
                        id="UserInsert"
                    />
                </div>

                {/*ユーザ一一覧部分*/}
                {(() => {
                    return(
                        <UserSettingTable
                            UserList= {UserList}
                        />
                    )
                })()}
            </main>
        </div>
    )
})
