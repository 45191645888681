/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Top(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { MyIcon33471830: {}, text: {}, MyIcon33471831: {}, Top: {} },
      variantValues: { status: "Joukyoukakunin" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: { height: "18px", children: "\u89B3\u6E2C\u5730\u9078\u629E" },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "Kansokuchisentaku" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: { height: "18px", children: "\u7BA1\u7406\u8005\u8A2D\u5B9A" },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "KanriSetting" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: {
          height: "18px",
          children: "\u30E6\u30FC\u30B6\u30FC\u767B\u9332",
        },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "UserAdd" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: {
          height: "18px",
          children: "\u30E6\u30FC\u30B6\u30FC\u7DE8\u96C6",
        },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "UserEdit" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: {
          height: "18px",
          children: "\u30E6\u30FC\u30B6\u30FC\u524A\u9664",
        },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "UserDelete" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: {
          height: "18px",
          children: "\u5B89\u5426\u78BA\u8A8D\u9805\u76EE\u9001\u4FE1",
        },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "Anpikoumoku" },
    },
    {
      overrides: {
        MyIcon33471830: {},
        text: {
          height: "18px",
          children: "\u64CD\u4F5C\u753B\u9762\u8AAC\u660E",
        },
        MyIcon33471831: { type: "xmark02" },
        Top: {},
      },
      variantValues: { status: "howtoVoozy" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="26px"
      direction="row"
      width="288px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Top")}
    >
      <MyIcon
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="angle-left"
        {...getOverrideProps(overrides, "MyIcon33471830")}
      ></MyIcon>
      <Text
        fontFamily=""
        fontSize="14px"
        fontWeight=""
        color="rgba(0,0,0,1)"
        lineHeight="21px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="220px"
        height="40px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="2022/04/15 11:50&#xA;愛知県 震度5強"
        {...getOverrideProps(overrides, "text")}
      ></Text>
      <MyIcon
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="xmark"
        {...getOverrideProps(overrides, "MyIcon33471831")}
      ></MyIcon>
    </Flex>
  );
}
