/*
    概要：Icon＋下に文字列のボタンコンポーネント
    引数：
        Content　　　　　 : 文字列
        RGB    　　　　　 : 選択されたときの色
        IconStatus       : 使用するアイコン
*/
import React, { memo, useState } from 'react';
import {
    BtnAnpiBuji,     //無事ボタン
} from '../../../ui-components';
import { monotonicFactory } from 'ulid';

export const IconContentButton = (props) => {
    const {bgColor, textColor, Content, IconStatus, ...rest} = props;
    const ulid  = monotonicFactory();//ulidの生成
    return (
        <BtnAnpiBuji
            overrides = {
                {
                    "text":{
                        children : Content,
                        color    : textColor,
                        key      : `${ulid()}text`
                    },
                    "MyIcon":{
                        type : IconStatus,
                        key  : `${ulid()}MyIcon`
                    },
                }
            }
            status          = {"default"}
            backgroundColor = {bgColor}
            key             = {`${ulid()}BtnAnpiBuji`}
            {...rest}
        />
    );
}
