/*
    Voozy使い方ページのルーティング方法：
  　    管理者説明部分のルーティング
*/
import { Page404 } from "../components/pages/Page404";
import { HowtouseAdmin } from "../components/pages/howtouse/HowtouseAdmin";
import { HowtouseLayout } from "../components/templates/HowtouseLayout";
import { OperationLayout } from "../components/templates/OperationLayout";
import { HowtouseExplain } from "../components/pages/howtouse/HowtouseExplain";

export const HowtouseAdminRoutes = [
  {
    //"/howtouse/admin/"
    path: "/",
    exact: true,
    children: <HowtouseLayout><HowtouseAdmin /></HowtouseLayout>
  },
  {
    //"/howtouse/admin/earthquakesetting"
    path: "/earthquakesetting",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    //"/howtouse/admin/membersetting"
    path: "/membersetting",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    //"/howtouse/admin/adminsubmit"
    path: "/adminsubmit",
    exact: false,
    children: <OperationLayout><HowtouseExplain /></OperationLayout>
  },
  {
    path: "*",
    exact: false,
    children: <Page404 />
  }
];

