/*
  概要：安否確認項目送信ページで使用するuseContext
*/
import React, { createContext, useContext, useState } from "react";

const AdminSubmitContext = createContext({});

export const AdminSubmitProvider = (props) => {
  const { children }                            = props;
  const [Title, setTitle]                       = useState("");    //タイトル
  const [Precautions, setPrecautions]           = useState({});    //注意事項
  const [CompleteviewFlag, setCompleteViewFlag] = useState(false); //登録完了モーダルを開くか否かのフラグ
  const [TitleTime, setTitleTime]               = useState("");    //タイトルに入る時間(表示用)
  const [PreviewFlag, setPreviewFlag]           = useState(false); //プレビュー画面の表示をするか否かのフラグ
  const [InputError, setInputError]             = useState(false); //入力漏れ
  const [loading, setloading]                   = useState(false); //ローディングを画面を表示するか否かのフラグ
  const [InsertTime, setInsertTime]             = useState("");    //データに登録する用の時間

  const value = {
    Title           , setTitle           ,
    Precautions     , setPrecautions     ,
    CompleteviewFlag, setCompleteViewFlag, 
    TitleTime       , setTitleTime       ,
    PreviewFlag     , setPreviewFlag     ,
    InputError      , setInputError      ,
    loading         , setloading         ,
    InsertTime      , setInsertTime
  }

  return (
    <AdminSubmitContext.Provider value={value}>
      {children}
    </AdminSubmitContext.Provider>
  );
};

export const useAdminSubmitContext = () => useContext(AdminSubmitContext);