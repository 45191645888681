/*
    概要：HowtoUseのページで使用する機能のないページ。安否結果ページの詳細を表示しているページ。
*/

import React, {memo} from 'react';
import { ulid } from 'ulid';
import {
    HeadJoukyoukakunin02 , //モーダル内の詳細部分
    Choice               , //被害あり、被害なし、未回答を選択するボタン
    ListJoukyoukakunin01 , //表で表示する部分// CheckCard01,
    ListJoukyoukakunin02 , //表で表示する部分// CheckCard02,
    ListJoukyoukakunin03 , //表で表示する部分// CheckCard03
    Middle               , //真ん中部分
    Top                  , //Topコンポーネント
} from '../../../ui-components'


export const ResultDetailPage = memo(()=>{
    return(
            <UserStatusDetailModal2/>
    );
});

/*<br>があったらbrタグに変換する*/
/*keyに関してはuuidを使ったら確実な気がする*/
/*備考に入力した改行などを変形させていく*/
const JudgeComment =(comment)=>{
    let items=[]
    while(comment.includes('<br>')){
        let idx = comment.indexOf('br')
        let text=comment.slice(0, idx - 1);
        comment = comment.slice(idx + 3);
        items.push(text)
        items.push(<br key={ulid()}/>)
    }
    items.push(comment)
    return(items);
}

//日付から文字列に変換する関数
const getStringFromDate = (date) => {
    let year_str = date.substr(0,4);
    let month_str = date.substr(4,2);
    let day_str = date.substr(6,2);
    let hour_str = date.substr(8,2);
    let minute_str = date.substr(10,2);
    
    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
}

//地震の文章フォーマット通りに成形する関数
//1. 辞書配列のキーの長さからCustomかEarthquakeから取得したデータなのかを判定
//2. それぞれの場合のフォーマットで出力する 
const EarthquakeFormatConv = (EarthquakeInfo) => {
    const dataLength = Object.keys(EarthquakeInfo).length//取得データのキーの長さが入る
    const customLength = 3//カスタムデータだったとき
    const earthquakeLength = 4//地震データだったとき
    let format_str//指定のフォーマット
    if(dataLength === customLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let title = EarthquakeInfo.attributes.Title
        format_str = 'datetime title';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/title/g, title);
    }
    else if(dataLength === earthquakeLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let spot = EarthquakeInfo.spot
        let seismicIntensity = EarthquakeInfo.seismicIntensity
        format_str = 'datetime <br> spot 震度seismicIntensity';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/spot/g, spot);
        format_str = format_str.replace(/seismicIntensity/g, seismicIntensity);
    }
    return format_str
}


const SelectionButtonsClassAttach = () => {
    let bGStatusSafe = "white";//ボタンの色を切り替えるプロパティの値
    let bGStatusDamage = "white";//ボタンの色を切り替えるプロパティの値
    let bGStatusUnAnswered = "white";//ボタンの色を切り替えるプロパティの値
    const UserStatusDetailModalButton = "Damage"
    switch(UserStatusDetailModalButton){
        case "Safe"://No damage
            bGStatusSafe = "blue"
            break;

        case "Damage"://Damage
            bGStatusDamage = "blue"
            break;

        case "UnAnswered"://No answer
            bGStatusUnAnswered = "blue"
            break;
    }

    return (
        <div id="selection" style={{width:"90%", margin:"auto", paddingTop:"8px", paddingBottom:"8px",marginBottom:"16px"}}>
            <Choice
                overrides = {
                    {
                        btnChoice34533985:{
                            bg: bGStatusDamage,
                            // onClick: ()=> {ElectionControler("Damage", props.UserReportsModal)}//not use
                        },
                        btnChoice34533986:{
                            bg: bGStatusSafe,
                            // onClick: ()=> {ElectionControler("Safe", props.UserReportsModal)}//not use
                        },
                        btnChoice34533987:{
                            bg: bGStatusUnAnswered,
                            // onClick: ()=> {ElectionControler("UnAnswered", props.UserReportsModal)}//not use
                        }

                    }
                }
                margin={"auto"}
            />
        </div>
    )
}   

//ユーザIDからユーザ名に変換する関数
const UserIdChangeName = (UserId) => {
    const listUserSetting = {
        "1": {"employeeNumber": 1, "name": '山田 太郎'},
        "2": {"employeeNumber": 2, "name": '山田 次郎'},
        "3": {"employeeNumber": 3, "name": '山田 三郎'}
    }

    return listUserSetting[UserId].name
}

//ユーザのStatusをアイコンに変換する関数
// let UserStatus = {"NumberOfResponse": 0, "Safe": 0, "FamilyHealthDamage": 0, "MyHealthDamage": 0, "HomeDamage": 0, "Unanswered": 0}
const UserStatusChangeIcon = (Status) => {
    let type ="";
    switch(Status){
        case "Safe"://無事//line
            type = "line"
            break;

        case "FamilyHealthDamage"://家族被害あり//higai-family
            type = "higai-family"
            break;

        case "HomeDamage"://家被害あり//higai-house
            type = "higai-house"
            break;

        case "MyHealthDamage"://自分被害あり//higai_k
            type = "higai_k"
            break;

        case "UnAnswered"://未回答//line
            type = "line"
            break;

        /*-------------------------------------------------------------------白色--------------------------------------------------------------------------------------------------------------------------------------------*/
        case "Safe_w"://無事白色
            type = "line-white"
            break;

        case "FamilyHealthDamage_w"://家族被害あり白色
            type = "higai-family-w"
            break

        case "HomeDamage_w"://家被害あり白色
            type = "higai-house-w"
            break
        
        case "MyHealthDamage_w"://自分被害あり
            type = "higai_w"
            break
    }
    return type
}

//モーダルないで使用する関数
//回答日時を指定のフォーマットに変換
const AnswerDateTimeModalFunc = (datetime) => {//datetime:"20220202222222"
    if(datetime){
        let month_str = datetime.substr(4,2);
        let day_str = datetime.substr(6,2);
        let hour_str = datetime.substr(8,2);
        let minute_str = datetime.substr(10,2);
        
        let format_str = 'MM/DD hh:mm';
        format_str = format_str.replace(/MM/g, month_str);
        format_str = format_str.replace(/DD/g, day_str);
        format_str = format_str.replace(/hh/g, hour_str);
        format_str = format_str.replace(/mm/g, minute_str);
        return format_str;
    }
    else{
        return null
    }
}

//確認可能かどうかをアイコンで表す関数
const confirmAvailable = (status) => {
    let type = "";
    if(status){
        type = "eye"
        return type
    }
    else{
        type = "eye-slash"
        return type
    }
}


//地震回答結果を詳細に表示するモーダル
const UserStatusDetailModal2 = memo(() => {
    const EarthquakeInfoModal = {
        datetime:"20220828120705",
        id: "20220828120413",
        seismicIntensity: "5強",
        spot: "愛知県",
    }

    const UserStatusModal = {
        FamilyHealthDamage: 0,
        HomeDamage: 0,
        MyHealthDamage: 0,
        NumberOfResponse: 0,
        Safe: 0,
        Unanswered: 14
    }

    const TotalUserquantity = "10"

    const UserStatusDetailModalButton ="Damage"

    const UserReportsModalElection = [
        {
            "comment": "コメント",
            "confirmAvailable": "",
            "createdAt": "20220830144303",
            "status": "MyHealthDamage",
            "userId": "1"
        },
        {
            "comment": "",
            "confirmAvailable": "",
            "createdAt": "20220830144503",
            "status": "FamilyHealthDamage",
            "userId": "2"
        },
        {
            "comment": "コメント",
            "confirmAvailable": "",
            "createdAt": "20220830144603",
            "status": "HomeDamage",
            "userId": "3"
        }
    ]

     
    const EarthquakeInfoTitle = JudgeComment(EarthquakeFormatConv(EarthquakeInfoModal))
    const Response = UserStatusModal.NumberOfResponse
    let datailResultTable_th;//datailResultTableのth部分
    let datailResultTable_td;//datailResultTableのtd部分
    
    //被害なし回答時のth
    if(UserStatusDetailModalButton === "Safe"){
        datailResultTable_th = [
            <th>回答日時</th>,
            <th>名前</th>,
            <th>被害</th>,
            <th>会社</th>,
            <th></th>
        ]
    }
    else{
        datailResultTable_th = [
            <th>回答日時</th>,
            <th>名前</th>,
            <th>被害</th>,
            <th></th>
        ]
    }

    return (
        <div style={{width:"100%", height: "100vh", backgroundColor:"rgba(0,0,0,0.5)"}}>
            <div style = {{overflowY: "hidden", WebkitTransition: "0.3s", OTransition:"0.3s", transition:"0.3s", background:"#fff", height: "100vh", width: "100%", zIndex:"100"}}>
                <div style = {{paddingTop:"16px"}} >
                    <Top
                        overrides={
                            {
                                "text":{
                                    children : EarthquakeInfoTitle,
                                    id:"damageSituation"
                                }
                            }
                        }
                        status = {"Joukyoukakunin"}
                        width = {"90vw"}
                        style = {{margin: "auto",marginBottom: "24px"}}
                    />
                </div>

                {/*回答者数と社員一覧ボタンの部分*/}
                
                <div style={{position: "relative"}}>
                    <Middle
                        /*変数を入れる*/
                        overrides = {
                            {
                                "Frame 469":{
                                    id: "AnswerofPeople",
                                    height:"50px",
                                    paddingTop: "8px",
                                    paddingBottom:"8px",
                                    paddingLeft: "8px",
                                    paddingRight:"8px"
                                },
                                "btnSyain":{
                                    id: "employeeBtn",//左にする。spotlightされる要素の大きさを大きくしたい//spotlight側で定義をするればいい。
                                },
                                "0036332745":{
                                    children: Response
                                },
                                "0036332772":{
                                    children: TotalUserquantity
                                }
                            }
                        }
                        width = {"90%"}
                        style = {{margin:"auto",marginBottom:"24px",position:"absolute"}}
                    />
                    <div style={{position:"absolute", width:"40px", height:"40px"}}></div>
                </div>

                <div style = {{paddingTop:"18px",paddingBottom:"80px", width:"100%", minHeight:"100%", backgroundColor:"rgba(245,245,245,1)", borderRadius: "50px 50px 0px 0px"}}>
                    <SelectionButtonsClassAttach
                        // UserReportsModal={UserReportsModal}
                    />
                    <div>
                        {/*ヘッダー部分*/}
                        {(() => {
                            return(
                                <div id="damageList">
                                    <HeadJoukyoukakunin02
                                        width= "90vw"
                                        margin= "auto"
                                    />
                                </div>
                            )           
                        })()}
                        
                        {/*コメントがあるかないか？→OK*/}
                        {/*被害アイコンをクリックしたときに出す説明部分の分岐が必要→*/}
                        {/*どのアイコンにするのかの分岐も必要*/}
                        {/*コメントボタンをクリックした後画面がフリーズする*/}
                        {/*データ部分の表*/}
                        {
                            UserReportsModalElection.map((value, index)=>{
                                //コメント回答の有無
                                let haveComment 
                                if(value["comment"] === ""){//コメントなし
                                    haveComment = {              
                                        type: "comment_g",
                                        // onClick: ()=> {}//not use
                                    }
                                }
                                else{// CommentDetailModalManager(value["userId"], value["status"], value["comment"])
                                    haveComment = {//コメントあり
                                        type: "comment_b",
                                        // onClick: ()=> CommentDetailModalManager(value["userId"], value["status"], value["comment"])//not use
                                    }
                                }
                                //奇数番目偶数番目の背景色
                                let BGColor = null//componentのプロパティ
                                let BGColorDiv = null//Componentの背景色をwidth100%まで伸ばすために必要
                                let damageIcon = null
                                let companyCheckIcon = null
                                // let commentIcon = null
                                if (index % 2 === 0){
                                    BGColor = "gray"
                                    BGColorDiv = "rgba(245,245,245,1)"
                                }
                                else{
                                    BGColor = "white"
                                    BGColorDiv = "rgba(255,255,255,1)"
                                }

                                if (index === 0){
                                    damageIcon = "damageIcon"
                                    companyCheckIcon = "companyCheckIcon"
                                    haveComment["id"] = "commentIcon"
                                }

                                if(UserStatusDetailModalButton === "Safe"){//被害なし
                                    return (
                                        <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin02"+index}>
                                            <ListJoukyoukakunin02
                                                overrides={
                                                    {
                                                        name:{//名前部分
                                                            children: UserIdChangeName(value["userId"])
                                                        },
                                                        day:{//回答日時
                                                            children: AnswerDateTimeModalFunc(value["createdAt"])
                                                        },
                                                        MyIcon34592321: haveComment,//コメント部分
                                                        MyIcon34592318:{//被害のアイコン部分
                                                            type : UserStatusChangeIcon(value["status"])
                                                        },
                                                        MyIcon34592374:{//会社確認アイコン部分
                                                            type : confirmAvailable(value["confirmAvailable"]),
                                                            // onClick : ()=> setIconCheckExplainShow(true)
                                                        }
                                                    }
                                                }
                                                bg = {BGColor}
                                                width = "90vw" 
                                                margin = "auto"
                                            />
                                        </div>
                                    )
                                }
                                else if(UserStatusDetailModalButton === "Damage"){//被害あり
                                    return (
                                        <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin01"+index}>
                                            <ListJoukyoukakunin01
                                                overrides={
                                                    {
                                                        name:{//名前部分
                                                            children: UserIdChangeName(value["userId"])
                                                        },
                                                        day:{//回答日時
                                                            children: AnswerDateTimeModalFunc(value["createdAt"])
                                                        },
                                                        MyIcon37242838:{//被害アイコン部分
                                                            id: damageIcon,
                                                            type: UserStatusChangeIcon(value["status"])
                                                            // onClick: () => setIconDamageExplainShow(true)//not use
                                                        },
                                                        //会社アイコン部分
                                                        MyIcon37252848:{
                                                            id: companyCheckIcon
                                                        },
                                                        //コメントアイコン部分
                                                        MyIcon37242841: haveComment,
                                                    }
                                                }
                                                bg = {BGColor}
                                                width = "90vw" 
                                                margin = "auto"
                                            />
                                        </div>
                                    )
                                }
                                else if(UserStatusDetailModalButton === "UnAnswered"){//未回答
                                    return (
                                        <div style={{backgroundColor: BGColorDiv, width:"100vw"}} key={"ListJoukyoukakunin03"+index}>
                                            <ListJoukyoukakunin03
                                                overrides={
                                                    {
                                                        MyIcon34602664: {
                                                            marginRight: "65px"//80px被害ありでの回答日時部分のwidth、15pxアイコンのwidth(80px-15px)
                                                        },
                                                        name:{
                                                            children: UserIdChangeName(value["userId"])
                                                        }
                                                    }
                                                }
                                                bg = {BGColor}
                                                width="90vw"
                                                margin= "auto"
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    )
});


