/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import BtnSyain from "./BtnSyain";
export default function Middle(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="102px"
      direction="row"
      width="288px"
      height="unset"
      justifyContent="space-between"
      alignItems="flex-end"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Middle")}
    >
      <Flex
        gap="8px"
        direction="column"
        width="107px"
        height="40px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 469")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(160,160,160,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="100px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="回答者数"
          {...getOverrideProps(overrides, "\u56DE\u7B54\u8005\u6570")}
        ></Text>
        <Flex
          gap="4px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 468")}
        >
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-end"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 466")}
          >
            <Text
              fontFamily=""
              fontSize="20px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="00"
              {...getOverrideProps(overrides, "0036332745")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="14px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="人"
              {...getOverrideProps(overrides, "\u4EBA36332746")}
            ></Text>
          </Flex>
          <MyIcon
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="slash"
            {...getOverrideProps(overrides, "MyIcon")}
          ></MyIcon>
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-end"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 467")}
          >
            <Text
              fontFamily=""
              fontSize="20px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="00"
              {...getOverrideProps(overrides, "0036332772")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="14px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="人"
              {...getOverrideProps(overrides, "\u4EBA36332773")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <BtnSyain
        display="flex"
        gap="10px"
        direction="row"
        width="71px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(160,160,160,1)"
        borderRadius="13px"
        padding="7px 15px 7px 15px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "btnSyain")}
      ></BtnSyain>
    </Flex>
  );
}
