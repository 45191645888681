/*
    ユーザ削除用のモーダル
*/
import React, { memo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteUserSetting } from '../../../graphql/mutations';
import {
    TextSakujokakunin,
    Btn
}
from '../../../ui-components';
import { SubscriptionDeleteFunc } from "../../../hooks/misc/common";
import { useMessage } from "../../../hooks/useMessage";
import { TopHeader } from '../../molecules/top/TopHeader';
import { MultiButton } from '../../molecules/buttons/MultiButton';


export const DeleteUserSetting = memo((props) =>{
  const { showMessage } = useMessage();
  const { DeleteShow, setDeleteShowFlase, DeleteSetting, fetchAndSetSettingUsers, setLoading } = props;
  /*
        const DeleteSetting = {
            adminFlag     : false                  ,
            employeeNumber: 123                    ,
            id            : "23"                   ,
            mail          : "hirotake615@gmail.com",
            mailStatus    : false                  ,
            name          : "23"                   ,
            phone         : "+819038589402"        ,
            smsStatus     : false
        }
  */


    /*
        概要：ユーザの削除処理
    */
    const UserDelete = async(args) => {
        const {id} = args;
        let DofinallyFlag = true;
        let DoFailSubscrition = false;
        // load画面を見せる
        setLoading(true)
        try{
            // ユーザ情報をデータベースから削除する。
            await API.graphql(graphqlOperation(deleteUserSetting, {"id": id}))
            .catch((e)=>{
                console.error(e);
                throw new Error("API Invoke Error");
            })

            // サブスクリプションを削除
            await SubscriptionDeleteFunc("email", DeleteSetting.mail)
            .then((response)=>{
                if(response.data.ResultStatus === false)
                {
                    let message = response.data.ResultLog.message
                    if(message.includes("An ARN must have at least 6 elements, not 1")){//Email
                        // throw new Error("Emailすでに削除済みです");//何もしない
                    }
                    else if(message.includes("no value for required parameter")){//sms
                        // throw new Error("SMSすでに削除済みです");//何もしない
                    }
                    else{
                        throw new Error(message);
                    }
                }
            })
            .catch((e)=>{
                DoFailSubscrition = true;
                console.error(e);
                throw new Error("サブスクリプションの削除に失敗しました。");
            });

            
            await SubscriptionDeleteFunc("sms", DeleteSetting.phone)
            .then((response)=>{
                if(response.data.ResultStatus === false)
                {
                    let message = response.data.ResultLog.message
                    if(message.includes("An ARN must have at least 6 elements, not 1")){//Email
                        // throw new Error("Emailすでに削除済みです");//何もしない
                    }
                    else if(message.includes("no value for required parameter")){//sms
                        // throw new Error("SMSすでに削除済みです");//何もしない
                    }
                    else{
                        throw new Error(message);
                    }
                }
            }).catch((e)=>{
                DoFailSubscrition = true;
                console.error(e);
                throw new Error("サブスクリプションの削除に失敗しました。");
            })

            // ユーザリストをリストで取得する 
            await fetchAndSetSettingUsers()
            .catch((e)=>{
                console.error(e);
                throw new Error("API Invoke Error");
            })
        }
        catch(e){
            DofinallyFlag = false;
            showMessage({message:e.message});
        }
        finally{
            // load画面の終了をする
            setLoading(false);

            if(DofinallyFlag){
                // モーダルを閉じる
                setDeleteShowFlase();
            }
        }   
    }


    return (
        <div style = {{width:"100%", height:"100vh", padding: "0", overflowY: "auto", backgroundColor:"#F5F5F5"}}>
            {/*ヘッダー部分*/}
            <TopHeader
                status       = {"UserDelete"}
                clickFunc    = {()=>{setDeleteShowFlase()}}
                text         = {"ユーザ削除"}
                margin       = {"auto"}
                width        = {"90%"}
                marginBottom = {"55px"}
                paddingTop   = {"16px"}
            />

            {/* 本当に削除してもよろしいですか？*/}
            <TextSakujokakunin
                width = {"90%"}
                margin = {"auto"}
            />
            
            {/*削除ボタン*/}
            <div style = {{position:"absolute", bottom:"20px", width:"100vw"}}>
                <MultiButton
                    CallBackFunc = {UserDelete}
                    omission     = {false}
                    text         = {"削除"}
                    pattern      = {"default"}
                    id           = {DeleteSetting.id}
                />
                <Btn
                    status = "Cancel"
                    onClick = {()=>{setDeleteShowFlase()}}
                    style = {{margin:"auto"}}
                    width = {"90%"}
                    overrides = {
                        {
                            "Btn": {
                                justifyContent:"center"
                            }
                        }
                    }
                />
            </div>
        </div>
    );
});