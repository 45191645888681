/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function BtnSmall(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)", children: "\u767B\u9332" },
        BtnSmall: {
          padding: "8px 57px 8px 57px",
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      variantValues: { status: "Touroku" },
    },
    {
      overrides: { text: {}, BtnSmall: {} },
      variantValues: { status: "Syusei" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(255,255,255,1)"
      borderRadius="4px"
      padding="7px 56px 7px 56px"
      backgroundColor="rgba(43,133,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "BtnSmall")}
    >
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(255,255,255,1)"
        lineHeight="14px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="1.05px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="修正"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
