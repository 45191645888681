import { memo,useState } from "react";
import { CommonHeader } from "../molecules/header/CommonHeader"; 
import { Footer } from '../../ui-components';

export const HeaderFooterLayout = memo((props) => {
  const { children } = props;
  return (
    <div style={{backgroundColor:"#F5F5F5", display:"flex", flexDirection:"column", minHeight:"100vh", height:"fit-content"}}>
      <CommonHeader/>
      {children}
      <footer style = {{marginTop: "auto", width:"100%"}}>
        <Footer
            width="100%"  
        />
      </footer>
    </div>
  );
});

