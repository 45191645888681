/*
  概要：安否結果ページで使用するuseContext
*/
import React, { createContext, useContext, useState } from "react";

const ResultContext = createContext({});


export const ResultProvider = (props) => {
  const { children } = props;
  //modal
  const [IconCheckExplainShow ,  setIconCheckExplainShow]  = useState(false);     //確認アイコンをクリックしたときに説明モーダルを表示するか否かを管理します。
  const [IconDamageExplainShow, setIconDamageExplainShow] = useState(false);      //被害アイコンをクリックしたときに説明モーダルを表示するか否かを管理します。
  const [showCommentDetailModal, setShowCommentDetailModal] = useState(false);    //モーダルの表示、非表示を管理する部分
  const [CommentModalReport, setCommentModalReport] = useState(null);

  //hook
  const [listUserSetting, setlistUserSetting] = useState([]);                     //全ユーザ情報
  const [listEarthquake, setlistEarthquake] = useState([]);                       //地震情報情報
  const [TimeFilteredReports, setTimeFilteredReports] = useState([]);             //時間でフィルターされた回答結果が入っている

  //resultList
  const [toggle, settoggle] = useState({});                                       //表示・非表示を管理するステート//クリックされたらキーとバリューを追加する。クリックされていないときはundefinedの状態とする。
  const [update, setupdate] = useState(false);
  const [ResultDetailShow, setResultDetailShow] = useState();                     //ResultDetailのページの表示を管理します。

  //resultdetail
  const [SortedUserReportModal, setSortedUserReportModal] = useState("");         //モーダル表示に必要なデータ //UserStatusDetailModal
  const [UserStatus, setUserStatus] = useState("");                               //ユーザの回答情報に最初の回答者数X人とかに使ってる //モーダル表示に必要なデータ//UserStatusDetailModal
  const [Earthquake, setEarthquake] = useState("");                               //地震の情報が入っている //モーダル表示に必要なデータ//UserStatusDetailModal
  const [Report, setReport] = useState(null);
  const [SelectReport, setSelectReport] = useState([]);
  const [SelectReportButton, setSelectReportButton] = useState("被害あり");
  const [SummaryReports, setSummaryReports] = useState([]);

  //employeelistPage
  const [ShowEmployeeList, setShowEmployeeList] = useState(false);//モーダル表示に必要なデータ //EmployeeListModal

  //dropDownList
  const [DropDownOpen, setDropDownOpen] = useState(false); 
  const [EmployeeSelectBtn, setEmployeeSelectBtn] = useState("回答順");
  const [EmployeeListSelectReport, setEmployeeListSelectReport] = useState([]);


  const value = {
    IconCheckExplainShow        , setIconCheckExplainShow        ,
    IconDamageExplainShow       , setIconDamageExplainShow       ,
    
    showCommentDetailModal      , setShowCommentDetailModal      ,
    CommentModalReport          , setCommentModalReport          ,
    
    listUserSetting             , setlistUserSetting             ,
    listEarthquake              , setlistEarthquake              ,
    TimeFilteredReports         , setTimeFilteredReports         ,
    Report                      , setReport,
    toggle                      , settoggle                      ,
    update                      , setupdate                      ,
    ResultDetailShow            , setResultDetailShow            ,
    
    SortedUserReportModal       , setSortedUserReportModal       ,
    UserStatus                  , setUserStatus                  ,
    Earthquake                  , setEarthquake                  ,
    SelectReport                , setSelectReport                ,
    SelectReportButton          , setSelectReportButton          ,
    SummaryReports              , setSummaryReports              ,
    EmployeeSelectBtn           , setEmployeeSelectBtn           ,
  
    ShowEmployeeList            , setShowEmployeeList            ,

    DropDownOpen                , setDropDownOpen                ,
    EmployeeListSelectReport    , setEmployeeListSelectReport
  }

  return (
    <ResultContext.Provider value={value}>
      {children}
    </ResultContext.Provider>
  );
};

export const useResult = () => useContext(ResultContext);