/*
    エラーを表示します。
*/
import Alert from 'react-bootstrap/Alert';
import React, { memo } from 'react';
import { useToast } from "../../../hooks/providers/useToastProvider";

export const AlertBar = memo(() => {
    const { showToast, setshowToast, messageToast, setmessageToast } = useToast();
    const ErrorSentence = ["エラーが発生致しました。"]
   
    return (
        <>
            <Alert
                variant={"danger"}
                dismissible={true}
                onClose={() => setshowToast(false)}
                style = {{zIndex:"9998","opacity": 1, position:"absolute",top:"10px", left:"calc(5vw)",width:"90%", fontSize:"14px",display:"block"}}
            >
                {ErrorSentence}
                <br/>
                {messageToast}
            </Alert>
        </>
    )
    
});