/* eslint-disable react-hooks/exhaustive-deps */
/*
    概要：Headerのサイドバーのコンポーネント(Headerのハンバーガボックスクリック時にスライドインしてくる部分)
*/
import React, {useState, useEffect, useRef} from 'react';
import { AnimatePresence, motion} from "framer-motion";
import { useHistory } from 'react-router-dom';
import {
    Menu
} from '../../../ui-components';
import {Auth} from 'aws-amplify';
import { useLoginUser } from "../../../hooks/providers/useLoginUserProvider";

export const HeaderSideBar = (props) =>{
    const {HeaderFlag, HeaderBtnOpen} = props;
    const history              = useHistory();
    const thresholdX           = 50;             //X軸閾値
    const ShowCommentDetailRef = useRef();       //コメントモーダルで指定の要素以外をクリックしたときに検知できるようにする
    const {loginUser}          = useLoginUser(); //useContext
    let adminvisible           = "none";


    useEffect(()=>{
        //指定要素以外をクリックしたことを検知
        const handleClickOutside = (e) => {
            //モーダルがオンになっているとき
            if(ShowCommentDetailRef){
                if (!ShowCommentDetailRef?.current?.contains(e.target)) {
                    HeaderBtnOpen()
                }
            }
        };

        if(HeaderFlag){//コメントモーダルが開いているとき処理する
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    },[HeaderFlag])

    
    /*
        概要：閾値から閉じる
    */
    const handleDragEnd = (x) => {
        if(x < thresholdX){
            HeaderBtnOpen() 
        }
    }

    /*
        概要：サインアウトする処理
    */
    const SignOutFunc = async() =>{
        try{
            // サインアウトする
            await Auth.signOut();
        }
        catch (e) {
            console.error("error signout", e);
        }
        finally{
            // Loginページへ遷移させる
            history.push("/")
        }
    }

    return(
        <AnimatePresence>
            {HeaderFlag && (
                <div style = {{position: "absolute", top:"0", left:"0", width:"100%",backgroundColor:"rgba(0,0,0,0.5)",zIndex: "100", display: "flex", alignItems: "center", justifyContent: "center", overflowY: "auto", height: "100vh"}}> 
                    <motion.div
                        initial    = {{ opacity: 1, x: -240 }} // 初期状態
                        animate    = {{ opacity: 1, x: 0 }}    // マウント時
                        exit       = {{ opacity: 1, x: -240 }} // アンマウント時
                        transition = {{ duration: 0.5 }}       // 0.5秒かけて立ち上がる
                        style      = {{position:"absolute", left:"0", top:"0", width: "240px", height: "100vh", backgroundColor:"#FFFFFF"}}
                        onClick    = {(e) => e.stopPropagation()}
                        ref        = {ShowCommentDetailRef}
                        drag       = "x"
                        dragConstraints = {{
                            top   : 0,
                            left  : 0,
                            right : 0,
                            bottom: 0,
                        }}
                        //ドラッグ要素が解放されたときの中心に戻る動き
                        dragElastic={{
                            top   : 0,
                            left  : 1,
                            right : 0.0000001,
                            bottom: 0 
                        }}
                        //ドラッグ終了時に起動する
                        onDragEnd={
                            (event, info) => handleDragEnd(info.point.x)
                        }
                        dragMomentum={true}//ドラッグ終了時に勢いをつける
                        key = "HeaderComponent"
                    >
                        {(() => {
                            /*管理者での分岐を行う*/
                            if(loginUser.adminFlag){
                                adminvisible = "block"
                            }
                        })()}

                        <Menu
                            overrides = {
                                {
                                    //Menuコンポーネント全体に対して
                                    Menu: {
                                        width: "240px"
                                    },
                                    // サインアウト
                                    listMenu41013624: {
                                        onClick :()=>{SignOutFunc()}
                                    },
                                    // 安否確認項目送信
                                    listMenu41013622: {
                                        onClick : ()=>{history.push("./adminsubmit")},
                                        display: adminvisible
                                    },
                                    // 管理者設定
                                    listMenu41013621: {
                                        onClick : ()=>{history.push("./admin")},
                                        display: adminvisible
                                    },
                                    // フィードバック
                                    listMenu41013625: {
                                        onClick : () =>{document.location.href = "https://form.asana.com/?k=Qz_XzAOOSLfdR69nIhahAw&d=1155748668115033"} 
                                    },
                                    // Voozyの使い方
                                    listMenu41013623: {
                                        onClick : () =>{history.push("./howtouse/home")}
                                    },
                                    // アカウント設定(仮で作成したので消している)
                                    listMenu41013620: {
                                        onClick : () => {},
                                        display: "none"
                                    },
                                    // ユーザ名
                                    \u90FD\u7BC9\u68A8\u592E:{
                                        children: loginUser.name
                                    }
                                }
                            }
                        />
                    </motion.div>
                </div>
            )}   
        </AnimatePresence>
    )
}
