/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import BtnChoice from "./BtnChoice";
import { Flex } from "@aws-amplify/ui-react";
export default function Choice(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="248px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="15px"
      padding="2px 2px 2px 2px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Choice")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="26px"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 474")}
      >
        <BtnChoice
          width="unset"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          bg="blue"
          situation="Damage"
          {...getOverrideProps(overrides, "btnChoice34533985")}
        ></BtnChoice>
        <BtnChoice
          width="unset"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          bg="white"
          situation="No damage"
          {...getOverrideProps(overrides, "btnChoice34533986")}
        ></BtnChoice>
        <BtnChoice
          width="unset"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          bg="white"
          situation="No answer"
          {...getOverrideProps(overrides, "btnChoice34533987")}
        ></BtnChoice>
      </Flex>
    </Flex>
  );
}
