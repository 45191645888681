/*
    概要：HowtoUseのページで使用する機能のないページ。安否登録ページ
*/
import React, {memo} from "react";
import {
    BtnBuji       , //Buttonbuji
    BtnHigai      , //Buttonhigai
    TxtAnpihoukoku, //安否報告をしてください
    Top01         , //震度を表示する部分
    RemarkText    ,   
    BtnText
} from '../../../ui-components';
import { NoneFuncHeaderFooterLayoutAnpi } from "../../templates/NoneFuncHeaderFooterLayoutAnpi";
import 'bootstrap/dist/css/bootstrap.min.css';//←react-bootstrap
import { Form } from 'react-bootstrap';//←react-bootstrap


export const SafetyConfirmPage = memo(()=>{
    return(
        <NoneFuncHeaderFooterLayoutAnpi>
            <div>
                <EarthquakeInfo/>
                <PageOne/>
                <InputArea/>
                <RegisterButton/>
            </div>
        </NoneFuncHeaderFooterLayoutAnpi>
    );
});


const PageOne = memo(()=>{
    return (
        <div style={{marginTop:"20px", marginBottom:"20px", width:"100%"}}>
            {/*安否報告をしてください*/}
            <TxtAnpihoukoku
                style = {{marginLeft:"5%"}}
            />
            <div style={{width:"95%", margin:"auto", height:"64px",display: "flex", justifyContent: "center"}} id ="button">
                <div style = {{display: "flex", padding:"8px 0px 8px 0px",width: "94.7%", margin: "auto"}}>
                    {/*無事*/}
                    <BtnBuji
                        status = {"select"}
                        width={"50%"}
                        style = {{marginRight : "1.25%"}}
                    />
                    {/*被害あり*/}
                    <BtnHigai
                        width={"50%"}
                        style = {{marginLeft: "1.25%"}}
                    />
                </div>
            </div>
        </div>
    )
});



const EarthquakeInfo = memo(()=>{
    const username = "山田太郎"
    const earthquakeInfo = "2022/08/28/ 12:07 愛知県 震度5強"
    return (
        <div>
            <Top01
                style = {{marginLeft:"5%",marginTop:"20px",marginBottom:"20px"}}
                overrides = {
                    {
                        "username":{
                            id: "username",
                            width:"128px",
                            height:"34px",
                        },
                        "\u82D7\u5B57 \u540D\u524D":{
                            children: username
                        },
                        "2022/04/15 11:50 \u611B\u77E5\u770C \u9707\u5EA65\u5F37":{
                            id: "earthquakeinfo",
                            width:"230px",
                            children: earthquakeInfo
                        }
                    }
                }
            />
        </div>
    )
});

const InputArea = memo(()=>{
    return(
        <div >
            <Form style={{width:"100%"}}>
                <RemarkText
                    style = {{marginBottom: "8px",marginLeft: "5%",marginTop: "8px"}}
                />
                <div style = {{width:"95%", margin:"auto", height:"70px",display: "flex", justifyContent: "center"}} id="RemarkText">
                    <Form.Group className='lg' controlId="exampleForm.ControlInput1" style={{width: "94.7%", margin: "auto", padding:"8px 8px 8px px"}}>
                        <Form.Control 
                            type="text"
                            as="textarea"
                            name="comment"
                            rows={2}
                            style={{width:"100%",fontFamily:"YuGothic",fontSize:"12px",fontWeight:"700"}}
                            maxLength="250"//入力文字数の制限
                        />
                    </Form.Group>
                </div>
            </Form>
        </div>
    )
})

const RegisterButton = () =>{
    return (
        <div style = {{width:"100%",position: "absolute", top:"450px",height:"64px"}} >
            <div style = {{width:"95%", margin:"auto", height:"64px",display: "flex", justifyContent: "center"}} id = "RegisterButton">
                <BtnText
                    style = {{margin: "auto", justifyContent:"center"}}
                    width = {"94.7%"}
                    overrides = {
                        {
                            "text":{
                                children: "登録"
                            }
                        }
                    }
                />
            </div>
        </div>
    )
}