/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function HeadJoukyoukakunin02(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 16px 0px 16px"
      {...rest}
      {...getOverrideProps(overrides, "HeadJoukyoukakunin02")}
    >
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 216")}
      >
        <Text
          fontFamily="``"
          fontSize="10px"
          fontWeight="``"
          color="rgba(0,0,0,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="80px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="回答日時"
          {...getOverrideProps(overrides, "\u56DE\u7B54\u65E5\u6642")}
        ></Text>
        <Text
          fontFamily=""
          fontSize="10px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="90px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="名前"
          {...getOverrideProps(overrides, "\u540D\u524D")}
        ></Text>
        <Text
          fontFamily=""
          fontSize="10px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="26px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="被害"
          {...getOverrideProps(overrides, "\u88AB\u5BB3")}
        ></Text>
        <Text
          fontFamily=""
          fontSize="10px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="53px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="会社"
          {...getOverrideProps(overrides, "\u4F1A\u793E")}
        ></Text>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(224,224,224,1)"
        {...getOverrideProps(overrides, "Rectangle 28")}
      ></View>
    </Flex>
  );
}
