/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function BtnChoice(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Rectangle 27": { backgroundColor: "rgba(43,133,255,1)" },
        text: { color: "rgba(255,255,255,1)" },
        BtnChoice: {},
      },
      variantValues: { bg: "blue", situation: "Damage" },
    },
    {
      overrides: { "Rectangle 27": {}, text: {}, BtnChoice: {} },
      variantValues: { bg: "white", situation: "Damage" },
    },
    {
      overrides: {
        "Rectangle 27": { backgroundColor: "rgba(43,133,255,1)" },
        text: {
          color: "rgba(255,255,255,1)",
          children: "\u88AB\u5BB3\u306A\u3057",
        },
        BtnChoice: {},
      },
      variantValues: { bg: "blue", situation: "No damage" },
    },
    {
      overrides: {
        "Rectangle 27": {},
        text: { children: "\u88AB\u5BB3\u306A\u3057" },
        BtnChoice: {},
      },
      variantValues: { bg: "white", situation: "No damage" },
    },
    {
      overrides: {
        "Rectangle 27": {
          width: "68px",
          backgroundColor: "rgba(43,133,255,1)",
        },
        text: {
          color: "rgba(255,255,255,1)",
          width: "51px",
          left: "11.76%",
          right: "13.24%",
          children: "\u672A\u56DE\u7B54",
        },
        BtnChoice: { width: "68px" },
      },
      variantValues: { bg: "blue", situation: "No answer" },
    },
    {
      overrides: {
        "Rectangle 27": { width: "68px" },
        text: { width: "51px", children: "\u672A\u56DE\u7B54" },
        BtnChoice: { width: "68px" },
      },
      variantValues: { bg: "white", situation: "No answer" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="80px"
      height="26px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "BtnChoice")}
    >
      <View
        width="80px"
        height="26px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        borderRadius="13px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 27")}
      ></View>
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(43,133,255,1)"
        lineHeight="12px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="60px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="26.92%"
        bottom="26.92%"
        left="12.5%"
        right="12.5%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="被害あり"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </View>
  );
}
