/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function ErrorText(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="100px"
      height="18px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "ErrorText")}
    >
      <Text
        fontFamily=""
        fontSize="10px"
        fontWeight=""
        color="rgba(255,76,108,1)"
        lineHeight="18px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="入力に不備があります"
        {...getOverrideProps(
          overrides,
          "\u5165\u529B\u306B\u4E0D\u5099\u304C\u3042\u308A\u307E\u3059"
        )}
      ></Text>
    </View>
  );
}
