/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function Input02(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text36392801: {},
        hissu: {},
        "Frame 435": {},
        text36392805: {},
        "Rectangle 15": {},
        "Frame 1959": {},
        "Frame 1958": {},
        Input02: {},
      },
      variantValues: { status: "name", kome: "on" },
    },
    {
      overrides: {
        text36392801: {
          children: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
        },
        hissu: {},
        "Frame 435": {},
        text36392805: { children: "tsuzuki@trafficsim.co.jp" },
        "Rectangle 15": {},
        "Frame 1959": {},
        "Frame 1958": {},
        Input02: {},
      },
      variantValues: { status: "mailaddress", kome: "on" },
    },
    {
      overrides: {
        text36392801: { children: "\u96FB\u8A71\u756A\u53F7" },
        hissu: {},
        "Frame 435": {},
        text36392805: { children: "080-2615-8683" },
        "Rectangle 15": {},
        "Frame 1959": {},
        "Frame 1958": {},
        Input02: {},
      },
      variantValues: { status: "PhoneNumber", kome: "on" },
    },
    {
      overrides: {
        text36392801: { children: "\u793E\u54E1\u756A\u53F7" },
        hissu: {},
        "Frame 435": {},
        text36392805: { children: "5413" },
        "Rectangle 15": {},
        "Frame 1959": {},
        "Frame 1958": {},
        Input02: {},
      },
      variantValues: { status: "EmployeeNumber", kome: "on" },
    },
    {
      overrides: {
        text36392801: { children: "\u30BF\u30A4\u30C8\u30EB" },
        hissu: {},
        "Frame 435": {},
        text36392805: {
          children: "2022/0523 10:00 \u611B\u77E5\u770C \u6D25\u6CE2",
        },
        "Rectangle 15": {},
        "Frame 1959": {},
        "Frame 1958": {},
        Input02: {},
      },
      variantValues: { status: "EmployeeNumber", kome: "on" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Input02")}
    >
      <Flex
        gap="6px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 435")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(160,160,160,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="名前"
          {...getOverrideProps(overrides, "text36392801")}
        ></Text>
        <Text
          fontFamily=""
          fontSize="10px"
          fontWeight=""
          color="rgba(255,76,108,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="※必須"
          {...getOverrideProps(overrides, "hissu")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="12px 0px 0px 0px"
        backgroundColor="rgba(245,245,245,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 1958")}
      >
        <Flex
          gap="4px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 1959")}
        >
          <Text
            fontFamily=""
            fontSize="14px"
            fontWeight=""
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="288px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="都築 梨央"
            {...getOverrideProps(overrides, "text36392805")}
          ></Text>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(224,224,224,1)"
            {...getOverrideProps(overrides, "Rectangle 15")}
          ></View>
        </Flex>
      </Flex>
    </Flex>
  );
}
