/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function OrderSyain(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        name34522455: {},
        name34522468: {},
        "Frame 488": {},
        Status: {},
        "Frame 489": {},
        order: {},
        line: {},
        OrderSyain: {},
      },
      variantValues: { status: "Default" },
    },
    {
      overrides: {
        name34522455: {},
        name34522468: {},
        "Frame 488": {},
        Status: { color: "rgba(43,133,255,1)" },
        "Frame 489": {},
        order: {},
        line: {},
        OrderSyain: {},
      },
      variantValues: { status: "select" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "OrderSyain")}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="28px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 16px 8px 16px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "order")}
      >
        <Flex
          gap="100px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 489")}
        >
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 488")}
          >
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="40px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="社員数"
              {...getOverrideProps(overrides, "name34522455")}
            ></Text>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="40px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="45人"
              {...getOverrideProps(overrides, "name34522468")}
            ></Text>
          </Flex>
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(160,160,160,1)"
            lineHeight="12px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="104px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="回答順"
            {...getOverrideProps(overrides, "Status")}
          ></Text>
        </Flex>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(224,224,224,1)"
        {...getOverrideProps(overrides, "line")}
      ></View>
    </Flex>
  );
}
