/*
  概要：共通して使用しそうなuseContext
*/

import React, { createContext, useContext, useState } from "react";

const OtherDataContext = createContext({});

// ログインユーザー情報を保持するcontext
export const OtherDataProvider = (props) => {
  const { children } = props;
  const [listQuestion, setlistQuestion] = useState(null);
  const value = {
    listQuestion, setlistQuestion, //Questionテーブルから取得したデータ
  }

  return (
    <OtherDataContext.Provider value={value}>
      {children}
    </OtherDataContext.Provider>
  );
};

export const useOtherData = () => useContext(OtherDataContext);
