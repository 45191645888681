/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function ListJoukyoukakunin03(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        MyIcon34602664: {},
        name: {},
        MyIcon34602656: {},
        MyIcon34602657: {},
        "Frame 216": {},
        "Rectangle 30": {},
        MyIcon34602660: {},
        "Frame 1950": {},
        ListJoukyoukakunin03: {},
      },
      variantValues: { bg: "gray" },
    },
    {
      overrides: {
        MyIcon34602664: {},
        name: {},
        MyIcon34602656: {},
        MyIcon34602657: {},
        "Frame 216": {},
        "Rectangle 30": {},
        MyIcon34602660: {},
        "Frame 1950": {},
        ListJoukyoukakunin03: { backgroundColor: "rgba(255,255,255,1)" },
      },
      variantValues: { bg: "white" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="10px 16px 10px 16px"
      backgroundColor="rgba(245,245,245,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ListJoukyoukakunin03")}
    >
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 216")}
      >
        <MyIcon
          width="80px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="line"
          {...getOverrideProps(overrides, "MyIcon34602664")}
        ></MyIcon>
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="93px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="苗字苗字 名前"
          {...getOverrideProps(overrides, "name")}
        ></Text>
        <MyIcon
          width="27px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="line"
          {...getOverrideProps(overrides, "MyIcon34602656")}
        ></MyIcon>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="line"
          {...getOverrideProps(overrides, "MyIcon34602657")}
        ></MyIcon>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="21px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 1950")}
      >
        <View
          width="1px"
          height="18px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(224,224,224,1)"
          {...getOverrideProps(overrides, "Rectangle 30")}
        ></View>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="comment_g"
          {...getOverrideProps(overrides, "MyIcon34602660")}
        ></MyIcon>
      </Flex>
    </Flex>
  );
}
