/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ListPrefecture(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)" },
        MyIcon: {},
        "Frame 1954": { justifyContent: "space-between" },
        ListPrefecture: {},
      },
      variantValues: { status: "select" },
    },
    {
      overrides: { text: {}, MyIcon: {}, "Frame 1954": {}, ListPrefecture: {} },
      variantValues: { status: "Default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="288px"
      position="relative"
      padding="8px 8px 8px 8px"
      backgroundColor="rgba(245,245,245,1)"
      {...rest}
      {...getOverrideProps(overrides, "ListPrefecture")}
    >
      <Flex
        gap="100px"
        direction="row"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1954")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="12px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="160px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="関東地方すべて選択"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <MyIcon
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          type="check"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
      </Flex>
    </Flex>
  );
}
