/*県とJISコードが結び付いた設定ファイル*/
export const JISCODE = {
    "01": "北海道", "02": "青森県", "03": "岩手県", "04": "宮城県", "05": "秋田県", "06": "山形県", "07": "福島県", "08": "茨城県", "09": "栃木県", "10": "群馬県",
    "11": "埼玉県", "12": "千葉県", "13": "東京都", "14": "神奈川県", "15": "新潟県", "16": "富山県", "17": "石川県", "18": "福井県", "19": "山梨県", "20": "長野県",
    "21": "岐阜県", "22": "静岡県", "23": "愛知県", "24": "三重県", "25": "滋賀県", "26": "京都府", "27": "大阪府", "28": "兵庫県", "29": "奈良県", "30": "和歌山県",
    "31": "鳥取県", "32": "島根県", "33": "岡山県", "34": "広島県", "35": "山口県", "36": "徳島県", "37": "香川県", "38": "愛媛県", "39": "高知県", "40": "福岡県",
    "41": "佐賀県", "42": "長崎県", "43": "熊本県", "44": "大分県", "45": "宮崎県", "46": "鹿児島県", "47":	"沖縄県"
}
export const JISCODE_reverse = {
    "北海道":"1", "青森県":"2", "岩手県":"3", "宮城県":"4", "秋田県":"5", "山形県":"6", "福島県":"7", "茨城県":"8", "栃木県":"9", "群馬県":"10",
    "埼玉県":"11", "千葉県":"12", "東京都":"13", "神奈川県":"14", "新潟県":"15","富山県":"16","石川県":"17", "福井県":"18", "山梨県":"19", "長野県":"20",
    "岐阜県":"21", "静岡県":"22", "愛知県":"23", "三重県":"24", "滋賀県":"25", "京都府":"26", "大阪府":"27", "兵庫県":"28", "奈良県":"29", "和歌山県":"30",
    "鳥取県":"31", "島根県":"32", "岡山県":"33", "広島県":"34", "山口県":"35", "徳島県":"36", "香川県":"37", "愛媛県":"38", "高知県":"39", "福岡県":"40",
    "佐賀県":"41", "長崎県":"42", "熊本県":"43", "大分県":"44", "宮崎県":"45", "鹿児島県":"46", "沖縄県":"47"
}
//北海道・東北
export const hokkaidou_and_touhoku = [
    "北海道", "青森県", "岩手県", "秋田県", "宮城県", "山形県", "福島県"
]
//関東
export const kantou = [
    "東京都", "神奈川県", "千葉県", "埼玉県", "茨城県", "栃木県", "群馬県"
]
//中部地方(東海)
export const tyubu = [
    "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県"
]

//近畿地方//(関西)
export const kinki = [
    "滋賀県", "三重県", "奈良県", "和歌山県", "京都府", "大阪府", "兵庫県"
]
//中国・四国
export const tyugoku_and_sikoku = [
    "鳥取県", "岡山県", "鳥取県", "広島県", "山口県", "香川県", "徳島県", "愛媛県", "高知県"
]
//九州地方
export const kyusyuu = [
    "福岡県", "大分県", "宮崎県", "鹿児島県", "熊本県", "佐賀県", "長崎県", "沖縄県"
]

//都道府県地方別
export const LocalArea = {
    "北海道・東北": hokkaidou_and_touhoku,
    "関東": kantou,
    "東海": tyubu,
    "関西": kinki,
    "中国・四国": tyugoku_and_sikoku,
    "九州・沖縄": kyusyuu
}

//逆検索：都道府県地方別//
export const LocalArea_reverse = {
    "北海道":"北海道・東北",
    "青森県":"北海道・東北", 
    "岩手県":"北海道・東北", 
    "宮城県":"北海道・東北", 
    "秋田県":"北海道・東北", 
    "山形県":"北海道・東北", 
    "福島県":"北海道・東北", 
    "茨城県":"関東", 
    "栃木県":"関東", 
    "群馬県":"関東",
    "埼玉県":"関東", 
    "千葉県":"関東", 
    "東京都":"関東",
    "神奈川県":"関東", 
    "新潟県":"東海",
    "富山県":"東海",
    "石川県":"東海", 
    "福井県":"東海", 
    "山梨県":"東海", 
    "長野県":"東海",
    "岐阜県":"東海", 
    "静岡県":"東海", 
    "愛知県":"東海", 
    "三重県":"関西",
    "滋賀県":"関西", 
    "京都府":"関西", 
    "大阪府":"関西", 
    "兵庫県":"関西", 
    "奈良県":"関西", 
    "和歌山県":"関西",
    "鳥取県":"中国・四国",
    "島根県":"中国・四国", 
    "岡山県":"中国・四国", 
    "広島県":"中国・四国", 
    "山口県":"中国・四国", 
    "徳島県":"中国・四国", 
    "香川県":"中国・四国", 
    "愛媛県":"中国・四国", 
    "高知県":"中国・四国", 
    "福岡県":"九州・沖縄",
    "佐賀県":"九州・沖縄", 
    "長崎県":"九州・沖縄", 
    "熊本県":"九州・沖縄", 
    "大分県":"九州・沖縄", 
    "宮崎県":"九州・沖縄", 
    "鹿児島県":"九州・沖縄", 
    "沖縄県":"九州・沖縄"
}