/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import TextTuchikakunin from "./TextTuchikakunin";
import BtnSmall from "./BtnSmall";
import { Flex } from "@aws-amplify/ui-react";
export default function LayoutTuchikakunin(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(43,133,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "LayoutTuchikakunin")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1969")}
      >
        <TextTuchikakunin
          width="288px"
          height="18px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "textTuchikakunin")}
        ></TextTuchikakunin>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1968")}
        >
          <BtnSmall
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(255,255,255,1)"
            borderRadius="4px"
            padding="7px 56px 7px 56px"
            backgroundColor="rgba(43,133,255,1)"
            status="Syusei"
            {...getOverrideProps(overrides, "btnSmall34992829")}
          ></BtnSmall>
          <BtnSmall
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="4px"
            status="Touroku"
            {...getOverrideProps(overrides, "btnSmall34992828")}
          ></BtnSmall>
        </Flex>
      </Flex>
    </Flex>
  );
}
