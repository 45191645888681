/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function BtnAdd(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { MyIcon: {}, text: {}, BtnAdd: {} },
      variantValues: { property1: "User" },
    },
    {
      overrides: {
        MyIcon: {},
        text: { children: "\u6CE8\u610F\u4E8B\u9805\u3092\u8FFD\u52A0" },
        BtnAdd: {},
      },
      variantValues: { property1: "Attention" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="6px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "BtnAdd")}
    >
      <MyIcon
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="circle-plus"
        {...getOverrideProps(overrides, "MyIcon")}
      ></MyIcon>
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(43,133,255,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ユーザー登録"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
