import React from "react";
import {RingLoading} from "./RingLoading";

export const RingLoadingModalWhite = () => {
  return (
    <div style = {{position:"absolute", overflow: "hidden",top:"0px", left:"0px", width:"100%", height:"100%", backgroundColor: "white", zIndex:"5000"}}>
        <RingLoading/>
    </div>
  );
}
