/*
    スポットライト機能
*/
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useHistory } from "react-router-dom";
import React,{memo,useEffect,useState} from "react";
import styled from "@emotion/styled";
import { HowtoExplain, HowtoGrey } from '../../../ui-components';
import { SafetyConfirmPage } from "../../organisms/HowtoUsePages/SafetyConfrimPage";
import { SafetyResultPage } from "../../organisms/HowtoUsePages/SafetyResultPage";
import { MemberDetailPage } from "../../organisms/HowtoUsePages/MemberDetailPage";
import { ResultDetailPage } from "../../organisms/HowtoUsePages/ResultDetailPage";
import { MemberSettingPage } from "../../organisms/HowtoUsePages/MemberSettingPage";
import { EarthquakeSettingPage} from "../../organisms/HowtoUsePages/EarthquakeSettingPage";
import { AdminSubmit } from "../../organisms/HowtoUsePages/AdminSubmit";
import { selectInput } from "aws-amplify";


/*idリスト*/
const safetyconfrimIdList   = ["earthquakeinfo", "username", "button", "RemarkText","RegisterButton"];
const safetyresultIdList    = ["latest", "past"];
const memberdetailList      = ["NumberofPeople"];
const resultdetailList      = ["damageSituation", "AnswerofPeople", "employeeBtn", "selection", "damageList", "damageIcon", "companyCheckIcon", "commentIcon"];
const EarthquakeSettingList = ["earthquakeIntensity", "ObservationSite", "UserInsert", "UserList", "UserInfo", "UserDelete", "UserEdit"];
const membersettingList     = ["UserInsertData", "UserInsertadmin"];
const AdminSubmitList       = ["title", "noteAddition", "note", "noteDelete"];

/*explainリスト*/
const safetyconfrimExplain     = ["User05", "User01", "User02", "User03", "User04"];
const safetyresultExplain      = ["User05", "User06"];
const memberdetailExplain      = ["User07"];
const resultdetailExplain      = ["User08", "User09", "User10", "User11", "User12", "User13", "User14", "User15"];
const EarthquakeSettingExplain = ["Kanri01", "Kanri02", "Kanri03", "Kanri04", "Kanri05", "Kanri06", "Kanri07"];
const membersettingExplain     = ["Kanri08","Kanri09"];
const AdminSubmitExplain       = ["Kanri10", "Kanri11", "Kanri12", "Kanri13"];

/*pathによって返すリスト*/
const ExplainsDefine = [
    {
        /*"/howtouse/home/safetyconfirm"*/
        id: safetyconfrimIdList,
        explain: safetyconfrimExplain,
        component: <SafetyConfirmPage/>
    },
    {
        /*"/howtouse/home/safetyresult"*/
        id: safetyresultIdList,
        explain: safetyresultExplain,
        component: <SafetyResultPage/>
    },
    {
        /*"/howtouse/home/memberdetail"*/
        id: memberdetailList,
        explain:memberdetailExplain,
        component: <MemberDetailPage/>
    },
    {
        /*"/howtouse/home/resultdetail"*/
        id: resultdetailList,
        explain: resultdetailExplain,
        component: <ResultDetailPage/>
    },
    {
        /*"/howtouse/admin/earthquakesetting"*/
        id: EarthquakeSettingList,
        explain: EarthquakeSettingExplain,
        component: <EarthquakeSettingPage/>
    },
    {
        /*"/howtouse/admin/membersetting"*/
        id: membersettingList,
        explain: membersettingExplain,
        component: <MemberSettingPage/>
    },
    {
        /*"/howtouse/admin/adminsubmit"*/
        id: AdminSubmitList,
        explain: AdminSubmitExplain,
        component: <AdminSubmit/>
    }
]

/*pathnameの値によって設定値を変更する*/
const switchingFunc = (path) => {
    switch(path){
        case "/howtouse/home/safetyconfirm":
            return ExplainsDefine[0]
        case "/howtouse/home/safetyresult":
            return ExplainsDefine[1]
        case "/howtouse/home/memberdetail":
            return ExplainsDefine[2]
        case "/howtouse/home/resultdetail":
            return ExplainsDefine[3]
        case "/howtouse/admin/earthquakesetting":
            return ExplainsDefine[4]  
        case "/howtouse/admin/membersetting":
            return ExplainsDefine[5]
        case "/howtouse/admin/adminsubmit":
            return ExplainsDefine[6]
        default:
            break;
    }
}

export const HowtouseExplain = memo(() => {
    const history    = useHistory();
    const {pathname} = useLocation();
    const switching  = switchingFunc(pathname);
    const [count, setcount] = useState(0);
    const idList = switching.id;
    const [spotelem, setspotelem] = useState({"top": null, "left": null, "bottom": null, "right": null});//spotのスタイルに必要なデータ
    const [explainelem, setexplainelem] = useState({"top":null,"left":null});//explainのスタイルに必要なデータ
    const EXPLAINS = switching.explain;

    useEffect(()=>{
        onClickFunc()
    },[])


    /*画面クリック時の処理*/
    const onClickFunc = () => {
        setcount(count + 1);
        // console.log("count",count);
        // console.log("idList.length+1",idList.length+1);
        // console.log("(count+1)%(idList.length+1)",(count+1)%(idList.length+1));
        if((count+1)%(idList.length+1)!==0){
            const [explainelem, spotelem] =  onWindowClickFunc(idList[(count+1)%(idList.length+1)-1])
            setspotelem(spotelem);
            setexplainelem(explainelem);
        }
        else{
            history.goBack();//操作画面説明ページを閉じる
        }
    }

    return(
        <div onClick = {onClickFunc} style = {{position:"relative",zIndex:"300"}} id="howtouse">
            {switching.component}
            <SpotLight
                count = {count}
                spotelem = {spotelem}
                explainelem = {explainelem}
                idList = {idList}
                EXPLAINS = {EXPLAINS}
            />
        </div>
    )
});

//画面をクリックしたときに指定の要素の場所データを取得する(絶対位置を取得する)⇒上はヘッダ分の大きさが要らない。下は404ページ文が要らない。
export const onWindowClickFunc = (ID) => {
    let elem_howtouse = document.getElementById("howtouse");
    let rect_howtouse = elem_howtouse.getBoundingClientRect();
    const window_h = rect_howtouse.bottom + window.pageYOffset;
    const window_w = window.innerWidth;/*ウインドウ(見開きの横幅)の大きさを取得*//*vwの取得*/
    const HEADERTHEIGHT = 50;/*ヘッダ分の高さ*/
    const EXPLAINHEIGHT = 28;/*explainコンポーネントのHEIGHT*/
    const EXPLAINMARGIN = 4;/*スポットライト部分と説明文を意図的に隙間を開ける*/
    let spotelem = {"top":null, "left":null, "bottom":null, "right":null};/*初期化*/
    let explainelem = {"top":null,"left":null};/*初期化*/
    let elem  = document.getElementById(ID);
    let rect = elem.getBoundingClientRect();/*ウインドウの左上からの座標(x、y、top、left、right、bottom, width、height)*/
    spotelem["top"] = rect.top + window.pageYOffset - HEADERTHEIGHT;
    spotelem["left"] = rect.left + window.pageXOffset;
    spotelem["bottom"] = window_h - (rect.bottom + window.pageYOffset)
    spotelem["right"] = window_w - rect.right + window.pageXOffset;
    explainelem["top"] = rect.top + window.pageYOffset - HEADERTHEIGHT -EXPLAINHEIGHT - EXPLAINMARGIN;
    explainelem["left"] = rect.left + window.pageXOffset;
    //例外対応を行う。
    [explainelem, spotelem] = IdbranchFunc(ID, rect.width, rect.height, spotelem, explainelem, window_h);
    return [explainelem, spotelem]
}

/*idの例外に対応する*/
//例外処理をするidをあらかじめ定義しておく
const excenptionid = {
    earthquakeinfo:{
        width: "200",
        height:"28" 
    },
    username:{
        width: "200",
        height:"28" 
    },
    button:{
        width: "116",
        height:"28" 
    },
    damageSituation:{
        width: "200",
        height:"28" 
    },
    employeeBtn:{
        width: "224",
        height:"28" 
    },
    selection: {
        width: "224",
        height: "28"
    },
    damageList: {
        width: "224",
        height: "28"
    },
    damageIcon: {
        width: "224",
        height: "28"
    },
    companyCheckIcon:{
        width: "308",
        height: "28"
    },
    commentIcon: {
        width: "272",
        height: "28"
    },
    earthquakeIntensity:{
        width: "284",
        height: "28"
    },
    ObservationSite:{
        width: "260",
        height: "28"
    },
    UserInsert:{
        width: "200",
        height: "28"
    },
    UserList:{
        width: "212",
        height: "28"
    },
    UserInfo:{
        width: "224",
        height: "28"
    },
    UserDelete:{
        width: "188",
        height: "28"
    },
    UserEdit:{
        width: "224",
        height: "28"
    },
    UserInsertData:{
        width: "200",
        height: "28"
    }, 
    UserInsertadmin:{
        width: "200",
        height: "28"
    },
    title:{
        width: "212",
        height: "28"
    }, 
    noteAddition:{
        width: "152",
        height: "28"
    }, 
    note:{
        width: "152",
        height: "28"
    },
    noteDelete:{
        width: "200",
        height: "28"
    }
}

//例外処理をする関数
const IdbranchFunc = (id, width, height, spotelem, explainelem, window_h) => {
    const window_ih = window.innerHeight+window.pageYOffset - 50;/*ウインドウ(見開きの縦画面幅)の大きさを取得*/
    const difference = window_h - window_ih
    const EXPLAINMARGIN = 4;/*スポットライト部分と説明文を意図的に隙間を開ける*/
    const EXPLAINHEIGHT = 28;/*explainコンポーネントのHEIGHT*/
    let exceptExplainelem = {};
    let exceptSpotelem = {};
    //idを見て例外に含まれているか確認する。
    switch(id){
        case "earthquakeinfo":
            const earthquakeinfotop = 8;
            const earthquakeinfoleft = 8;
            const earthquakeinfobottom = 8;
            const earthquakeinforight = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - earthquakeinfotop;
            exceptSpotelem["left"] = spotelem["left"] - earthquakeinfoleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - earthquakeinfobottom;
            exceptSpotelem["right"] = spotelem["right"] - earthquakeinforight;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - earthquakeinfotop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]
        
        case "username":
            const usernametop = 0;
            const usernameleft = 8;
            const usernamebottom = 0;
            const usernameright = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - usernametop;
            exceptSpotelem["left"] = spotelem["left"] - usernameleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - usernamebottom;
            exceptSpotelem["right"] = spotelem["right"] - usernameright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - usernametop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]

        case "button":
            const buttontop = 0;
            const buttonleft = 0;
            const buttonbottom = 8;
            const buttonright = 0;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - buttontop;
            exceptSpotelem["left"] = spotelem["left"] - buttonleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - buttonbottom;
            exceptSpotelem["right"] = spotelem["right"] - buttonright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - buttontop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]


        case "damageSituation":
            /*スポットライトの下に説明文を表示する。説明文が中央にくるように設定する*/
            exceptExplainelem["top"] = spotelem["top"] + height + EXPLAINMARGIN;
            exceptExplainelem["left"] = spotelem["left"] + (width - excenptionid[id].width)/2;
            return [exceptExplainelem, spotelem]
            
        case "employeeBtn":
            /*スポットライトの大きさを変えたい。説明文がスポットライトの右端揃えになるようにする*/
            //スポットライトの大きさ変更
            const employeeBtnIdtop = 8
            const employeeBtnIdleft = 8
            const employeeBtnIdbottom = 8
            const employeeBtnIdright = 8
            exceptSpotelem["top"] = spotelem["top"] - employeeBtnIdtop
            exceptSpotelem["left"] = spotelem["left"] - employeeBtnIdleft
            exceptSpotelem["bottom"] = spotelem["bottom"] - employeeBtnIdbottom
            exceptSpotelem["right"] = spotelem["right"] - employeeBtnIdright
            //右揃え
            exceptExplainelem["top"]  = exceptSpotelem["top"] - EXPLAINHEIGHT - EXPLAINMARGIN
            exceptExplainelem["left"] = exceptSpotelem["left"] - (excenptionid[id].width - (width + employeeBtnIdleft + employeeBtnIdright))
            return [exceptExplainelem, exceptSpotelem]

        case "selection":
            /*説明文が中央にくるように設定する*/
            exceptExplainelem["top"] = explainelem["top"];
            exceptExplainelem["left"] = spotelem["left"] + (width - excenptionid[id].width)/2;
            return [exceptExplainelem, spotelem]
        
        case "damageList":
            /*スポットライト部分はbottomから29px、左右は8px,8px,上は8px*/
            const damageListIdtop = 8
            const damageListIdleft = 8
            const spotLightBottom = 29
            const damageListIdright = 8
            const damageListIdbottom = difference - spotLightBottom
            exceptSpotelem["top"] = spotelem["top"] - damageListIdtop
            exceptSpotelem["left"] = spotelem["left"] + damageListIdleft
            exceptSpotelem["bottom"] = damageListIdbottom 
            exceptSpotelem["right"] = spotelem["right"] + damageListIdright
            /*説明文が中央にくるように設定する*/
            exceptExplainelem["top"] = exceptSpotelem["top"] - EXPLAINHEIGHT - EXPLAINMARGIN
            exceptExplainelem["left"] = exceptSpotelem["left"] + (width - excenptionid[id].width)/2;
            return [exceptExplainelem, exceptSpotelem]
        
        case "damageIcon":
            const damageIcontop = 5;
            const damageIconleft = 5;
            const damageIconbottom = 5;
            const damageIconright = 5;
            const damageIconexplainleft = -50;
            /*スポットライト部分はbottom2px,left2px,right2px,top2px分余裕を持つ*/
            exceptSpotelem["top"] = spotelem["top"] - damageIcontop;
            exceptSpotelem["left"] = spotelem["left"] - damageIconleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - damageIconbottom;
            exceptSpotelem["right"] = spotelem["right"] - damageIconright;
            /*説明文の位置を調節する*/
            exceptExplainelem["top"] = spotelem["top"] - damageIcontop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = explainelem["left"] + (width - excenptionid[id].width)/2 + damageIconexplainleft;
            return [exceptExplainelem, exceptSpotelem]

        case "companyCheckIcon":
            const companyCheckIcontop = 8;
            const companyCheckIconleft = 5;
            const companyCheckIconbottom = 8;
            const companyCheckIconright = 5;
            const companyCheckIconexplainleft = 32;
            /*スポットライト部分はbottom2px,left2px,right2px,top2px分余裕を持つ*/
            exceptSpotelem["top"] = spotelem["top"] - companyCheckIcontop;
            exceptSpotelem["left"] = spotelem["left"] - companyCheckIconleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - companyCheckIconbottom;
            exceptSpotelem["right"] = spotelem["right"] - companyCheckIconright;
            /*説明文の位置を調節する*/
            exceptExplainelem["top"] = spotelem["top"] - companyCheckIcontop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = explainelem["left"] + (width - excenptionid[id].width) + companyCheckIconexplainleft;
            return [exceptExplainelem, exceptSpotelem]
        
        case "commentIcon":
            const commentIcontop = 8;
            const commentIconleft = 5;
            const commentIconbottom = 8;
            const commentIconright = 5;
            /*スポットライト部分はbottom2px,left2px,right2px,top2px分余裕を持つ*/
            exceptSpotelem["top"] = spotelem["top"] - commentIcontop;
            exceptSpotelem["left"] = spotelem["left"] - commentIconleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - commentIconbottom;
            exceptSpotelem["right"] = spotelem["right"] - commentIconright;
            /*説明文の位置を右端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - commentIcontop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"] - (excenptionid[id].width - (width + commentIconleft + commentIconright))
            return [exceptExplainelem, exceptSpotelem]

        case "earthquakeIntensity":
            const earthquakeIntensitytop = 8;
            const earthquakeIntensityleft = - 8;
            const earthquakeIntensitybottom = 8;
            const earthquakeIntensityright = - 8;
            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - earthquakeIntensitytop;
            exceptSpotelem["left"] = spotelem["left"] - earthquakeIntensityleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - earthquakeIntensitybottom;
            exceptSpotelem["right"] = spotelem["right"] - earthquakeIntensityright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - earthquakeIntensitytop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]

        case "ObservationSite":
            const ObservationSitetop = 8;
            const ObservationSiteleft = - 8;
            const ObservationSitebottom = 8;
            const ObservationSiteright = - 8;
            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - ObservationSitetop;
            exceptSpotelem["left"] = spotelem["left"] - ObservationSiteleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - ObservationSitebottom;
            exceptSpotelem["right"] = spotelem["right"] - ObservationSiteright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - ObservationSitetop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]

        case "UserInsert":
            const UserInserttop = 8;
            const UserInsertleft = 8;
            const UserInsertbottom = 8;
            const UserInsertright = 8;
            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserInserttop;
            exceptSpotelem["left"] = spotelem["left"] - UserInsertleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserInsertbottom;
            exceptSpotelem["right"] = spotelem["right"] - UserInsertright;
            /*説明文の位置を右端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserInserttop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"] - (excenptionid[id].width - (width + UserInsertleft + UserInsertright))
            return [exceptExplainelem, exceptSpotelem]

        case "UserList":
            const UserListtop = 30;
            const UserListleft = -8;
            const UserListbottom = 8;
            const UserListright = -8;
            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserListtop;
            exceptSpotelem["left"] = spotelem["left"] - UserListleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserListbottom;
            exceptSpotelem["right"] = spotelem["right"] - UserListright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserListtop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]
        
        case "UserInfo":
            const UserInfotop = 8;
            const UserInfoleft = 8;
            const UserInfobottom = 8;
            const UserInforight = 8;
            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserInfotop;
            exceptSpotelem["left"] = spotelem["left"] - UserInfoleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserInfobottom;
            exceptSpotelem["right"] = spotelem["right"] - UserInforight;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserInfotop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]
        
        case "UserDelete":
            const UserDeletetop = 8;
            const UserDeleteleft = 8;
            const UserDeletebottom = 8;
            const UserDeleteright = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserDeletetop;
            exceptSpotelem["left"] = spotelem["left"] - UserDeleteleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserDeletebottom;
            exceptSpotelem["right"] = spotelem["right"] - UserDeleteright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserDeletetop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]

        case "UserEdit":
            const UserEdittop = 8;
            const UserEditleft = 8;
            const UserEditbottom = 8;
            const UserEditright = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserEdittop;
            exceptSpotelem["left"] = spotelem["left"] - UserEditleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserEditbottom;
            exceptSpotelem["right"] = spotelem["right"] - UserEditright;
            /*説明文の位置を右端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserEdittop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"] - (excenptionid[id].width - (width + UserEditleft + UserEditright))
            return [exceptExplainelem, exceptSpotelem]

        case "UserInsertData":
            const UserInsertDatatop = 8;
            const UserInsertDataleft = -8;
            const UserInsertDatabottom = 8;
            const UserInsertDataright = -8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserInsertDatatop;
            exceptSpotelem["left"] = spotelem["left"] - UserInsertDataleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserInsertDatabottom;
            exceptSpotelem["right"] = spotelem["right"] - UserInsertDataright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserInsertDatatop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]
        
        case "UserInsertadmin":
            const UserInsertadmintop = 8;
            const UserInsertadminleft = 8;
            const UserInsertadminbottom = 8;
            const UserInsertadminright = -230;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - UserInsertadmintop;
            exceptSpotelem["left"] = spotelem["left"] - UserInsertadminleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - UserInsertadminbottom;
            exceptSpotelem["right"] = spotelem["right"] - UserInsertadminright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - UserInsertadmintop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem]

        case "title":
            const titletop = 8;
            const titleleft = -8;
            const titlebottom = 8;
            const titleright = -8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - titletop;
            exceptSpotelem["left"] = spotelem["left"] - titleleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - titlebottom;
            exceptSpotelem["right"] = spotelem["right"] - titleright;
            /*説明文の位置に左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - titletop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem] 
        
        case "noteAddition":
            const noteAdditiontop = 8;
            const noteAdditionleft = 8;
            const noteAdditionbottom = 8;
            const noteAdditionright = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - noteAdditiontop;
            exceptSpotelem["left"] = spotelem["left"] - noteAdditionleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - noteAdditionbottom;
            exceptSpotelem["right"] = spotelem["right"] - noteAdditionright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - noteAdditiontop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem] 
        
        case "note":
            const notetop = 8;
            const noteleft = 8;
            const notebottom = 8;
            const noteright = -15;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - notetop;
            exceptSpotelem["left"] = spotelem["left"] - noteleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - notebottom;
            exceptSpotelem["right"] = spotelem["right"] - noteright;
            /*説明文の位置を左端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - notetop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"];
            return [exceptExplainelem, exceptSpotelem] 
        
        case "noteDelete":
            const noteDeletetop = 8;
            const noteDeleteleft = 8;
            const noteDeletebottom = 8;
            const noteDeleteright = 8;

            /*スポットライト部分を変更*/
            exceptSpotelem["top"] = spotelem["top"] - noteDeletetop;
            exceptSpotelem["left"] = spotelem["left"] - noteDeleteleft;
            exceptSpotelem["bottom"] = spotelem["bottom"] - noteDeletebottom;
            exceptSpotelem["right"] = spotelem["right"] - noteDeleteright;
            /*説明文の位置を右端揃え*/
            exceptExplainelem["top"] = spotelem["top"] - noteDeletetop -EXPLAINHEIGHT - EXPLAINMARGIN;
            exceptExplainelem["left"] = exceptSpotelem["left"] - (excenptionid[id].width - (width + noteDeleteleft + noteDeleteright))
            return [exceptExplainelem, exceptSpotelem]    


        default:
            return [explainelem, spotelem]

    }
}



//スポットライトを表示するコンポーネント
const SpotLight = memo((props)=>{
    const { count, spotelem, explainelem, idList, EXPLAINS } = props;

    /*スポットライトのスタイルの定義 */
    let SSpotLight = styled.div`
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top : 0;
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: ${spotelem.top}px solid rgba(0,0,0,0.5);
            border-bottom: ${spotelem.bottom}px solid rgba(0,0,0,0.5);
            border-left: ${spotelem.left}px solid rgba(0,0,0,0.5);
            border-right: ${spotelem.right}px solid rgba(0,0,0,0.5);
        }
    `;
    /*Explainのスタイルの定義*/
    let SExplain = {
        position: "absolute",
        top: explainelem.top, 
        left: explainelem.left
    }
    

    if(count%(idList.length+1)!==0){
        return(
            <SSpotLight>
                <HowtoExplain
                    explain = {EXPLAINS[count%(idList.length+1)-1]}
                    style={SExplain}
                />
                <div style={{width:"90px",height:"100px"}}></div>
            </SSpotLight>  
        );
    }
    else{
        return null;
    }
});
