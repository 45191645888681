/*
    概要：HowtoUseのページで使用する機能のないページ。メンバーの回答状況を詳細に表示するページ。
*/
import { NoneFuncHeaderFooterLayoutJokyo } from "../../templates/NoneFuncHeaderFooterLayoutJokyo";
import React, {memo} from 'react';
import {
    TxtSaishinHigai       , 
    TxtKakoHigai          , //過去の被害状態
    DamageSituation01     , //地震を一覧で表示するために使用している
    DamageSituationDetail , //地震一覧をクリックしたときに表示する詳細部分
} from '../../../ui-components'

export const MemberDetailPage = memo(()=>{
    return(
        <NoneFuncHeaderFooterLayoutJokyo>
            <EarthquakeList/>
        </NoneFuncHeaderFooterLayoutJokyo>
    );
});


/*brタグを削除する*/
const deleteBr = (text) => {
    text = text.replace(/<br>/g, "");
    return text
}  


//地震の文章フォーマット通りに成形する関数
//1. 辞書配列のキーの長さからCustomかEarthquakeから取得したデータなのかを判定
//2. それぞれの場合のフォーマットで出力する 
const EarthquakeFormatConv = (EarthquakeInfo) => {
    const dataLength = Object.keys(EarthquakeInfo).length//取得データのキーの長さが入る
    const customLength = 3//カスタムデータだったとき
    const earthquakeLength = 4//地震データだったとき
    let format_str//指定のフォーマット
    if(dataLength === customLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let title = EarthquakeInfo.attributes.Title
        format_str = 'datetime title';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/title/g, title);
    }
    else if(dataLength === earthquakeLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let spot = EarthquakeInfo.spot
        let seismicIntensity = EarthquakeInfo.seismicIntensity
        format_str = 'datetime <br> spot 震度seismicIntensity';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/spot/g, spot);
        format_str = format_str.replace(/seismicIntensity/g, seismicIntensity);
    }
    return format_str
}

//日付から文字列に変換する関数
const getStringFromDate = (date) => {
    let year_str = date.substr(0,4);
    let month_str = date.substr(4,2);
    let day_str = date.substr(6,2);
    let hour_str = date.substr(8,2);
    let minute_str = date.substr(10,2);
    
    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
}


/*howtouseComponent*/
const EarthquakeList = memo(()=>{
    const  MyIconStatus = "angle-down"
    const earthquake = [
        {id: '20220828120705', datetime: '20220828120413', seismicIntensity: '5強', spot: '愛知県'},
        {id: '20220826084838', datetime: '20220826085226', seismicIntensity: '5強', spot: '静岡県'},
        {id: '20220827083338', datetime: '20220827085226', seismicIntensity: '5強', spot: '岐阜県'},
        {id: '20220828084838', datetime: '20220828085226', seismicIntensity: '5強', spot: '京都府'}
    ]

    const UserStatus = {
        "NumberOfResponse": 10,
        "FamilyHealthDamage": 1,
        "Safe": 0,
        "MyHealthDamage": 1,
        "HomeDamage": 1,
        "Unanswered": 7,
    }

    return(                   
        <>
            {/*1列目*/}
            <div style = {{marginTop:"24px", marginBottom:"32px"}}>
                <div style={{width:"95%", margin:"auto", height:"64px", paddingTop:"8px",paddingBottom:"8px"}}>
                    {/*一覧で表示している地震情報*/}
                    <TxtSaishinHigai
                        width = {"94.7%"}
                        style = {{margin:"auto", marginBottom:"12px"}}
                    />
                    <DamageSituation01
                        overrides = {
                            {
                                "text":{
                                    children: `${deleteBr(EarthquakeFormatConv(earthquake[0]))}`
                                },
                                "MyIcon": {
                                    type: `${MyIconStatus}`
                                }
                            }
                        }
                        width = {"94.7%"}
                        style = {{margin:"auto"}}
                    />
                    <div id="NumberofPeople">
                        <DamageSituationDetail
                            overrides = {
                                {
                                    "number37054675":{//回答者数`${format_str}`//UserStatus["NumberOfResponse"]
                                        children: `${UserStatus["NumberOfResponse"]}`
                                    },
                                    "number37054696":{//家族の健康被害//UserStatus["FamilyHealthDamage"]
                                        children: `${UserStatus["FamilyHealthDamage"]}`
                                    },
                                    "number37054682":{//無事//UserStatus["Safe"]
                                        children: `${UserStatus["Safe"]}`
                                    },
                                    "number37054689":{//自分の健康被害//UserStatus["MyHealthDamage"]
                                        children: `${UserStatus["MyHealthDamage"]}`
                                    },
                                    "number37054703":{//家被害//UserStatus["HomeDamage"]
                                        children: `${UserStatus["HomeDamage"]}`
                                    },
                                    "number37054710":{//未回答//UserStatus["Unanswered"]
                                        children: `${UserStatus["Unanswered"]}`
                                    }
                                }
                            }
                            width = {"94.7%"}
                            paddingTop={"8px"}
                            paddingBottom={"8px"}
                            style = {{margin:"auto"}}
                        />
                    </div>
                </div>
            </div>
            
            {/*2列目*/}
            <div style = {{marginTop:"220px",marginBottom:"16px"}}>
                <TxtKakoHigai
                    width = {"90%"}
                    style = {{margin:"auto", marginBottom:"12px"}}
                />
                <DamageSituation01
                    overrides = {
                        {
                            "text":{
                                children: `${deleteBr(EarthquakeFormatConv(earthquake[1]))}`
                            },
                            "MyIcon": {
                                type: `${MyIconStatus}`
                            }
                        }
                    }
                    width = {"90%"}
                    style = {{margin:"auto"}}
                />
            </div>
            {/*3列目*/}
            <div style= {{display:"block", marginBottom:"16px"}}>
                <DamageSituation01
                    overrides = {
                        {
                            "text":{
                                children: `${deleteBr(EarthquakeFormatConv(earthquake[2]))}`
                            },
                            "MyIcon": {
                                type: `${MyIconStatus}`
                            }
                        }
                    }
                    width = {"90%"}
                    style = {{margin:"auto"}}
                />
            </div>
            {/*4列目*/}
            <div style= {{display:"block", marginBottom:"16px"}}>
                <DamageSituation01
                    overrides = {
                        {
                            "text":{
                                children: `${deleteBr(EarthquakeFormatConv(earthquake[3]))}`
                            },
                            "MyIcon": {
                                type: `${MyIconStatus}`
                            }
                        }
                    }
                    width = {"90%"}
                    style = {{margin:"auto"}}
                />
            </div>
        </>
    );
})

