/* eslint-disable react-hooks/exhaustive-deps */
/*
    概要：Headerコンポーネント
*/
import React, { memo, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../../ui-components";
import { HeaderSideBar } from "./HeaderSideBar";

export const CommonHeader = memo(() =>{
    const history    = useHistory();
    const {pathname} = useLocation();
    const [btnStatus, setbtnStatus] = useState(false)

    const btnStatusChangeFunc = () => {
        setbtnStatus(!btnStatus)
    };

    const HeaderOverrides = 
    {
        /*状況確認の文字*/
        "Frame 2017":{
            onClick: () => {history.push("/safetyresult");}
        }, 
        /*安否登録の文字*/
        "Frame 2016":{
            onClick: () => history.push("/safetyconfirm")
        },
        /*ハンバーガーボックスのアイコン*/
        "MyIcon":{
            onClick:()=>{btnStatusChangeFunc()}
        }
    }
    

    let HeaderComponent = 
    <Header
        overrides = {HeaderOverrides}
        width  = {"100vw"}//画像幅レスポンス
        status = {"Anpi"}
    />;

    if(pathname.includes(["/safetyconfirm","/SafetyConfirm"]))
    {
        HeaderComponent = 
        <Header
            overrides = {HeaderOverrides}
            width     = {"100vw"}
            status    = {"Anpi"}
        />
    }
    else if(pathname.includes("/safetyresult"))
    {
        HeaderComponent = 
        <Header
            overrides = {HeaderOverrides}
            width     = {"100vw"}
            status    = {"Jokyo"}
        />
    }

    return(
        <div>
            {HeaderComponent}
            <HeaderSideBar
                HeaderFlag={btnStatus}
                HeaderBtnOpen={() => btnStatusChangeFunc()}
            />
        </div>
    )
})



