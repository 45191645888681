/*
    概要：HowtoUseのページで使用する機能のないページ。メンバーを追加するページ。
*/
import React, {useState, memo} from 'react';
import {
    Top         , //ページの一番上の部分
    InputToggle ,
    Btncancel   ,
    Btn         ,
    Input02
}
from "../../../ui-components";

export const MemberSettingPage = memo(()=>{
  
    //ユーザ登録用の変数
    const nameInsert     = "山田六郎";                 //ユーザの名前を登録する
    const mailInsert     = "take101@trafficsim.co.jp"; //メールを登録する
    const phoneInsert    = "09038589402";              //電話番号を登録する
    const employeeNumber = "6";                        //社員番号
    const adminFlag      = "off";                      //管理者フラグ

    return (
        <div style = {{width: "100%", height:"100vh", backgroundColor:"rgba(0,0,0,0.5)", zIndex:"0", display:"flex", alignItems: "center", justifyContent: "center", overflowY: "hidden", maxHeight: "100vh"}}>
            <div style = {{zIndex:"0", width:"100%", height:"100%", padding: "0", overflowY: "hidden", maxHeight: "100vh", backgroundColor: "#F5F5F5"}}>
            
                <div style = {{paddingTop:"16px",marginBottom:"16px"}}>
                    <Top
                        key = {"Top"}
                        overrides = {
                            {
                                "MyIcon33471830":{//左の矢印ボタン
                                }
                            }
                        }
                        status = {"UserAdd"}//UserAddのプロパティを選択
                        width = {"90vw"}
                        style = {{margin:"auto"}}
                    />
                </div>
                <form>
                    <div id = "UserInsertData">
                        {/*名前の入力フォーム*/}
                        <Input02
                            key = {"name"}
                            overrides = {
                                {
                                    "text36392805":{
                                        children: [<input key = {"nameInput"} type="text" placeholder="" value={nameInsert}  style = {{border: "none", outline: "none",  width:"90vw",backgroundColor:"#F5F5F5"}}/>]
                                    }
                                }
                            }
                            status = "name"
                            kome ="on"
                            width = {"90vw"}
                            style = {{margin:"auto",marginBottom:"14px"}}
                        />

                        {/*電話番号の入力フォーム*/}
                        <Input02
                            key = {"phone"}
                            overrides = {
                                {
                                    "text36392805":{
                                        children: [<input key={"phoneInput"} type="tel" placeholder="" value={phoneInsert}  style = {{border: "none", outline: "none",  width:"90vw",backgroundColor:"#F5F5F5"}}/>]
                                    }
                                }
                            }
                            status = "PhoneNumber"
                            kome ="on"
                            width = {"90vw"}
                            style = {{margin:"auto",marginBottom:"14px"}}
                        />

                        {/*メールアドレスの入力フォーム*/}
                        <Input02
                            key = {"mail"}
                            overrides = {
                                {
                                    "text36392805":{
                                        children: [<input key={"mailInput"} type="eamil" placeholder="" value={mailInsert} style = {{border: "none", outline: "none",  width:"90vw",backgroundColor:"#F5F5F5"}}/>]
                                    }
                                }
                            }
                            status = "mailaddress"
                            kome = "on"
                            width = {"90vw"}
                            style = {{margin:"auto",marginBottom:"14px"}}
                        />

                        {/*社員番号の入力フォーム*/}
                        <Input02
                            key = {"employeeNumber"}
                            overrides = {
                                {
                                    "text36392805":{
                                        children: [<input key={"employeeNumberInput"} type="text" placeholder="" value={employeeNumber} style = {{border: "none", outline: "none",  width:"90vw",backgroundColor:"#F5F5F5"}}/>]
                                    },
                                    "text36392801":{
                                        children: "社員番号"
                                    }
                                }
                            }
                            status = "EmployeeNumber"
                            kome = "on"
                            width = {"90vw"}
                            style = {{margin:"auto",marginBottom:"14px"}}
                        />
                    </div>
                    

                    {/*管理者トグルボタン*/}
                    <div>
                        <InputToggle
                            id = "UserInsertadmin"
                            overrides = {
                                {
                                    "btnToggle":{
                                        toggle : adminFlag
                                    }
                                }
                            }
                            property1 = "Admin"
                            style = {{marginLeft:"5%", marginBottom:"70px"}}
                        />
                    </div>
                    <div>
                        {/*登録ボタンコンポーネント*/}
                        <Btn
                            property1 = "Touroku"
                            width = {"90%"}
                            margin = {"auto"}
                            marginBottom = {"8px"}
                            type="submit"
                        />
                        <Btncancel
                            width = {"90%"}
                            margin = {"auto"}
                        />
                    </div>
                </form>
                
            </div>
        </div>
    );
});