/*
    概要：HowtoUseのページで使用する機能のないページ。安否結果を表示するページ。
*/
import React, {memo} from "react";
import { NoneFuncHeaderFooterLayoutJokyo } from "../../templates/NoneFuncHeaderFooterLayoutJokyo";
import {
    TxtSaishinHigai   ,
    TxtKakoHigai      ,//過去の被害状態
    DamageSituation01 ,//地震を一覧で表示するために使用している
} from "../../../ui-components"

export const SafetyResultPage = memo(()=>{
    return(
        <NoneFuncHeaderFooterLayoutJokyo>
            <EarthquakeList/>
        </NoneFuncHeaderFooterLayoutJokyo>
    );
});



/*brタグを削除する*/
const deleteBr = (text) => {
    text = text.replace(/<br>/g, "");
    return text
}  


//地震の文章フォーマット通りに成形する関数
const EarthquakeFormatConv = (EarthquakeInfo) => {
    const dataLength = Object.keys(EarthquakeInfo).length//取得データのキーの長さが入る
    const customLength = 3//カスタムデータだったとき
    const earthquakeLength = 4//地震データだったとき
    let format_str//指定のフォーマット
    if(dataLength === customLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let title = EarthquakeInfo.attributes.Title
        format_str = 'datetime title';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/title/g, title);
    }
    else if(dataLength === earthquakeLength){
        let datetime = getStringFromDate(EarthquakeInfo.datetime)//datetimeを指定の形に変換
        let spot = EarthquakeInfo.spot
        let seismicIntensity = EarthquakeInfo.seismicIntensity
        format_str = 'datetime <br> spot 震度seismicIntensity';//指定のフォーマット
        format_str = format_str.replace(/datetime/g, datetime);
        format_str = format_str.replace(/spot/g, spot);
        format_str = format_str.replace(/seismicIntensity/g, seismicIntensity);
    }
    return format_str
}

//日付から文字列に変換する関数
const getStringFromDate = (date) => {
    let year_str = date.substr(0,4);
    let month_str = date.substr(4,2);
    let day_str = date.substr(6,2);
    let hour_str = date.substr(8,2);
    let minute_str = date.substr(10,2);
    
    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
}



/*howtouseComponent*/
const EarthquakeList = memo(()=>{
    const  MyIconStatus = "angle-down"
    const earthquake = [
        {id: '20220828120705', datetime: '20220828120413', seismicIntensity: '5強', spot: '愛知県'},
        {id: '20220826084838', datetime: '20220826085226', seismicIntensity: '5強', spot: '静岡県'},
        {id: '20220827083338', datetime: '20220827085226', seismicIntensity: '5強', spot: '岐阜県'},
        {id: '20220828084838', datetime: '20220828085226', seismicIntensity: '5強', spot: '京都府'}
    ]

    return(                   
        <>
            <div style = {{marginTop:"24px", marginBottom:"32px"}}>
                <div style={{width:"95%", margin:"auto", height:"64px", paddingTop:"8px",paddingBottom:"8px"}} id = "latest">
                    {/*一覧で表示している地震情報*/}
                    <TxtSaishinHigai
                        width = {"94.7%"}
                        style = {{margin:"auto", marginBottom:"12px"}}
                    />
                    <DamageSituation01
                        overrides = {
                            {
                                "text":{
                                    children: `${deleteBr(EarthquakeFormatConv(earthquake[0]))}`
                                },
                                "MyIcon": {
                                    type: `${MyIconStatus}`
                                }
                            }
                        }
                        width = {"94.7%"}
                        style = {{margin:"auto"}}
                    />
                </div>
            
                <div  style={{width:"95%",margin:"auto",paddingTop:"8px",paddingBottom:"8px"}} id="past"> 
                    <div style = {{marginBottom:"16px"}}>
                        <TxtKakoHigai
                            width = {"94.7%"}
                            style = {{margin:"auto", marginBottom:"12px"}}
                        />
                        <DamageSituation01
                            overrides = {
                                {
                                    "text":{
                                        children: `${deleteBr(EarthquakeFormatConv(earthquake[1]))}`
                                    },
                                    "MyIcon": {
                                        type: `${MyIconStatus}`
                                    }
                                }
                            }
                            width = {"94.7%"}
                            style = {{margin:"auto"}}
                        />
                    </div>
                    {/*  */}
                    <div style= {{dispaly:"block", marginBottom:"16px"}}>
                        <DamageSituation01
                            overrides = {
                                {
                                    "text":{
                                        children: `${deleteBr(EarthquakeFormatConv(earthquake[2]))}`
                                    },
                                    "MyIcon": {
                                        type: `${MyIconStatus}`
                                    }
                                }
                            }
                            width = {"94.7%"}
                            style = {{margin:"auto"}}
                        />
                    </div>
                    {/*  */}
                    <div style= {{dispaly:"block", marginBottom:"16px"}}>
                        <DamageSituation01
                            overrides = {
                                {
                                    "text":{
                                        children: `${deleteBr(EarthquakeFormatConv(earthquake[3]))}`
                                    },
                                    "MyIcon": {
                                        type: `${MyIconStatus}`
                                    }
                                }
                            }
                            width = {"94.7%"}
                            style = {{margin:"auto"}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
})

