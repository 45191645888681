/*
  概要：ルーティングを行います
*/
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Login } from "../components/pages/login/Login";
import { Page404 } from "../components/pages/Page404";
import { HeaderLayout } from "../components/templates/HeaderLayout";
import { LoginUserProvider } from "../hooks/providers/useLoginUserProvider";
import { ToastProvider } from "../hooks/providers/useToastProvider";
import { SafetyReport } from "../components/pages/home/SafetyReport";
import { SafetyResultScreen } from "../components/pages/home/SafetyResultScreen";
import { Admin } from "../components/pages/admin/Admin";
import { AdminSubmit } from "../components/pages/admin/AdminSubmit";
import { HeaderFooterLayout } from "../components/templates/HeaderFooterLayout";
import { AdminSubmitLayout } from "../components/templates/AdminSubmitLayout";
import { OtherDataProvider } from "../hooks/providers/useOtherDataProvider";

import { HowtouseHomeRoutes } from "./HowtouseHomeRoutes";
import { HowtouseAdminRoutes } from "./HowtouseAdminRoutes";

import { ReportProvider } from '../hooks/providers/useReportProvider';
import { AdminSubmitProvider } from "../hooks/providers/useAdminSubmitProvider";
import { ResultProvider } from "../hooks/providers/useResultProvider";
import { AdminProvider } from "../hooks/providers/useAdminProvider";


export const Router = () => {
  return (
    <ToastProvider>
      <OtherDataProvider>
        <LoginUserProvider>
          <Switch>
            <Route exact path="/">
                <Login/>
            </Route>
            <Route path="/safetyconfirm">
              <HeaderFooterLayout>
                <ReportProvider>
                  <SafetyReport />
                </ReportProvider>
              </HeaderFooterLayout>
            </Route>
            <Route path="/safetyresult">
                <ResultProvider>
                  <SafetyResultScreen />
                </ResultProvider>
            </Route>
            <Route path="/admin">
              <AdminProvider>
                <Admin />
              </AdminProvider>
            </Route>
            <Route path="/adminsubmit">
              <AdminSubmitLayout>
                <AdminSubmitProvider>
                  <AdminSubmit />
                </AdminSubmitProvider>
              </AdminSubmitLayout>
            </Route>
            <Route
              path="/howtouse"
              render={({ match: { url } }) => (
                <Switch>
                  <Route
                    exact= {false}
                    path={`${url}/home`}
                    render={({match:{url}})=>(
                      <Switch>
                        {HowtouseHomeRoutes.map((route) => (
                          <Route
                            key={route.path}
                            exact={route.exact}
                            path={`${url}${route.path}`}
                          >
                            {route.children}
                          </Route>
                        ))}
                      </Switch>
                    )}
                  />

                  <Route
                    exact={false}
                    path={`${url}/admin`}
                    render={({ match: { url }}) => (
                      <Switch>
                        {HowtouseAdminRoutes.map((route)=>(
                          <Route
                            key={route.path}
                            exact={route.exact}
                            path={`${url}${route.path}`}
                          >
                            {route.children}
                          </Route>
                        ))}
                      </Switch>
                    )}
                  />
                </Switch>
              )}
            />
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
          </LoginUserProvider>
        </OtherDataProvider>
      </ToastProvider>
  );
};
