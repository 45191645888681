//メールアドレスは変更してもAmazonからメールが飛んできてそれにサブスクリプションに登録するをクリックしないと登録されない仕様です
/*
    概要：Admin.jsのユーザ編集部分のモーダル
*/
/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";
import React, {useState,useEffect} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import { updateUserSetting} from '../../../graphql/mutations';
import { Btncancel } from '../../../ui-components';
import { ConvInternationalCall, ConvPhoneNumber, SubscriptionCreateFunc, SubscriptionDeleteFunc } from "../../../hooks/misc/common";
import { useMessage } from "../../../hooks/useMessage";
import { TopHeader } from "../../molecules/top/TopHeader";
import { InputText } from "../../atoms/Input/InputText";
import { ToggleButton } from "../../atoms/button/ToggleButton";
import { MultiButton } from "../../molecules/buttons/MultiButton";

export const EditUserSetting = memo((props) =>{
    const { setEditShowFalse, EditSetting, fetchAndSetSettingUsers, setLoading, BrokenEditKey } = props;
    const { showMessage } = useMessage();
    const [Error, setError] = useState(false);
    const [Editdict, setEditdict] = useState({...EditSetting, "phone": ConvPhoneNumber(EditSetting.phone)});
    const [ErrorText, setErrorText] = useState("");
    /*
        Editdict = {
            id            : ""    , //ユーザのid
            mail          : ""    , //ユーザの名前
            name          : ""    , //メール
            phone         : ""    , //電話番号
            employeeNumber: ""    , //社員番号
            adminFlag     : false , //管理者フラグ
            mailStatus    : false , //メール通知
            smsStatus     : false   //SNS通知

        }
    */

    

    /*辞書*************************************************************************************/
    //入力欄のフォームの辞書
    const InputFormDict = [
        {
            //名前の入力フォーム
            "status"  : "name"           ,
            "kome"    : false            ,
            "dictkey" : "name"           ,
            "setvalue": setEditdict      ,
            "type"    : "text"           ,
            "dict"    : Editdict         ,
            "text"    : "名前"
        },
        {
            //電話番号の入力フォーム
            "status"  : "PhoneNumber"     ,
            "kome"    : false             ,
            "dictkey" : "phone"           ,
            "setvalue": setEditdict       ,
            "type"    : "tel"             ,
            "dict"    : Editdict          ,
            "text"    : "電話番号"
        },
        {
            //メールアドレスの入力フォーム
            "status"  : "mailaddress"     ,
            "kome"    : false             ,
            "dictkey" : "mail"            ,
            "setvalue": setEditdict       ,
            "type"    : "email"           ,
            "dict"    : Editdict          ,
            "text"    : "メールアドレス"
        }
    ]

    //トグルボタンの辞書
    const ToggleFormDict = [
        {
            //通知トグル
            "status"   : "mailtoggle",
            "dictkey"  : "mailStatus",
            "setvalue" : setEditdict ,
            "property" : "Tuuchi"    ,
            "dict"     : Editdict
        },
        {
            //SMS通知トグル
            "status"   : "smstoggle" ,
            "dictkey"  : "smsStatus" ,
            "setvalue" : setEditdict ,
            "property" : "SMS"       ,
            "dict"     : Editdict  
        },
        {
            //管理者トグル
            "status"   : "admintoggle",
            "dictkey"  : "adminFlag"  ,
            "setvalue" : setEditdict  ,
            "property" : "Admin"      ,
            "dict"     : Editdict  
        }
    ]

    /********************************************************************************************/
    /*
        概要：名前が空欄のときには、管理者フラグをoffに.電話番号が空欄のときには、SMS通知フラグをoffに.メールアドレスが空白のときには、通知フラグをoffにする。
    */
    useEffect(()=>{
        //名前入力欄が空白だったら管理者フラグをoffに
        if(!Editdict["name"]){
            setEditdict({...Editdict, "adminFlag": false});
        }

        //メール入力欄が空白だったら通知フラグをoffに
        if(!Editdict["mail"]){
            setEditdict({...Editdict, "mailStatus": false});
        }

        //電話番号入力欄が空白だったらSMS通知フラグをoffに
        if(!Editdict["phone"]){//SMS通知をoffに変更する
            setEditdict({...Editdict, "smsStatus": false});
        }
    },[Editdict["name"], Editdict["mail"], Editdict["phone"]])


    /*
        概要：trueとflaseを1と0に変換する処理(登録するときに1と0じゃないとうまくいかないため)
    */
    const BooleanConv = (flag) =>{
        if(flag){
            return 1
        }
        else{
            return 0
        }
    }

    /*
        概要：ユーザ編集登録処理
    */
    const UserEdit = async() => {
        let DofinallyFlag = true;//finally文を実行するかどうかを決める
        let updateUserSettingInput = {
            "mail"          : Editdict["mail"]                         ,
            "id"            : Editdict["id"]                           ,
            "name"          : Editdict["name"]                         ,
            "phone"         : ConvInternationalCall(Editdict["phone"]) , //+81に変換
            "adminFlag"     : BooleanConv(Editdict["adminFlag"])       , //Boolean→Int
            "employeeNumber": Editdict["employeeNumber"]               
        }
        console.log(updateUserSettingInput);

        //mailStatusとsmsStatusの変化を検知します。
        /*
            メールアドレスの通知するかどうかの有無を変更する。
        */
        let BeforeEdit = JSON.parse(JSON.stringify(EditSetting));
        let AfterEdit  = JSON.parse(JSON.stringify(Editdict));
        AfterEdit["phone"] = ConvInternationalCall(AfterEdit["phone"]);

        let StatusChanges = [];
        console.log(BeforeEdit);
        console.log(AfterEdit);
        //ステータスの変化を検知
        if(BeforeEdit.mailStatus !== AfterEdit.mailStatus)
        {
            //ONに変わった
            if(AfterEdit.mailStatus === true)
            {
                //アドレスが変わったかどうか
                if(BeforeEdit.mailStatus !== AfterEdit.mailStatus)
                {
                    StatusChanges.push({
                        "Protocol": "email",
                        "Endpoint": AfterEdit.mail,
                        "mode"    : AfterEdit.mailStatus
                    });
                }
                else
                {
                    StatusChanges.push({
                        "Protocol": "email",
                        "Endpoint": AfterEdit.mail,
                        "mode"    : AfterEdit.mailStatus
                    });
                }
            }
            //OFFに変わった
            else
            {
                //アドレスが変わったかどうか
                if(BeforeEdit.mailStatus !== AfterEdit.mailStatus)
                {
                    StatusChanges.push({
                        "Protocol": "email",
                        "Endpoint": BeforeEdit.mail,
                        "mode"    : false
                    });
                }
                else
                {
                    StatusChanges.push({
                        "Protocol": "email",
                        "Endpoint": AfterEdit.mail,
                        "mode"    : AfterEdit.mailStatus
                    });
                }
                
            }
        }

        //ステータスの変化を検知
        if(BeforeEdit.smsStatus !== AfterEdit.smsStatus)
        {
            //ONに変わった
            if(AfterEdit.smsStatus === true)
            {
                //アドレスが変わったかどうか
                if(BeforeEdit.smsStatus !== AfterEdit.smsStatus)
                {
                    StatusChanges.push({
                        "Protocol": "sms",
                        "Endpoint": AfterEdit.phone,
                        "mode"    : AfterEdit.smsStatus
                    });
                }
                else
                {
                    StatusChanges.push({
                        "Protocol": "sms",
                        "Endpoint": AfterEdit.phone,
                        "mode"    : AfterEdit.smsStatus
                    });
                }
            }
            //OFFに変わった
            else
            {
                //アドレスが変わったかどうか
                if(BeforeEdit.smsStatus !== AfterEdit.smsStatus)
                {
                    StatusChanges.push({
                        "Protocol": "sms",
                        "Endpoint": BeforeEdit.phone,
                        "mode"    : false
                    });
                }
                else
                {
                    StatusChanges.push({
                        "Protocol": "sms",
                        "Endpoint": AfterEdit.phone,
                        "mode"    : AfterEdit.smsStatus
                    });
                }
                
            }
        }



        //load画面を見せる
        setLoading(true);
        
        try{
            //ユーザ情報を追加する
            await API.graphql(graphqlOperation(updateUserSetting, {updateUserSettingInput: updateUserSettingInput }))
            .catch((e)=>{
                if(e.errors[0].message.includes("'phone' has an invalid value.")){
                    console.error(e);
                    throw new Error("電話番号が適切ではないです");
                }
                else if(e.errors[0].message.includes("'mail' has an invalid value.")){
                    console.error(e);
                    throw new Error("メールアドレスが適切ではないです");
                }
                else{
                    console.error(e);
                    throw new Error("API Invoke Error");
                }
            })

            //subscriptionへの登録をする
            StatusChanges.forEach(async function(data){
                console.log(data);
                await SubscriptionSetting(data["Protocol"], data["Endpoint"], data["mode"])
                .catch((e)=>{
                    console.error(e);
                    throw(e);
                });
            })

            //※データを取得するタイミングが早すぎて取得したデータが古いことがあるため
            //300msだけ待ちます
            await new Promise(resolve=>setTimeout(resolve, 300));

            //ユーザリストをリストで取得する 
            await fetchAndSetSettingUsers()
            .catch((e)=>{
                console.error(e);
                throw new Error("API Invoke Error");
            });
        }
        catch(e){
            console.error(e);
            DofinallyFlag = false;
            showMessage({message: e.message});
        }
        finally{
            //load画面の終了をする
            setLoading(false);
            if(DofinallyFlag){
                //モーダルを閉じる
                setEditShowFalse();
                //キーを破壊
                BrokenEditKey();
            }
        }
    }

    /*
        概要：サブスクリプションを設定する
    */
    const SubscriptionSetting = async(Protocol, Endpoint, mode) => {
        if(Protocol === "sms")
        {
            if(mode)
            {
                //Create
                await SubscriptionCreateFunc("sms", Endpoint)
                .then((response) => {
                    if(response.data.ResultStatus === false)
                    {
                        throw new Error(response.data.ResultLog.message);
                    }
                }).catch((e)=>{
                    console.error(e);
                    throw new Error("サブスクリプションの登録に失敗しました");
                })
            }
            else{
                //Delete
                await SubscriptionDeleteFunc("sms", Endpoint)
                .then((response) => {
                    if(response.data.ResultStatus === false)
                    {
                        let message = response.data.ResultLog.message
                        if(message.includes("An ARN must have at least 6 elements, not 1")){//Email
                            throw new Error("Emailすでに削除済みです");
                        }
                        else if(message.includes("no value for required parameter")){//sms
                            throw new Error("SMSすでに削除済みです");
                        }
                        else{
                            throw new Error(message);
                        }
                    }
                })
                .catch((e)=>{
                    console.error(e);
                    throw new Error("サブスクリプションの削除に失敗しました");
                })
            }
        }
        if(Protocol === "email"){
            if(mode){
                //Create
                await SubscriptionCreateFunc("email", Endpoint)
                .then((response)=>{
                    if(response.data.ResultStatus === false)
                    {
                        throw new Error(response.data.ResultLog.message);
                    }
                })
                .catch((e)=>{
                    console.error(e);
                    throw new Error("サブスクリプションの登録に失敗しました");
                })
            }
            else{
                //Delete
                await SubscriptionDeleteFunc("email", Endpoint)
                .then((response)=>{
                    if(response.data.ResultStatus === false)
                    {
                        let message = response.data.ResultLog.message
                        if(message.includes("An ARN must have at least 6 elements, not 1")){//Email
                            throw new Error("Emailすでに削除済みです");
                        }
                        else if(message.includes("no value for required parameter")){//sms
                            throw new Error("SMSすでに削除済みです");
                        }
                        else{
                            throw new Error(message);
                        }
                    }
                })
                .catch((e)=>{
                    console.error(e);
                    throw new Error("サブスクリプションの削除に失敗しました");
                })
            }
        }
    }


    /*
        概要：登録ボタンをクリックした時の処理
    */
    const ButtonClickHandler = () => {

        const flag = InputChecker();

        if(flag)
        {
            setError(true);
        }
        else
        {
            setError(false);
            UserEdit();
        }
    }

    /*
        概要：メールアドレスと電話番号のチェック
        戻り値：
            ture  : 不適切なメールアドレスまたは電話番号だった場合
            false : 適切なメールアドレスまたは電話番号だった場合
    */
    const InputChecker = () => {
        let tmpphone = Editdict["phone"];
        let tmpmail  = Editdict["mail"];

        //メールアドレスのパターン 正規表現
        const mailchecker = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
        // 0から始まり、残りの数字9桁または10桁の半角数字
        const phonecheker = /^0\d{9,10}$/;


        //ハイフンをなくす
        if(tmpphone.match(/-/))
        {
            tmpphone = tmpphone.replace(/-/, "");
        }
        
        const mailflag  = mailchecker.test(tmpmail);
        const phoneflag = phonecheker.test(tmpphone);


        let flag = false;
        let ErrorQueue = [];
        
        if(!mailflag)
        {
            console.log("不適切なメールアドレスです。");
            ErrorQueue.push(["不適切なメールアドレスです。"]);
            flag = true;
        }

        if(!phoneflag)
        {
            console.log("不適切な電話番号です。");
            ErrorQueue.push(["不適切な電話番号です。"]);
            flag = true;
        }

        if(Editdict["name"] === "")
        {
            ErrorQueue.push(["名前を入力してください。"]);
            flag = true;
        }

        setErrorText(ErrorQueue);
        return flag;
    }



    return (
        <div style = {{width:"100%", height:"100vh", padding: "0", overflowY:"auto", backgroundColor:"#F5F5F5"}}>
            {/*ユーザ編集のヘッダー*/}        
            <TopHeader
                text         = {"ユーザ編集"}
                clickFunc    = {()=>{setEditShowFalse();BrokenEditKey();}}
                status       = {"UserAdd"}
                paddingTop   = {"16px"}
                width        = {"90vw"}
                marginTop    = {"0px"}
                marginLeft   = {"auto"}
                marginRight  = {"auto"}
                marginBottom = {"24px"}
            />
            <form>
                {
                    // 入力フォーム欄
                    InputFormDict.map((inputform)=>{
                        return(
                            <div key={inputform["status"]}>
                                <InputText
                                    dictkey  = {inputform["dictkey" ]}
                                    setValue = {inputform["setvalue"]}
                                    status   = {inputform["status"  ]}
                                    kome     = {inputform["kome"    ]}
                                    Type     = {inputform["type"    ]}
                                    dict     = {inputform["dict"    ]}
                                    text     = {inputform["text"    ]}
                                /> 
                            </div>
                        )
                    })
                }
                <div style = {{width:"90vw", margin:"auto"}}>
                    {
                        // トグルボタン
                        ToggleFormDict.map((toggleform)=>{
                            return (
                                <div key={toggleform["status"]}>
                                    <ToggleButton
                                        dictkey  = {toggleform["dictkey" ]}
                                        setValue = {toggleform["setvalue"]}
                                        property = {toggleform["property"]}
                                        dict     = {toggleform["dict"    ]}
                                    />
                                </div>
                            )
                        })
                    }
                </div>

                 <div>
                    {/*登録ボタン*/}
                    <MultiButton
                        CallBackFunc = {()=>{ButtonClickHandler()}} 
                        omission     = {Error}
                        text         = {"登録"}
                        pattern      = {"default"}
                        omissionText = {ErrorText}
                    />
                    <Btncancel
                        onClick = {()=>{setEditShowFalse(); BrokenEditKey();}}
                        width   = {"90%"}
                        margin  = {"auto"}
                    />
                </div>
            </form>
        </div>
    )
});


