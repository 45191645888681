/*
    概要：Admin.jsの観測値選択部分のモーダル
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, memo} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { monotonicFactory } from 'ulid';
import {JISCODE_reverse,LocalArea } from '../../../setting/PrefectureJIS';
import { createCustomEarthQuaqeSettingSpot } from '../../../graphql/mutations';
import {
    ListRegion,
    ListPrefecture
}
from '../../../ui-components';
import { TopHeader } from '../../molecules/top/TopHeader';
import { MultiButton } from '../../molecules/buttons/MultiButton';

export const ObservationSelect = memo((props) =>{
    const {ObservationSelectShow, setobservationSelectShow, spot, GetEarthQuaqeSettingSpot, setLoading} = props;
    const [AreaClickState, setAreaClickState] = useState([])//地方をクリックしたときに地方名を入れる
    const [update,setupdate] = useState(false)//強制再描画させる
    //関東とかの地方判定は配列の長さから行う
    const [AreaClickDetail, setAreaClickDetail] = useState({"北海道・東北": [], "関東": [], "東海": [], "関西": [], "中国・四国": [], "九州・沖縄": []})//近畿→関西、中部→東海

    useEffect(()=>{//初期描画時の処理
        //値をそのまま入れるのをやめる
        /*参照渡しされてしまうのを防ぐ*/
        let valueCopy1 = JSON.stringify(spot);
        valueCopy1     = JSON.parse(valueCopy1);
        setAreaClickDetail(valueCopy1);
    },[ObservationSelectShow])//props.spotは前のデータである

    
    /*関数群****************************************************************************************************/

    /*
        概要：県を選択選択して追加するときの処理
    */
    const AreaClickStateInsert = (value) => {//配列に値を追加する
        setAreaClickState([...AreaClickState, value])
    }

    /*
        概要：全選択ボタンをクリックしたときの処理
    */
    const AreaAllSelectFunc = (Area) => {
        const prefectures = LocalArea[Area]//アドレス参照されてる？？
        if(!(AreaClickDetail[Area].length===LocalArea[Area].length)){//全選択されていなければ(追加)
            setAreaClickDetail({...AreaClickDetail, [Area]: prefectures})
        }
        else{//全選択されていれば(削除)
            setAreaClickDetail({...AreaClickDetail, [Area]: []})
        }
    }

    /*
        概要：県の選択を削除する処理
    */ 
    const AreaClickStateDelete = (value) => {//配列から値を削除する
        var index = AreaClickState.indexOf(value)
        var deletingState = AreaClickState
        deletingState.splice(index, 1);//要素数、開始位置
        setAreaClickState(deletingState)
        setupdate(!update)
    }

    /*
        概要：エリア横のボタンでそれぞれの都道府県を開いたりする
    */
    const OpenOrCloseButton = (Area) => {
        if(AreaClickState.includes(Area)){//含んでいた場合
            AreaClickStateDelete(Area)
        }
        else{//含んでいなかった場合
            AreaClickStateInsert(Area)
        }
    }

    /*
        概要：県選択判定
        indexOfでヒットしなければ返り値は-1
    */
    const PrefectureSelectJudge = (Area, prefecture) => {
        let status;
        if(AreaClickDetail[Area].indexOf(prefecture) !== -1){//ヒットしたとき
            status = true
        }
        else{//ヒットしなかったとき
            status = false
        }
        return status
    }

    /*
        概要：全選択の処理
    */
    const AllSelectJudge = (Area) => {
        let status;
        if(AreaClickDetail[Area].length === LocalArea[Area].length){//全選択されている
            status = true
        }
        else{//全選択されていない
            status = false
        }
        return status
    }

    /*
        概要：登録ボタンをクリックした時のハンドラー
    */
    const SpotDataUpdate = async() => {

        //Loadingが表示される
        setLoading(true);

        //観測値のデータをdata_allに入れます
        let data_all = []
        Object.keys(AreaClickDetail).map((Area)=>{
            data_all = data_all.concat(AreaClickDetail[Area])
        })


        
        try{
            //データを登録する
            const apiName          = "SafetyConfirmRest" ;
            const path             = "/updateParameterValue";
            const my_parameter_key = "Detected_Prefectures";
            const myInit           = {
                response             : true,
                queryStringParameters: {
                    "param_name" : my_parameter_key,
                    "param_value": JSON.stringify(data_all)
                }
            };
            
            await API.get(apiName, path, myInit)
            .then((data)=>{
                if(data.data.status === "error")
                {
                    throw new Error("API Internal Error");
                }
            }).catch((e)=>{
                throw new Error("API Invoke Error");
            })
            
        }
        catch(e){
            //Errorの表示
            console.error(e);
        }
        finally{
            //データベースから取得設定地震情報のデータを更新
            GetEarthQuaqeSettingSpot()
            //モーダルを閉じる
            Goback()
            //Loading画面を閉じる
            setLoading(false);
        }
    }

    /*
        概要：戻るボタンをクリックしたときの処理
    */
    const Goback = () => {
        setupdate(!update)
        setobservationSelectShow(false)
    }

    /*コンポーネント群**************************************************************************************************/

    //地方の詳細を表示する(県がリストで表示される)
    const AreaDetailList = (props) => {
        const {Area} = props;
        const ulid   = monotonicFactory();//ulidの生成

        /*
            概要：リストの県をクリックしたときに配列に入れていく
        */
        const AreaClickDetailChange = (id, value) =>{//
            //今現状の登録されてる変数の状態を知る。
            if(!AreaClickDetail[id].includes(value)){//クリックした県が含まれていなければ(追加)
                let array = AreaClickDetail[id]
                array.push(value)
                setAreaClickDetail({...AreaClickDetail, [id]: array})
            }
            else{//クリックした県が含まれていた場合(消す)
                let array = AreaClickDetail[id]
                let index = array.indexOf(value)
                array.splice(index, 1);
                setAreaClickDetail({...AreaClickDetail, [id]: array})
            }
        }

        /*
            概要：リスト内の県をクリックしたときに呼び出される関数
    　　*/
        const ClickPrefectureFunc = (Area, prefecture) => {
            if(!(AreaClickDetail[Area].length === LocalArea[Area].length)){//全選択されていないときの処理
                AreaClickDetailChange(Area, prefecture)
            }
            else{//全選択クリック後の処理
                setAreaClickDetail({...AreaClickDetail, [Area]: [prefecture]})
            }
        }
            
        if(AreaClickState.includes(Area)){
            return (
                <div>
                    {/*すべて選択のボタン*/}
                    <AreaAllSelectButton Area= {Area}/>
                    
                    {
                        LocalArea[Area].map((prefecture, index)=>{

                            //県が選択されているかどうかの判定
                            let ListPrefectureMyIconStatus;
                            let ListPrefectureStatus
                            if(PrefectureSelectJudge(Area, prefecture)){//選択されている場合
                                ListPrefectureMyIconStatus = {}
                                ListPrefectureStatus = "select"
                            }
                            else{//選択されていない場合
                                ListPrefectureMyIconStatus = {"display": "none"}
                                ListPrefectureStatus = "Default"
                            }

                            return(
                                <div key={ulid()}>
                                    {/*県がリストで表示される*/}
                                    <ListPrefecture
                                        overrides={
                                            {
                                                "text":{
                                                    children: prefecture
                                                },
                                                "MyIcon": ListPrefectureMyIconStatus,
                                            }
                                        }
                                        status = {ListPrefectureStatus}
                                        width = {"90%"}
                                        margin = {"auto"}
                                        onClick = {()=>{ClickPrefectureFunc(Area, prefecture)}}
                                    />
                                </div> 
                            )
                        })
                    }
                </div>
            )
        }
        else{
            return null;
        }
    }

    //地方の全選択ボタン
    const AreaAllSelectButton = (props) => {
        const {Area} = props;
        let ListPrefectureMyIconStatus;
        let ListPrefectureStatus
        if(AllSelectJudge(Area)){//全選択判定がtrue
            ListPrefectureMyIconStatus = {}
            ListPrefectureStatus = "select"
        }  
        else{//全選択判定がfalse
            ListPrefectureMyIconStatus = {"display": "none"}
            ListPrefectureStatus = "Default"
        }
        return (
            <div>
                <ListPrefecture
                    overrides={
                        {
                            "text":{
                                children: Area+"すべて選択"
                            },
                            "MyIcon": ListPrefectureMyIconStatus,
                        }
                    }
                    width = {"90%"}
                    margin = {"auto"}
                    status = {ListPrefectureStatus}
                    onClick = {()=>{AreaAllSelectFunc(Area)}}
                />
            </div>
        )
    }

    //地方毎にリストで表示する
    const AreaList = () => {
        const ulid = monotonicFactory();//ulidの生成

        return (
            Object.keys(LocalArea).map((Area)=>
                <div key={ulid()}>
                    {/*エリアを表示している部分*/}
                    <ListRegion
                        overrides={
                            {
                                "text":{
                                    children:  Area
                                },
                                "ListRegion":{
                                    onClick : () => OpenOrCloseButton(Area)
                                }
                            }
                        }
                        width = {"90%"}
                        margin = {"auto"}
                    />

                    <AreaDetailList
                        status = {true}
                        Area = {Area}
                    />
                </div>
            )
        )
    }

    return (
        <div style = {{width:"100%", height:"100vh", padding: "0",  display:"flex", flexDirection: "column",overflowY: "auto", backgroundColor: "#FFFFFF"}}>
            {/*観測地のヘッダーコンポーネント*/}
            <TopHeader
                clickFunc    = {()=>{Goback()}}
                status       = {"Kansokuchisentaku"}
                paddingTop   = {"16px"}
                width        = {"90vw"}
                marginTop    = {"0px"}
                marginLeft   = {"auto"}
                marginRight  = {"auto"}
                marginBottom = {"24px"}
                text         = {"観測地選択"}
            />

            {/*観測地エリア毎のリスト表示部分*/}
            <div style={{marginBottom:"24px"}}>
                <AreaList/> 
            </div>

            {/*観測地の決定ボタンコンポーネント*/}
            <div style = {{marginTop:"auto", marginBottom:"80px", width:"100%"}}>
                <MultiButton
                    CallBackFunc = {SpotDataUpdate}
                    omission = {false}
                    text     = {"登録"}
                    pattern  = {"default"}
                />
            </div>
        </div>
    );
});
