/*
  概要：404ページコンポーネント
*/
import { memo } from "react";
import { Link } from "react-router-dom";

export const Page404 = memo(() => {
    return(
      <div style={{marginLeft:"5%", paddingTop:"30px", height:"100vh"}}>
        <h1 style={{marginBottom:"30px"}}>ページが見つかりません</h1>
        <Link to="/">Topに戻る</Link>
      </div>
    );
});
