/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import ListMenu from "./ListMenu";
export default function Menu(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="260px"
      height="568px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Menu")}
    >
      <View
        width="240px"
        height="568px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.10000000149011612)"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 31")}
      ></View>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="80px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 2012")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2009")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Frame 162")}
          >
            <Flex
              gap="6px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 2")}
            >
              <MyIcon
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="user"
                {...getOverrideProps(overrides, "MyIcon")}
              ></MyIcon>
              <Text
                fontFamily=""
                fontSize="14px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="都築梨央"
                {...getOverrideProps(overrides, "\u90FD\u7BC9\u68A8\u592E")}
              ></Text>
              <Text
                fontFamily="YuGothic"
                fontSize="12px"
                fontWeight="700"
                color="rgba(0,0,0,1)"
                lineHeight="18px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="さん"
                {...getOverrideProps(overrides, "\u3055\u3093")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2011")}
        >
          <ListMenu
            display="flex"
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            property1="AccountSettings"
            {...getOverrideProps(overrides, "listMenu41013620")}
          ></ListMenu>
          <ListMenu
            display="flex"
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            property1="HowtoVoozy"
            {...getOverrideProps(overrides, "listMenu41013623")}
          ></ListMenu>
          <ListMenu
            display="flex"
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            property1="Feedback"
            {...getOverrideProps(overrides, "listMenu41013625")}
          ></ListMenu>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2010")}
        >
          <ListMenu
            display="flex"
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            property1="KanriSetup"
            {...getOverrideProps(overrides, "listMenu41013621")}
          ></ListMenu>
          <ListMenu
            display="flex"
            gap="10px"
            direction="column"
            width="240px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 32px 8px 32px"
            backgroundColor="rgba(255,255,255,1)"
            property1="anpikakuninkoumoku"
            {...getOverrideProps(overrides, "listMenu41013622")}
          ></ListMenu>
        </Flex>
        <ListMenu
          display="flex"
          gap="10px"
          direction="column"
          width="240px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="8px 32px 8px 32px"
          backgroundColor="rgba(255,255,255,1)"
          property1="SignOut"
          {...getOverrideProps(overrides, "listMenu41013624")}
        ></ListMenu>
      </Flex>
    </View>
  );
}
