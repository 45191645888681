/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Btn(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { text: {}, Btn: {} }, variantValues: { status: "Touroku" } },
    {
      overrides: {
        text: {
          letterSpacing: "3.05px",
          children: "\u30DB\u30FC\u30E0\u3078\u623B\u308B",
        },
        Btn: {},
      },
      variantValues: { status: "Home" },
    },
    {
      overrides: { text: { children: "\u6C7A\u5B9A" }, Btn: {} },
      variantValues: { status: "Kettei" },
    },
    {
      overrides: { text: { children: "\u524A\u9664" }, Btn: {} },
      variantValues: { status: "Sakujo" },
    },
    {
      overrides: {
        text: {
          color: "rgba(43,133,255,1)",
          children: "\u30AD\u30E3\u30F3\u30BB\u30EB",
        },
        Btn: {
          padding: "12px 23px 12px 23px",
          backgroundColor: "rgba(245,245,245,1)",
          border: "1px SOLID rgba(43,133,255,1)",
        },
      },
      variantValues: { status: "Cancel" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="4px"
      padding="13px 24px 13px 24px"
      backgroundColor="rgba(43,133,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Btn")}
    >
      <Text
        fontFamily=""
        fontSize="16px"
        fontWeight=""
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="1.45px"
        width="240px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="登録"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
