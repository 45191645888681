/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Icon } from "@aws-amplify/ui-react";
export default function MyIcon(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { MyIcon: {} }, variantValues: { type: "menu" } },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M6 8.25C6.86719 8.25 7.47656 7.85156 7.82812 7.47656C8.03906 7.24219 8.39062 7.21875 8.625 7.42969C8.85938 7.64062 8.85938 7.99219 8.64844 8.22656C8.13281 8.78906 7.24219 9.375 6 9.375C4.73438 9.375 3.84375 8.78906 3.32812 8.22656C3.11719 7.99219 3.11719 7.64062 3.35156 7.42969C3.58594 7.21875 3.9375 7.24219 4.14844 7.47656C4.5 7.85156 5.10938 8.25 6 8.25ZM4.875 4.875C4.875 5.29688 4.54688 5.625 4.125 5.625C3.70312 5.625 3.375 5.29688 3.375 4.875C3.375 4.47656 3.70312 4.125 4.125 4.125C4.54688 4.125 4.875 4.47656 4.875 4.875ZM7.125 4.875C7.125 4.47656 7.45312 4.125 7.875 4.125C8.29688 4.125 8.625 4.47656 8.625 4.875C8.625 5.29688 8.29688 5.625 7.875 5.625C7.45312 5.625 7.125 5.29688 7.125 4.875ZM12 6C12 9.32812 9.30469 12 6 12C2.67188 12 0 9.32812 0 6C0 2.69531 2.67188 0 6 0C9.30469 0 12 2.69531 12 6ZM6 1.125C3.30469 1.125 1.125 3.32812 1.125 6C1.125 8.69531 3.30469 10.875 6 10.875C8.67188 10.875 10.875 8.69531 10.875 6C10.875 3.32812 8.67188 1.125 6 1.125Z",
              fill: "rgba(1,185,133,1)",
              fillRule: "nonzero",
              style: { transform: "translate(7.14%, 7.14%)" },
            },
          ],
        },
      },
      variantValues: { type: "buji_g" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M6 8.25C6.86719 8.25 7.47656 7.85156 7.82812 7.47656C8.03906 7.24219 8.39062 7.21875 8.625 7.42969C8.85938 7.64062 8.85938 7.99219 8.64844 8.22656C8.13281 8.78906 7.24219 9.375 6 9.375C4.73438 9.375 3.84375 8.78906 3.32812 8.22656C3.11719 7.99219 3.11719 7.64062 3.35156 7.42969C3.58594 7.21875 3.9375 7.24219 4.14844 7.47656C4.5 7.85156 5.10938 8.25 6 8.25ZM4.875 4.875C4.875 5.29688 4.54688 5.625 4.125 5.625C3.70312 5.625 3.375 5.29688 3.375 4.875C3.375 4.47656 3.70312 4.125 4.125 4.125C4.54688 4.125 4.875 4.47656 4.875 4.875ZM7.125 4.875C7.125 4.47656 7.45312 4.125 7.875 4.125C8.29688 4.125 8.625 4.47656 8.625 4.875C8.625 5.29688 8.29688 5.625 7.875 5.625C7.45312 5.625 7.125 5.29688 7.125 4.875ZM12 6C12 9.32812 9.30469 12 6 12C2.67188 12 0 9.32812 0 6C0 2.69531 2.67188 0 6 0C9.30469 0 12 2.69531 12 6ZM6 1.125C3.30469 1.125 1.125 3.32812 1.125 6C1.125 8.69531 3.30469 10.875 6 10.875C8.67188 10.875 10.875 8.69531 10.875 6C10.875 3.32812 8.67188 1.125 6 1.125Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(7.14%, 7.14%)" },
            },
          ],
        },
      },
      variantValues: { type: "buji_w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M6.49219 0.28125C6.11719 0.117188 5.69531 0 5.25 0C3.98438 0 2.90625 0.796875 2.46094 1.875L4.35938 1.875L6.49219 0.28125ZM8.01562 1.875C7.82812 1.42969 7.52344 1.03125 7.17188 0.726562L5.625 1.875L8.01562 1.875ZM5.25 6C6.89062 6 8.25 4.66406 8.25 3C8.25 2.88281 8.22656 2.76562 8.20312 2.625L2.27344 2.625C2.25 2.76562 2.25 2.88281 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM6.375 9.75L5.29688 9.75L6.67969 11.9531C7.14844 11.8125 7.5 11.3906 7.5 10.875C7.5 10.2656 6.98438 9.75 6.375 9.75ZM6.42188 7.125L4.05469 7.125C3.91406 7.125 3.79688 7.14844 3.67969 7.14844L4.82812 9L6.375 9C7.40625 9 8.25 9.84375 8.25 10.875C8.25 11.3203 8.08594 11.6953 7.85156 12L9.67969 12C10.125 12 10.5 11.6484 10.5 11.2031C10.5 8.95312 8.67188 7.125 6.42188 7.125ZM0 11.2031C0 11.6484 0.351562 12 0.796875 12L1.5 12L1.5 8.03906C0.5625 8.78906 0 9.9375 0 11.2031ZM2.25 7.57031L2.25 12L5.83594 12L2.88281 7.3125C2.67188 7.38281 2.4375 7.45312 2.25 7.57031Z",
              fill: "rgba(255,76,108,1)",
              fillRule: "nonzero",
              style: { transform: "translate(14.29%, 7.14%)" },
            },
          ],
        },
      },
      variantValues: { type: "higai_r" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M6.49219 0.28125C6.11719 0.117188 5.69531 0 5.25 0C3.98438 0 2.90625 0.796875 2.46094 1.875L4.35938 1.875L6.49219 0.28125ZM8.01562 1.875C7.82812 1.42969 7.52344 1.03125 7.17188 0.726562L5.625 1.875L8.01562 1.875ZM5.25 6C6.89062 6 8.25 4.66406 8.25 3C8.25 2.88281 8.22656 2.76562 8.20312 2.625L2.27344 2.625C2.25 2.76562 2.25 2.88281 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM6.375 9.75L5.29688 9.75L6.67969 11.9531C7.14844 11.8125 7.5 11.3906 7.5 10.875C7.5 10.2656 6.98438 9.75 6.375 9.75ZM6.42188 7.125L4.05469 7.125C3.91406 7.125 3.79688 7.14844 3.67969 7.14844L4.82812 9L6.375 9C7.40625 9 8.25 9.84375 8.25 10.875C8.25 11.3203 8.08594 11.6953 7.85156 12L9.67969 12C10.125 12 10.5 11.6484 10.5 11.2031C10.5 8.95312 8.67188 7.125 6.42188 7.125ZM0 11.2031C0 11.6484 0.351562 12 0.796875 12L1.5 12L1.5 8.03906C0.5625 8.78906 0 9.9375 0 11.2031ZM2.25 7.57031L2.25 12L5.83594 12L2.88281 7.3125C2.67188 7.38281 2.4375 7.45312 2.25 7.57031Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(14.29%, 7.14%)" },
            },
          ],
        },
      },
      variantValues: { type: "higai_k" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M6.49219 0.28125C6.11719 0.117188 5.69531 0 5.25 0C3.98438 0 2.90625 0.796875 2.46094 1.875L4.35938 1.875L6.49219 0.28125ZM8.01562 1.875C7.82812 1.42969 7.52344 1.03125 7.17188 0.726562L5.625 1.875L8.01562 1.875ZM5.25 6C6.89062 6 8.25 4.66406 8.25 3C8.25 2.88281 8.22656 2.76562 8.20312 2.625L2.27344 2.625C2.25 2.76562 2.25 2.88281 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM6.375 9.75L5.29688 9.75L6.67969 11.9531C7.14844 11.8125 7.5 11.3906 7.5 10.875C7.5 10.2656 6.98438 9.75 6.375 9.75ZM6.42188 7.125L4.05469 7.125C3.91406 7.125 3.79688 7.14844 3.67969 7.14844L4.82812 9L6.375 9C7.40625 9 8.25 9.84375 8.25 10.875C8.25 11.3203 8.08594 11.6953 7.85156 12L9.67969 12C10.125 12 10.5 11.6484 10.5 11.2031C10.5 8.95312 8.67188 7.125 6.42188 7.125ZM0 11.2031C0 11.6484 0.351562 12 0.796875 12L1.5 12L1.5 8.03906C0.5625 8.78906 0 9.9375 0 11.2031ZM2.25 7.57031L2.25 12L5.83594 12L2.88281 7.3125C2.67188 7.38281 2.4375 7.45312 2.25 7.57031Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(14.29%, 7.14%)" },
            },
          ],
        },
      },
      variantValues: { type: "higai_w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M7 0C3.11719 0 0 3.14453 0 7C0 10.8828 3.11719 14 7 14C10.8555 14 14 10.8828 14 7C14 3.14453 10.8555 0 7 0ZM7 3.5C8.06641 3.5 8.96875 4.40234 8.96875 5.46875C8.96875 6.5625 8.06641 7.4375 7 7.4375C5.90625 7.4375 5.03125 6.5625 5.03125 5.46875C5.03125 4.40234 5.90625 3.5 7 3.5ZM7 12.25C5.55078 12.25 4.23828 11.6758 3.28125 10.7188C3.71875 9.57031 4.8125 8.75 6.125 8.75L7.875 8.75C9.16016 8.75 10.2539 9.57031 10.6914 10.7188C9.73438 11.6758 8.42188 12.25 7 12.25Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { type: "user" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M12.5125 4.52812C11.3313 1.8375 8.96875 0 6.3 0C3.60938 0 1.24688 1.8375 0.065625 4.52812C0.021875 4.6375 0 4.79063 0 4.9C0 5.00938 0.021875 5.18437 0.065625 5.29375C1.24688 7.98438 3.60938 9.8 6.3 9.8C8.96875 9.8 11.3313 7.98438 12.5125 5.29375C12.5562 5.18437 12.6 5.00938 12.6 4.9C12.6 4.79063 12.5562 4.6375 12.5125 4.52812ZM6.3 8.75C4.1125 8.75 2.1 7.28438 1.05 4.92188C2.12188 2.5375 4.1125 1.05 6.3 1.05C8.46562 1.05 10.4781 2.5375 11.5281 4.9C10.4563 7.28438 8.46562 8.75 6.3 8.75ZM6.3 2.1C4.74687 2.1 3.5 3.36875 3.5 4.9C3.5 6.45312 4.74687 7.7 6.3 7.7C7.83125 7.7 9.1 6.45312 9.1 4.92188C9.1 3.36875 7.83125 2.1 6.3 2.1ZM6.3 6.65C5.31563 6.65 4.55 5.88438 4.55 4.9C4.55 4.9 4.55 4.87813 4.55 4.85625C4.65938 4.9 4.76875 4.9 4.9 4.9C5.66563 4.9 6.3 4.2875 6.3 3.5C6.3 3.39062 6.27812 3.28125 6.23438 3.17188C6.25625 3.17188 6.27812 3.15 6.3 3.15C7.2625 3.15 8.05 3.9375 8.05 4.92188C8.05 5.88438 7.2625 6.65 6.3 6.65Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M12.5125 4.52812C11.3313 1.8375 8.96875 0 6.3 0C3.60938 0 1.24688 1.8375 0.065625 4.52812C0.021875 4.6375 0 4.79063 0 4.9C0 5.00938 0.021875 5.18437 0.065625 5.29375C1.24688 7.98438 3.60938 9.8 6.3 9.8C8.96875 9.8 11.3313 7.98438 12.5125 5.29375C12.5562 5.18437 12.6 5.00938 12.6 4.9C12.6 4.79063 12.5562 4.6375 12.5125 4.52812ZM6.3 8.75C4.1125 8.75 2.1 7.28438 1.05 4.92188C2.12188 2.5375 4.1125 1.05 6.3 1.05C8.46562 1.05 10.4781 2.5375 11.5281 4.9C10.4563 7.28438 8.46562 8.75 6.3 8.75ZM6.3 2.1C4.74687 2.1 3.5 3.36875 3.5 4.9C3.5 6.45312 4.74687 7.7 6.3 7.7C7.83125 7.7 9.1 6.45312 9.1 4.92188C9.1 3.36875 7.83125 2.1 6.3 2.1ZM6.3 6.65C5.31563 6.65 4.55 5.88438 4.55 4.9C4.55 4.9 4.55 4.87813 4.55 4.85625C4.65938 4.9 4.76875 4.9 4.9 4.9C5.66563 4.9 6.3 4.2875 6.3 3.5C6.3 3.39062 6.27812 3.28125 6.23438 3.17188C6.25625 3.17188 6.27812 3.15 6.3 3.15C7.2625 3.15 8.05 3.9375 8.05 4.92188C8.05 5.88438 7.2625 6.65 6.3 6.65Z",
              fill: "rgba(1,185,133,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye_g" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M12.5125 4.52812C11.3313 1.8375 8.96875 0 6.3 0C3.60938 0 1.24688 1.8375 0.065625 4.52812C0.021875 4.6375 0 4.79063 0 4.9C0 5.00938 0.021875 5.18437 0.065625 5.29375C1.24688 7.98438 3.60938 9.8 6.3 9.8C8.96875 9.8 11.3313 7.98438 12.5125 5.29375C12.5562 5.18437 12.6 5.00938 12.6 4.9C12.6 4.79063 12.5562 4.6375 12.5125 4.52812ZM6.3 8.75C4.1125 8.75 2.1 7.28438 1.05 4.92188C2.12188 2.5375 4.1125 1.05 6.3 1.05C8.46562 1.05 10.4781 2.5375 11.5281 4.9C10.4563 7.28438 8.46562 8.75 6.3 8.75ZM6.3 2.1C4.74687 2.1 3.5 3.36875 3.5 4.9C3.5 6.45312 4.74687 7.7 6.3 7.7C7.83125 7.7 9.1 6.45312 9.1 4.92188C9.1 3.36875 7.83125 2.1 6.3 2.1ZM6.3 6.65C5.31563 6.65 4.55 5.88438 4.55 4.9C4.55 4.9 4.55 4.87813 4.55 4.85625C4.65938 4.9 4.76875 4.9 4.9 4.9C5.66563 4.9 6.3 4.2875 6.3 3.5C6.3 3.39062 6.27812 3.28125 6.23438 3.17188C6.25625 3.17188 6.27812 3.15 6.3 3.15C7.2625 3.15 8.05 3.9375 8.05 4.92188C8.05 5.88438 7.2625 6.65 6.3 6.65Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye_w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M5.98125 6C7.06875 6 7.93125 5.1375 7.93125 4.05C7.93125 2.98125 7.05 2.1 5.98125 2.1C4.9125 2.1 4.05 2.98125 4.05 4.05C4.03125 5.1375 4.9125 6 5.98125 6ZM6.91875 6.6L5.0625 6.6C3.58125 6.6 2.4 7.725 2.4 9.1125C2.4 9.39375 2.625 9.6 2.925 9.6L9.05625 9.6C9.35625 9.6 9.6 9.39375 9.6 9.1125C9.6 7.725 8.4 6.6 6.91875 6.6ZM9.6 3C10.425 3 11.1 2.34375 11.1 1.5C11.1 0.675 10.425 0 9.6 0C8.75625 0 8.1 0.675 8.1 1.5C8.1 2.34375 8.75625 3 9.6 3ZM3.43125 4.05C3.43125 3.95625 3.45 3.8625 3.46875 3.76875C3.2625 3.65625 3.0375 3.6 2.79375 3.6L1.65 3.6C0.73125 3.6 0 4.3875 0 5.3625C0 5.55 0.13125 5.7 0.31875 5.7L4.06875 5.7C3.675 5.26875 3.43125 4.6875 3.43125 4.05ZM2.4 3C3.225 3 3.9 2.34375 3.9 1.5C3.9 0.675 3.225 0 2.4 0C1.55625 0 0.9 0.675 0.9 1.5C0.9 2.34375 1.55625 3 2.4 3ZM10.3313 3.6L9.1875 3.6C8.94375 3.6 8.71875 3.675 8.5125 3.76875C8.5125 3.8625 8.53125 3.95625 8.53125 4.05C8.53125 4.6875 8.30625 5.26875 7.9125 5.7L11.6625 5.7C11.85 5.7 12 5.55 12 5.3625C12 4.3875 11.25 3.6 10.3313 3.6Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1.4px)" },
            },
          ],
        },
      },
      variantValues: { type: "higai-family" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M5.98125 6C7.06875 6 7.93125 5.1375 7.93125 4.05C7.93125 2.98125 7.05 2.1 5.98125 2.1C4.9125 2.1 4.05 2.98125 4.05 4.05C4.03125 5.1375 4.9125 6 5.98125 6ZM6.91875 6.6L5.0625 6.6C3.58125 6.6 2.4 7.725 2.4 9.1125C2.4 9.39375 2.625 9.6 2.925 9.6L9.05625 9.6C9.35625 9.6 9.6 9.39375 9.6 9.1125C9.6 7.725 8.4 6.6 6.91875 6.6ZM9.6 3C10.425 3 11.1 2.34375 11.1 1.5C11.1 0.675 10.425 0 9.6 0C8.75625 0 8.1 0.675 8.1 1.5C8.1 2.34375 8.75625 3 9.6 3ZM3.43125 4.05C3.43125 3.95625 3.45 3.8625 3.46875 3.76875C3.2625 3.65625 3.0375 3.6 2.79375 3.6L1.65 3.6C0.73125 3.6 0 4.3875 0 5.3625C0 5.55 0.13125 5.7 0.31875 5.7L4.06875 5.7C3.675 5.26875 3.43125 4.6875 3.43125 4.05ZM2.4 3C3.225 3 3.9 2.34375 3.9 1.5C3.9 0.675 3.225 0 2.4 0C1.55625 0 0.9 0.675 0.9 1.5C0.9 2.34375 1.55625 3 2.4 3ZM10.3313 3.6L9.1875 3.6C8.94375 3.6 8.71875 3.675 8.5125 3.76875C8.5125 3.8625 8.53125 3.95625 8.53125 4.05C8.53125 4.6875 8.30625 5.26875 7.9125 5.7L11.6625 5.7C11.85 5.7 12 5.55 12 5.3625C12 4.3875 11.25 3.6 10.3313 3.6Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1.4px)" },
            },
          ],
        },
      },
      variantValues: { type: "higai-family-w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M13.7925 10.2788L11.54 8.48546C12.2179 7.8075 12.8084 6.95458 13.2239 5.99231C13.2677 5.90483 13.2895 5.72987 13.2895 5.62052C13.2895 5.51118 13.2677 5.35809 13.2239 5.24874C12.043 2.55876 9.68103 0.743571 7.01292 0.743571C5.63513 0.743571 4.34482 1.22471 3.2732 2.05576L0.845657 0.131218C0.758178 0.0437395 0.64883 0 0.539481 0C0.364523 0 0.211435 0.087479 0.123956 0.218697C-0.0728718 0.437395 -0.0291323 0.765441 0.211435 0.940399L13.1365 11.0661C13.3552 11.2629 13.6832 11.2192 13.8582 10.9786C14.0769 10.7818 14.0331 10.4537 13.7925 10.2788ZM8.63129 6.21101L6.83797 4.83321C6.92545 4.63639 6.99105 4.43956 6.99105 4.19899C6.99105 4.08964 6.96918 3.98029 6.92545 3.87094C6.96918 3.87094 6.99105 3.84907 7.01292 3.84907C7.97519 3.84907 8.7625 4.63639 8.7625 5.62052C8.7625 5.81735 8.69689 6.03605 8.63129 6.21101ZM9.48421 6.88897C9.68103 6.49531 9.81225 6.07979 9.81225 5.62052C9.81225 4.06777 8.54381 2.79933 7.01292 2.79933C6.29122 2.79933 5.657 3.08363 5.17587 3.49916L4.12612 2.68998C5.00091 2.0995 5.98505 1.74958 7.01292 1.74958C9.17803 1.74958 11.19 3.23672 12.2398 5.59865C11.8461 6.47344 11.3213 7.21701 10.687 7.8075L9.48421 6.88897ZM7.01292 8.39798C7.29723 8.39798 7.58154 8.35424 7.84398 8.26676L6.61927 7.30449C6.11626 7.19515 5.67887 6.84523 5.4383 6.38596L4.2136 5.4237C4.2136 5.48931 4.2136 5.55491 4.2136 5.59865C4.2136 7.15141 5.46017 8.39798 7.01292 8.39798ZM7.01292 9.44773C4.82595 9.44773 2.81393 7.98246 1.76419 5.62052C2.00475 5.09565 2.28906 4.61452 2.61711 4.19899L1.78606 3.52103C1.3924 4.0459 1.06435 4.61452 0.780048 5.22687C0.736309 5.33622 0.692569 5.48931 0.692569 5.59865C0.692569 5.708 0.714439 5.88296 0.758178 5.99231C1.93914 8.68229 4.30108 10.4975 6.99105 10.4975C7.97519 10.4975 8.91559 10.235 9.79038 9.77577L8.84998 9.05407C8.28137 9.31651 7.64715 9.44773 7.01292 9.44773Z",
              fill: "rgba(255,76,108,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye-slash_r" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M13.7925 10.2788L11.54 8.48546C12.2179 7.8075 12.8084 6.95458 13.2239 5.99231C13.2677 5.90483 13.2895 5.72987 13.2895 5.62052C13.2895 5.51117 13.2677 5.35809 13.2239 5.24874C12.043 2.55876 9.68103 0.743571 7.01292 0.743571C5.63513 0.743571 4.34482 1.22471 3.2732 2.05576L0.845657 0.131218C0.758178 0.0437395 0.64883 0 0.539481 0C0.364523 0 0.211435 0.087479 0.123956 0.218697C-0.0728718 0.437395 -0.0291323 0.765441 0.211435 0.940399L13.1365 11.0661C13.3551 11.2629 13.6832 11.2192 13.8582 10.9786C14.0769 10.7818 14.0331 10.4537 13.7925 10.2788ZM8.63128 6.21101L6.83797 4.83321C6.92545 4.63638 6.99105 4.43956 6.99105 4.19899C6.99105 4.08964 6.96918 3.98029 6.92545 3.87094C6.96918 3.87094 6.99105 3.84907 7.01292 3.84907C7.97519 3.84907 8.7625 4.63638 8.7625 5.62052C8.7625 5.81735 8.69689 6.03605 8.63128 6.21101ZM9.4842 6.88897C9.68103 6.49531 9.81225 6.07979 9.81225 5.62052C9.81225 4.06777 8.54381 2.79933 7.01292 2.79933C6.29122 2.79933 5.657 3.08363 5.17587 3.49916L4.12612 2.68998C5.00091 2.0995 5.98505 1.74958 7.01292 1.74958C9.17803 1.74958 11.19 3.23672 12.2398 5.59865C11.8461 6.47344 11.3213 7.21701 10.687 7.8075L9.4842 6.88897ZM7.01292 8.39798C7.29723 8.39798 7.58154 8.35424 7.84397 8.26676L6.61927 7.30449C6.11626 7.19515 5.67887 6.84523 5.4383 6.38596L4.2136 5.4237C4.2136 5.48931 4.2136 5.55491 4.2136 5.59865C4.2136 7.15141 5.46017 8.39798 7.01292 8.39798ZM7.01292 9.44773C4.82595 9.44773 2.81393 7.98246 1.76419 5.62052C2.00475 5.09565 2.28906 4.61452 2.61711 4.19899L1.78606 3.52103C1.3924 4.0459 1.06435 4.61452 0.780048 5.22687C0.736309 5.33622 0.692569 5.48931 0.692569 5.59865C0.692569 5.708 0.714439 5.88296 0.758178 5.99231C1.93914 8.68229 4.30108 10.4975 6.99105 10.4975C7.97519 10.4975 8.91559 10.235 9.79038 9.77577L8.84998 9.05407C8.28137 9.31651 7.64715 9.44773 7.01292 9.44773Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye-slash_w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M11.9969 5.34121C11.9969 5.71676 11.6839 6.00886 11.3292 6.00886L10.6615 6.00886L10.6824 9.34711C10.6824 10.0982 10.0774 10.6824 9.34711 10.6824L6.80169 10.6824L6.00886 9.34711L7.67798 7.94922C7.8449 7.82403 7.8449 7.57366 7.67798 7.42761L5.21602 5.50812C4.90306 5.25775 4.50664 5.65417 4.73615 5.96713L6.00886 7.67798L4.21455 9.1802C4.08936 9.28452 4.04763 9.45143 4.15195 9.59748L4.79874 10.6824L2.6706 10.6824C1.9195 10.6824 1.3353 10.0982 1.3353 9.34711L1.3353 6.00886L0.667651 6.00886C0.292097 6.00886 0 5.71676 0 5.34121C0 5.15343 0.0625923 4.98652 0.208641 4.84047L5.54985 0.187777C5.6959 0.0417282 5.86281 0 6.00886 0C6.15491 0 6.32182 0.0625923 6.447 0.166913L8.67946 2.10727L8.67946 1.3353C8.67946 0.980612 8.97156 0.667651 9.34711 0.667651L10.0148 0.667651C10.3695 0.667651 10.6824 0.980612 10.6824 1.3353L10.6824 3.85986L11.7673 4.84047C11.9343 4.98652 12.0177 5.15343 11.9969 5.34121Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0.8px)" },
            },
          ],
        },
      },
      variantValues: { type: "higai-house" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M11.9969 5.34121C11.9969 5.71676 11.6839 6.00886 11.3292 6.00886L10.6615 6.00886L10.6824 9.34711C10.6824 10.0982 10.0774 10.6824 9.34711 10.6824L6.80169 10.6824L6.00886 9.34711L7.67798 7.94922C7.8449 7.82403 7.8449 7.57366 7.67798 7.42761L5.21602 5.50812C4.90306 5.25775 4.50664 5.65417 4.73615 5.96713L6.00886 7.67798L4.21455 9.1802C4.08936 9.28452 4.04763 9.45143 4.15195 9.59748L4.79874 10.6824L2.6706 10.6824C1.9195 10.6824 1.3353 10.0982 1.3353 9.34711L1.3353 6.00886L0.667651 6.00886C0.292097 6.00886 0 5.71676 0 5.34121C0 5.15343 0.0625923 4.98652 0.208641 4.84047L5.54985 0.187777C5.6959 0.0417282 5.86281 0 6.00886 0C6.15491 0 6.32182 0.0625923 6.447 0.166913L8.67946 2.10727L8.67946 1.3353C8.67946 0.980612 8.97156 0.667651 9.34711 0.667651L10.0148 0.667651C10.3695 0.667651 10.6824 0.980612 10.6824 1.3353L10.6824 3.85986L11.7673 4.84047C11.9343 4.98652 12.0177 5.15343 11.9969 5.34121Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0.8px)" },
            },
          ],
        },
      },
      variantValues: { type: "higai-house-w" },
    },
    {
      overrides: {
        MyIcon: {
          width: "14px",
          height: "14px",
          viewBox: { minX: 0, minY: 0, width: 14, height: 14 },
          paths: [
            {
              d: "M13.7925 10.2788L11.54 8.48546C12.2179 7.8075 12.8084 6.95458 13.2239 5.99231C13.2677 5.90483 13.2895 5.72987 13.2895 5.62052C13.2895 5.51118 13.2677 5.35809 13.2239 5.24874C12.043 2.55876 9.68103 0.743571 7.01292 0.743571C5.63513 0.743571 4.34482 1.22471 3.2732 2.05576L0.845657 0.131218C0.758178 0.0437395 0.64883 0 0.539481 0C0.364523 0 0.211435 0.087479 0.123956 0.218697C-0.0728718 0.437395 -0.0291323 0.765441 0.211435 0.940399L13.1365 11.0661C13.3552 11.2629 13.6832 11.2192 13.8582 10.9786C14.0769 10.7818 14.0331 10.4537 13.7925 10.2788ZM8.63129 6.21101L6.83797 4.83321C6.92545 4.63639 6.99105 4.43956 6.99105 4.19899C6.99105 4.08964 6.96918 3.98029 6.92545 3.87094C6.96918 3.87094 6.99105 3.84907 7.01292 3.84907C7.97519 3.84907 8.7625 4.63639 8.7625 5.62052C8.7625 5.81735 8.69689 6.03605 8.63129 6.21101ZM9.48421 6.88897C9.68103 6.49531 9.81225 6.07979 9.81225 5.62052C9.81225 4.06777 8.54381 2.79933 7.01292 2.79933C6.29122 2.79933 5.657 3.08363 5.17587 3.49916L4.12612 2.68998C5.00091 2.0995 5.98505 1.74958 7.01292 1.74958C9.17803 1.74958 11.19 3.23672 12.2398 5.59865C11.8461 6.47344 11.3213 7.21701 10.687 7.8075L9.48421 6.88897ZM7.01292 8.39798C7.29723 8.39798 7.58154 8.35424 7.84398 8.26676L6.61927 7.30449C6.11626 7.19515 5.67887 6.84523 5.4383 6.38596L4.2136 5.4237C4.2136 5.48931 4.2136 5.55491 4.2136 5.59865C4.2136 7.15141 5.46017 8.39798 7.01292 8.39798ZM7.01292 9.44773C4.82595 9.44773 2.81393 7.98246 1.76419 5.62052C2.00475 5.09565 2.28906 4.61452 2.61711 4.19899L1.78606 3.52103C1.3924 4.0459 1.06435 4.61452 0.780048 5.22687C0.736309 5.33622 0.692569 5.48931 0.692569 5.59865C0.692569 5.708 0.714439 5.88296 0.758178 5.99231C1.93914 8.68229 4.30108 10.4975 6.99105 10.4975C7.97519 10.4975 8.91559 10.235 9.79038 9.77577L8.84998 9.05407C8.28137 9.31651 7.64715 9.44773 7.01292 9.44773Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "eye-slash" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6.01402 0C2.68589 0 0.0140174 2.20312 0.0140174 4.875C0.0140174 6.04688 0.506205 7.10156 1.32652 7.94531C1.04527 9.14062 0.0608924 10.1719 0.0608924 10.1953C-0.00942009 10.2422 -0.00942009 10.3359 0.0140174 10.4062C0.0374549 10.4766 0.107767 10.5 0.201517 10.5C1.74839 10.5 2.89683 9.77344 3.48277 9.30469C4.2562 9.58594 5.09996 9.75 6.01402 9.75C9.3187 9.75 11.9906 7.57031 11.9906 4.875C11.9906 2.20312 9.3187 0 6.01402 0Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "comment_b" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6.01402 0C2.68589 0 0.0140174 2.20312 0.0140174 4.875C0.0140174 6.04688 0.506205 7.10156 1.32652 7.94531C1.04527 9.14062 0.0608924 10.1719 0.0608924 10.1953C-0.00942009 10.2422 -0.00942009 10.3359 0.0140174 10.4062C0.0374549 10.4766 0.107767 10.5 0.201517 10.5C1.74839 10.5 2.89683 9.77344 3.48277 9.30469C4.2562 9.58594 5.09996 9.75 6.01402 9.75C9.3187 9.75 11.9906 7.57031 11.9906 4.875C11.9906 2.20312 9.3187 0 6.01402 0Z",
              fill: "rgba(224,224,224,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "comment_g" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M10.5 0.75C10.5 0.960938 10.4062 1.14844 10.2656 1.28906L4.26562 7.28906C4.125 7.42969 3.9375 7.5 3.75 7.5C3.53906 7.5 3.35156 7.42969 3.21094 7.28906L0.210938 4.28906C0.0703125 4.14844 0 3.96094 0 3.75C0 3.32812 0.328125 3 0.75 3C0.9375 3 1.125 3.09375 1.26562 3.23438L3.75 5.69531L9.21094 0.234375C9.35156 0.09375 9.53906 0 9.75 0C10.1484 0 10.5 0.328125 10.5 0.75Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "check" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6 0C2.67188 0 0 2.69531 0 6C0 9.32812 2.67188 12 6 12C9.30469 12 12 9.32812 12 6C12 2.69531 9.30469 0 6 0ZM8.25 6.5625L6.5625 6.5625L6.5625 8.25C6.5625 8.57812 6.30469 8.8125 6 8.8125C5.67188 8.8125 5.4375 8.57812 5.4375 8.25L5.4375 6.5625L3.75 6.5625C3.42188 6.5625 3.1875 6.32812 3.1875 6C3.1875 5.69531 3.42188 5.4375 3.75 5.4375L5.4375 5.4375L5.4375 3.75C5.4375 3.44531 5.67188 3.1875 6 3.1875C6.30469 3.1875 6.5625 3.44531 6.5625 3.75L6.5625 5.4375L8.25 5.4375C8.55469 5.4375 8.8125 5.69531 8.8125 6C8.8125 6.32812 8.55469 6.5625 8.25 6.5625Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "circle-plus" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M10.125 1.875L8.17969 1.875L7.38281 0.5625C7.17188 0.210938 6.82031 0 6.42188 0L4.05469 0C3.65625 0 3.30469 0.210938 3.09375 0.5625L2.29688 1.875L0.375 1.875C0.164062 1.875 0 2.0625 0 2.25L0 2.625C0 2.83594 0.164062 3 0.375 3L0.75 3L0.75 10.5C0.75 11.3438 1.40625 12 2.25 12L8.25 12C9.07031 12 9.75 11.3438 9.75 10.5L9.75 3L10.125 3C10.3125 3 10.5 2.83594 10.5 2.625L10.5 2.25C10.5 2.0625 10.3125 1.875 10.125 1.875ZM4.00781 1.19531C4.03125 1.17188 4.07812 1.125 4.14844 1.125L6.35156 1.125C6.39844 1.125 6.44531 1.17188 6.46875 1.19531L6.86719 1.875L3.60938 1.875L4.00781 1.19531ZM8.25 10.875L2.25 10.875C2.03906 10.875 1.875 10.7109 1.875 10.5L1.875 3L8.625 3L8.625 10.5C8.625 10.7109 8.4375 10.875 8.25 10.875ZM5.25 9.75C5.4375 9.75 5.625 9.58594 5.625 9.375L5.625 4.5C5.625 4.3125 5.4375 4.125 5.25 4.125C5.03906 4.125 4.875 4.3125 4.875 4.5L4.875 9.375C4.875 9.58594 5.03906 9.75 5.25 9.75ZM3.375 9.75C3.5625 9.75 3.75 9.58594 3.75 9.375L3.75 4.5C3.75 4.3125 3.5625 4.125 3.375 4.125C3.16406 4.125 3 4.3125 3 4.5L3 9.375C3 9.58594 3.16406 9.75 3.375 9.75ZM7.125 9.75C7.3125 9.75 7.5 9.58594 7.5 9.375L7.5 4.5C7.5 4.3125 7.3125 4.125 7.125 4.125C6.91406 4.125 6.75 4.3125 6.75 4.5L6.75 9.375C6.75 9.58594 6.91406 9.75 7.125 9.75Z",
              fill: "rgba(255,76,108,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0.75px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "trash" },
    },
    {
      overrides: {
        MyIcon: {
          width: "9px",
          height: "9px",
          viewBox: { minX: 0, minY: 0, width: 9, height: 9 },
          paths: [
            {
              d: "M8.49707 7.2666C8.85254 7.59473 8.85254 8.16895 8.49707 8.49707C8.33301 8.66113 8.11426 8.74316 7.89551 8.74316C7.64941 8.74316 7.43066 8.66113 7.2666 8.49707L4.39551 5.62598L1.49707 8.49707C1.33301 8.66113 1.11426 8.74316 0.895508 8.74316C0.649414 8.74316 0.430664 8.66113 0.266602 8.49707C-0.0888672 8.16895 -0.0888672 7.59473 0.266602 7.2666L3.1377 4.36816L0.266602 1.49707C-0.0888672 1.16895 -0.0888672 0.594727 0.266602 0.266602C0.594727 -0.0888672 1.16895 -0.0888672 1.49707 0.266602L4.39551 3.1377L7.2666 0.266602C7.59473 -0.0888672 8.16895 -0.0888672 8.49707 0.266602C8.85254 0.594727 8.85254 1.16895 8.49707 1.49707L5.62598 4.39551L8.49707 7.2666Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "xmark" },
    },
    {
      overrides: {
        MyIcon: {
          width: "9px",
          height: "9px",
          viewBox: { minX: 0, minY: 0, width: 9, height: 9 },
          paths: [],
        },
      },
      variantValues: { type: "xmark02" },
    },
    {
      overrides: {
        MyIcon: {
          width: "10px",
          height: "6px",
          viewBox: { minX: 0, minY: 0, width: 10, height: 6 },
          paths: [
            {
              d: "M4.51758 5.24414C4.30664 5.24414 4.11914 5.17383 3.97852 5.0332L0.228516 1.2832C-0.0761719 1.00195 -0.0761719 0.509766 0.228516 0.228516C0.509766 -0.0761719 1.00195 -0.0761719 1.2832 0.228516L4.51758 3.43945L7.72852 0.228516C8.00977 -0.0761719 8.50195 -0.0761719 8.7832 0.228516C9.08789 0.509766 9.08789 1.00195 8.7832 1.2832L5.0332 5.0332C4.89258 5.17383 4.70508 5.24414 4.51758 5.24414Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0.5px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "angle-down" },
    },
    {
      overrides: {
        MyIcon: {
          width: "10px",
          height: "6px",
          viewBox: { minX: 0, minY: 0, width: 10, height: 6 },
          paths: [
            {
              d: "M8.26758 5.24414C8.05664 5.24414 7.86914 5.17383 7.72852 5.0332L4.51758 1.82227L1.2832 5.0332C1.00195 5.33789 0.509766 5.33789 0.228516 5.0332C-0.0761719 4.75195 -0.0761719 4.25977 0.228516 3.97852L3.97852 0.228516C4.25977 -0.0761719 4.75195 -0.0761719 5.0332 0.228516L8.7832 3.97852C9.08789 4.25977 9.08789 4.75195 8.7832 5.0332C8.64258 5.17383 8.45508 5.24414 8.26758 5.24414Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0.5px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "angle-up" },
    },
    {
      overrides: {
        MyIcon: {
          width: "7px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 7, height: 12 },
          paths: [
            {
              d: "M5.27051 10.4932C5.02441 10.4932 4.80566 10.4111 4.6416 10.2471L0.266602 5.87207C-0.0888672 5.54395 -0.0888672 4.96973 0.266602 4.6416L4.6416 0.266602C4.96973 -0.0888672 5.54395 -0.0888672 5.87207 0.266602C6.22754 0.594727 6.22754 1.16895 5.87207 1.49707L2.12598 5.24316L5.87207 9.0166C6.22754 9.34473 6.22754 9.91895 5.87207 10.2471C5.70801 10.4111 5.48926 10.4932 5.27051 10.4932Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: {
                transform: "translate(0px, calc(50% - 5.25px - -0.25px))",
              },
            },
          ],
        },
      },
      variantValues: { type: "angle-left" },
    },
    {
      overrides: {
        MyIcon: {
          width: "32px",
          height: "32px",
          viewBox: { minX: 0, minY: 0, width: 32, height: 32 },
          paths: [
            {
              d: "M16 0C7.125 0 0 7.1875 0 16C0 24.875 7.125 32 16 32C24.8125 32 32 24.875 32 16C32 7.1875 24.8125 0 16 0ZM23.1875 13.25L15.1875 21.25C14.875 21.625 14.4375 21.75 14 21.75C13.5 21.75 13.0625 21.625 12.75 21.25L8.75 17.25C8.0625 16.5625 8.0625 15.5 8.75 14.8125C9.4375 14.125 10.5 14.125 11.1875 14.8125L14 17.5625L20.75 10.8125C21.4375 10.125 22.5 10.125 23.1875 10.8125C23.875 11.5 23.875 12.5625 23.1875 13.25Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "circle-check" },
    },
    {
      overrides: {
        MyIcon: {
          width: "10px",
          height: "10px",
          viewBox: { minX: 0, minY: 0, width: 10, height: 10 },
          paths: [
            {
              d: "M8.4375 1.5625L6.81641 1.5625L6.15234 0.46875C5.97656 0.175781 5.68359 0 5.35156 0L3.37891 0C3.04688 0 2.75391 0.175781 2.57812 0.46875L1.91406 1.5625L0.3125 1.5625C0.136719 1.5625 0 1.71875 0 1.875L0 2.1875C0 2.36328 0.136719 2.5 0.3125 2.5L0.625 2.5L0.625 8.75C0.625 9.45312 1.17188 10 1.875 10L6.875 10C7.55859 10 8.125 9.45312 8.125 8.75L8.125 2.5L8.4375 2.5C8.59375 2.5 8.75 2.36328 8.75 2.1875L8.75 1.875C8.75 1.71875 8.59375 1.5625 8.4375 1.5625ZM3.33984 0.996094C3.35938 0.976562 3.39844 0.9375 3.45703 0.9375L5.29297 0.9375C5.33203 0.9375 5.37109 0.976562 5.39062 0.996094L5.72266 1.5625L3.00781 1.5625L3.33984 0.996094ZM6.875 9.0625L1.875 9.0625C1.69922 9.0625 1.5625 8.92578 1.5625 8.75L1.5625 2.5L7.1875 2.5L7.1875 8.75C7.1875 8.92578 7.03125 9.0625 6.875 9.0625ZM4.375 8.125C4.53125 8.125 4.6875 7.98828 4.6875 7.8125L4.6875 3.75C4.6875 3.59375 4.53125 3.4375 4.375 3.4375C4.19922 3.4375 4.0625 3.59375 4.0625 3.75L4.0625 7.8125C4.0625 7.98828 4.19922 8.125 4.375 8.125ZM2.8125 8.125C2.96875 8.125 3.125 7.98828 3.125 7.8125L3.125 3.75C3.125 3.59375 2.96875 3.4375 2.8125 3.4375C2.63672 3.4375 2.5 3.59375 2.5 3.75L2.5 7.8125C2.5 7.98828 2.63672 8.125 2.8125 8.125ZM5.9375 8.125C6.09375 8.125 6.25 7.98828 6.25 7.8125L6.25 3.75C6.25 3.59375 6.09375 3.4375 5.9375 3.4375C5.76172 3.4375 5.625 3.59375 5.625 3.75L5.625 7.8125C5.625 7.98828 5.76172 8.125 5.9375 8.125Z",
              fill: "rgba(255,76,108,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0.25px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "trash02" },
    },
    {
      overrides: {
        MyIcon: {
          width: "15px",
          height: "5px",
          viewBox: { minX: 0, minY: 0, width: 15, height: 5 },
          paths: [
            {
              d: "M10.25 1.5L0.75 1.5C0.328125 1.5 0 1.17188 0 0.75C0 0.351562 0.328125 0 0.75 0L10.25 0C10.6484 0 11 0.351562 11 0.75C11 1.17188 10.6484 1.5 10.25 1.5Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(2px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "line" },
    },
    {
      overrides: {
        MyIcon: {
          width: "15px",
          height: "5px",
          viewBox: { minX: 0, minY: 0, width: 15, height: 5 },
          paths: [
            {
              d: "M10.25 1.5L0.75 1.5C0.328125 1.5 0 1.17188 0 0.75C0 0.351562 0.328125 0 0.75 0L10.25 0C10.6484 0 11 0.351562 11 0.75C11 1.17188 10.6484 1.5 10.25 1.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(2px, 2px)" },
            },
          ],
        },
      },
      variantValues: { type: "line-white" },
    },
    {
      overrides: {
        MyIcon: {
          width: "10px",
          height: "10px",
          viewBox: { minX: 0, minY: 0, width: 10, height: 10 },
          paths: [
            {
              d: "M9.66797 0.976562L9.02344 0.332031C8.80859 0.117188 8.53516 0 8.26172 0C7.96875 0 7.69531 0.117188 7.48047 0.332031L3.26172 4.55078C3.10547 4.6875 3.00781 4.88281 2.96875 5.07812L2.5 7.1875C2.46094 7.36328 2.59766 7.5 2.75391 7.5C2.77344 7.5 2.77344 7.5 2.79297 7.5C2.79297 7.5 4.23828 7.20703 4.90234 7.05078C5.09766 6.99219 5.27344 6.89453 5.41016 6.75781L9.64844 2.51953C10.0977 2.08984 10.0977 1.40625 9.66797 0.976562ZM9.00391 1.85547L4.76562 6.09375C4.74609 6.11328 4.72656 6.13281 4.70703 6.13281C4.43359 6.19141 4.02344 6.28906 3.63281 6.36719L3.88672 5.29297C3.88672 5.25391 3.90625 5.23438 3.92578 5.21484L8.14453 0.996094C8.18359 0.957031 8.22266 0.9375 8.26172 0.9375C8.28125 0.9375 8.32031 0.957031 8.35938 0.996094L9.00391 1.64062C9.0625 1.69922 9.0625 1.79688 9.00391 1.85547ZM8.28125 5.625C8.00781 5.625 7.8125 5.83984 7.8125 6.09375L7.8125 8.59375C7.8125 8.86719 7.59766 9.0625 7.34375 9.0625L1.40625 9.0625C1.13281 9.0625 0.9375 8.86719 0.9375 8.59375L0.9375 2.65625C0.9375 2.40234 1.13281 2.1875 1.40625 2.1875L4.21875 2.1875C4.47266 2.1875 4.6875 1.99219 4.6875 1.71875C4.6875 1.46484 4.47266 1.25 4.21875 1.25L1.38672 1.25C0.625 1.25 0 1.89453 0 2.65625L0 8.59375C0 9.375 0.625 10 1.38672 10L7.30469 10C8.06641 10 8.69141 9.375 8.69141 8.59375L8.75 6.09375C8.75 5.83984 8.53516 5.625 8.28125 5.625Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "edit" },
    },
    {
      overrides: {
        MyIcon: {
          width: "11px",
          height: "7px",
          viewBox: { minX: 0, minY: 0, width: 11, height: 7 },
          paths: [
            {
              d: "M5.27051 6.11816C5.02441 6.11816 4.80566 6.03613 4.6416 5.87207L0.266602 1.49707C-0.0888672 1.16895 -0.0888672 0.594727 0.266602 0.266602C0.594727 -0.0888672 1.16895 -0.0888672 1.49707 0.266602L5.27051 4.0127L9.0166 0.266602C9.34473 -0.0888672 9.91895 -0.0888672 10.2471 0.266602C10.6025 0.594727 10.6025 1.16895 10.2471 1.49707L5.87207 5.87207C5.70801 6.03613 5.48926 6.11816 5.27051 6.11816Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "angle-down02" },
    },
    {
      overrides: {
        MyIcon: {
          width: "16px",
          height: "16px",
          viewBox: { minX: 0, minY: 0, width: 16, height: 16 },
          paths: [
            {
              d: "M15.2803 0.21967C15.5732 0.512563 15.5732 0.987437 15.2803 1.28033L1.28033 15.2803C0.987437 15.5732 0.512563 15.5732 0.21967 15.2803C-0.0732233 14.9874 -0.0732233 14.5126 0.21967 14.2197L14.2197 0.21967C14.5126 -0.0732233 14.9874 -0.0732233 15.2803 0.21967Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "evenodd",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { type: "slash" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M11.4141 7.78125C11.4141 7.66406 11.3438 7.52344 11.2266 7.45312L10.125 6.84375C10.1719 6.5625 10.2188 6.28125 10.2188 6C10.2188 5.74219 10.1719 5.46094 10.125 5.17969L11.2266 4.57031C11.3438 4.5 11.4141 4.35938 11.4141 4.21875C11.4141 3.89062 10.4297 1.89844 9.91406 1.89844C9.84375 1.89844 9.77344 1.92188 9.72656 1.96875L8.625 2.60156C8.20312 2.22656 7.73438 1.96875 7.21875 1.78125L7.21875 0.515625C7.21875 0.328125 7.07812 0.164062 6.91406 0.140625C6.51562 0.046875 6.11719 0 5.71875 0C5.29688 0 4.89844 0.046875 4.5 0.140625C4.33594 0.1875 4.21875 0.328125 4.21875 0.515625L4.21875 1.78125C3.67969 1.96875 3.21094 2.22656 2.78906 2.60156L1.6875 1.96875C1.64062 1.92188 1.57031 1.89844 1.5 1.89844C1.03125 1.89844 0 3.82031 0 4.21875C0 4.35938 0.0703125 4.5 0.1875 4.57031L1.28906 5.17969C1.24219 5.46094 1.21875 5.71875 1.21875 6C1.21875 6.28125 1.24219 6.5625 1.28906 6.84375L0.1875 7.45312C0.0703125 7.52344 0 7.66406 0 7.78125C0 8.13281 0.984375 10.125 1.5 10.125C1.57031 10.125 1.64062 10.1016 1.6875 10.0547L2.78906 9.42188C3.21094 9.77344 3.67969 10.0547 4.21875 10.2422L4.21875 11.5078C4.21875 11.6953 4.33594 11.8359 4.5 11.8828C4.89844 11.9531 5.29688 12 5.69531 12C6.07031 12 6.49219 11.9766 6.89062 11.8828C7.07812 11.8359 7.21875 11.6953 7.21875 11.5078L7.21875 10.2422C7.73438 10.0547 8.20312 9.77344 8.625 9.42188L9.72656 10.0547C9.77344 10.1016 9.84375 10.125 9.91406 10.125C10.3828 10.1016 11.4141 8.20312 11.4141 7.78125ZM5.71875 7.875C4.66406 7.875 3.84375 7.05469 3.84375 6C3.84375 4.96875 4.66406 4.125 5.71875 4.125C6.75 4.125 7.59375 4.96875 7.59375 6C7.59375 7.05469 6.75 7.875 5.71875 7.875Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "gear" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M9 5.25L8.625 5.25L8.625 3.375C8.625 1.52344 7.10156 0 5.25 0C3.375 0 1.875 1.52344 1.875 3.375L1.875 5.25L1.5 5.25C0.65625 5.25 0 5.90625 0 6.75L0 10.5C0 11.3203 0.65625 12 1.5 12L9 12C9.82031 12 10.5 11.3203 10.5 10.5L10.5 6.75C10.5 5.92969 9.82031 5.25 9 5.25ZM3.375 3.375C3.375 2.34375 4.19531 1.5 5.25 1.5C6.28125 1.5 7.125 2.34375 7.125 3.375L7.125 5.25L3.375 5.25L3.375 3.375Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "key" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M9 0L1.5 0C0.65625 0 0 0.679688 0 1.5L0 9C0 9.84375 0.65625 10.5 1.5 10.5L9 10.5C9.82031 10.5 10.5 9.84375 10.5 9L10.5 1.5C10.5 0.679688 9.82031 0 9 0ZM4.17188 7.89844C3.65625 8.01562 2.50781 8.25 2.50781 8.25C2.48438 8.25 2.46094 8.25 2.46094 8.25C2.32031 8.25 2.22656 8.13281 2.25 7.99219L2.625 6.32812C2.64844 6.16406 2.74219 6.02344 2.85938 5.90625L5.22656 3.53906L6.98438 5.27344L4.59375 7.66406C4.47656 7.78125 4.33594 7.85156 4.17188 7.89844ZM7.99219 4.26562L7.5 4.75781L5.74219 3L6.23438 2.50781C6.39844 2.34375 6.63281 2.25 6.84375 2.25C7.07812 2.25 7.28906 2.34375 7.47656 2.50781L7.99219 3.02344C8.32031 3.375 8.32031 3.91406 7.99219 4.26562Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 1px)" },
            },
            {
              d: "M9 0L1.5 0C0.65625 0 0 0.679688 0 1.5L0 9C0 9.84375 0.65625 10.5 1.5 10.5L9 10.5C9.82031 10.5 10.5 9.84375 10.5 9L10.5 1.5C10.5 0.679688 9.82031 0 9 0ZM4.17188 7.89844C3.65625 8.01562 2.50781 8.25 2.50781 8.25C2.48438 8.25 2.46094 8.25 2.46094 8.25C2.32031 8.25 2.22656 8.13281 2.25 7.99219L2.625 6.32812C2.64844 6.16406 2.74219 6.02344 2.85938 5.90625L5.22656 3.53906L6.98438 5.27344L4.59375 7.66406C4.47656 7.78125 4.33594 7.85156 4.17188 7.89844ZM7.99219 4.26562L7.5 4.75781L5.74219 3L6.23438 2.50781C6.39844 2.34375 6.63281 2.25 6.84375 2.25C7.07812 2.25 7.28906 2.34375 7.47656 2.50781L7.99219 3.02344C8.32031 3.375 8.32031 3.91406 7.99219 4.26562Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(1px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "square-pen" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 2.68594 9.31406 0 6 0ZM9.15469 7.27969C8.86172 7.57266 8.38711 7.57266 8.09414 7.27969L6 5.18672L3.90469 7.27969C3.61172 7.57266 3.13711 7.57266 2.84414 7.27969C2.55117 6.98672 2.55117 6.51211 2.84414 6.21914L5.46914 3.59414C5.61563 3.44766 5.80781 3.375 6 3.375C6.19219 3.375 6.38391 3.44824 6.53016 3.59473L9.15516 6.21973C9.44766 6.51328 9.44766 6.98672 9.15469 7.27969Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { type: "circle-chevron-up-solid 1" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 2.68594 9.31406 0 6 0ZM9.15469 5.77969L6.52969 8.40469C6.38438 8.55234 6.19219 8.625 6 8.625C5.80781 8.625 5.61609 8.55176 5.46984 8.40527L2.84484 5.78027C2.55187 5.4873 2.55187 5.0127 2.84484 4.71973C3.13781 4.42676 3.61242 4.42676 3.90539 4.71973L6 6.81562L8.09484 4.72078C8.38781 4.42781 8.86242 4.42781 9.15539 4.72078C9.44836 5.01375 9.44766 5.48672 9.15469 5.77969Z",
              fill: "rgba(43,133,255,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { type: "circle-chevron-down-solid 1" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6 0C2.67188 0 0 2.69531 0 6C0 9.32812 2.67188 12 6 12C9.30469 12 12 9.32812 12 6C12 2.69531 9.30469 0 6 0ZM6 9.375C5.57812 9.375 5.25 9.04688 5.25 8.625C5.25 8.20312 5.55469 7.875 6 7.875C6.39844 7.875 6.75 8.20312 6.75 8.625C6.75 9.04688 6.39844 9.375 6 9.375ZM7.61719 6.04688L6.5625 6.70312L6.5625 6.75C6.5625 7.05469 6.30469 7.3125 6 7.3125C5.69531 7.3125 5.4375 7.05469 5.4375 6.75L5.4375 6.375C5.4375 6.1875 5.53125 6 5.71875 5.88281L7.05469 5.08594C7.21875 4.99219 7.3125 4.82812 7.3125 4.64062C7.3125 4.35938 7.05469 4.125 6.77344 4.125L5.57812 4.125C5.27344 4.125 5.0625 4.35938 5.0625 4.64062C5.0625 4.94531 4.80469 5.20312 4.5 5.20312C4.19531 5.20312 3.9375 4.94531 3.9375 4.64062C3.9375 3.72656 4.66406 3 5.55469 3L6.75 3C7.71094 3 8.4375 3.72656 8.4375 4.64062C8.4375 5.20312 8.13281 5.74219 7.61719 6.04688Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
          ],
        },
      },
      variantValues: { type: "howto" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M3.75 9L2.25 9C1.82812 9 1.5 8.67188 1.5 8.25L1.5 2.25C1.5 1.85156 1.82812 1.5 2.25 1.5L3.75 1.5C4.14844 1.5 4.5 1.17188 4.5 0.75C4.5 0.351562 4.14844 0 3.75 0L2.25 0C0.984375 0 0 1.00781 0 2.25L0 8.25C0 9.51562 0.984375 10.5 2.25 10.5L3.75 10.5C4.14844 10.5 4.5 10.1719 4.5 9.75C4.5 9.35156 4.14844 9 3.75 9ZM11.7656 4.73438L8.76562 1.73438C8.48438 1.42969 7.99219 1.42969 7.71094 1.73438C7.40625 2.01562 7.40625 2.50781 7.71094 2.78906L9.42188 4.5L4.5 4.5C4.07812 4.5 3.75 4.85156 3.75 5.25C3.75 5.67188 4.07812 6 4.5 6L9.42188 6L7.71094 7.73438C7.40625 8.01562 7.40625 8.50781 7.71094 8.78906C7.99219 9.09375 8.48438 9.09375 8.76562 8.78906L11.7656 5.78906C12.0703 5.50781 12.0703 5.01562 11.7656 4.73438Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "logout" },
    },
    {
      overrides: {
        MyIcon: {
          width: "12px",
          height: "12px",
          viewBox: { minX: 0, minY: 0, width: 12, height: 12 },
          paths: [
            {
              d: "M6 0C2.67188 0 0 2.17969 0 4.875C0 6.02344 0.515625 7.10156 1.33594 7.92188C1.05469 9.11719 0.0703125 10.1719 0.0703125 10.1719C0 10.2188 0 10.3125 0.0234375 10.3828C0.046875 10.4531 0.117188 10.5 0.1875 10.5C1.75781 10.5 2.88281 9.75 3.49219 9.28125C4.26562 9.5625 5.10938 9.75 6 9.75C9.32812 9.75 11.9766 7.54688 11.9766 4.875C11.9766 2.20312 9.30469 0 6 0ZM2.97656 5.625C2.55469 5.625 2.22656 5.27344 2.22656 4.875C2.22656 4.5 2.55469 4.125 2.97656 4.125C3.375 4.125 3.72656 4.47656 3.72656 4.875C3.72656 5.29688 3.39844 5.625 2.97656 5.625ZM6 5.625C5.57812 5.625 5.25 5.27344 5.25 4.875C5.25 4.5 5.60156 4.125 6 4.125C6.375 4.125 6.72656 4.47656 6.72656 4.875C6.72656 5.29688 6.39844 5.625 6 5.625ZM8.97656 5.625C8.55469 5.625 8.22656 5.27344 8.22656 4.875C8.22656 4.5 8.55469 4.125 8.97656 4.125C9.375 4.125 9.72656 4.47656 9.72656 4.875C9.72656 5.29688 9.39844 5.625 8.97656 5.625Z",
              fill: "rgba(160,160,160,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 1px)" },
            },
          ],
        },
      },
      variantValues: { type: "feedback" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M0 1.25C0 0.585938 0.546875 0 1.25 0L16.25 0C16.9141 0 17.5 0.585938 17.5 1.25C17.5 1.95312 16.9141 2.5 16.25 2.5L1.25 2.5C0.546875 2.5 0 1.95312 0 1.25ZM0 7.5C0 6.83594 0.546875 6.25 1.25 6.25L16.25 6.25C16.9141 6.25 17.5 6.83594 17.5 7.5C17.5 8.20312 16.9141 8.75 16.25 8.75L1.25 8.75C0.546875 8.75 0 8.20312 0 7.5ZM16.25 15L1.25 15C0.546875 15 0 14.4531 0 13.75C0 13.0859 0.546875 12.5 1.25 12.5L16.25 12.5C16.9141 12.5 17.5 13.0859 17.5 13.75C17.5 14.4531 16.9141 15 16.25 15Z",
          fill: "rgba(43,133,255,1)",
          fillRule: "nonzero",
          style: { transform: "translate(7.14%, 14.29%)" },
        },
      ]}
      {...rest}
      {...getOverrideProps(overrides, "MyIcon")}
    ></Icon>
  );
}
