/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import ListShindochi from "./ListShindochi";
import { Flex } from "@aws-amplify/ui-react";
import BtnKanryo from "./BtnKanryo";
export default function LayoutShindochiSentaku(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="16px"
      padding="16px 8px 16px 8px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "LayoutShindochiSentaku")}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 2001")}
      >
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default02"
          {...getOverrideProps(overrides, "listShindochi38283159")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          property1="Select03"
          {...getOverrideProps(overrides, "listShindochi38283507")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default04"
          {...getOverrideProps(overrides, "listShindochi38283523")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default05W"
          {...getOverrideProps(overrides, "listShindochi38283531")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default05S"
          {...getOverrideProps(overrides, "listShindochi38283541")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default06W"
          {...getOverrideProps(overrides, "listShindochi38283553")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default06S"
          {...getOverrideProps(overrides, "listShindochi38283567")}
        ></ListShindochi>
        <ListShindochi
          display="flex"
          gap="10px"
          direction="column"
          width="168px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="12px 8px 12px 8px"
          backgroundColor="rgba(255,255,255,1)"
          property1="Default07"
          {...getOverrideProps(overrides, "listShindochi38283583")}
        ></ListShindochi>
      </Flex>
      <BtnKanryo
        width="80px"
        height="26px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "btnKanryo")}
      ></BtnKanryo>
    </Flex>
  );
}
