/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: String!) {
    getAnswer(id: $id) {
      id
      answer
      userId
      questionId
      createdAt
      summary
      comment
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers($time: String!) {
    listAnswers(time: $time) {
      id
      answer
      userId
      questionId
      createdAt
      summary
      comment
      name
      question
      text
    }
  }
`;
export const getCustom = /* GraphQL */ `
  query GetCustom($id: String!) {
    getCustom(id: $id) {
      id
      datetime
      title
      precautions
      typeid
    }
  }
`;
export const listCustoms = /* GraphQL */ `
  query ListCustoms($limit: Int!) {
    listCustoms(limit: $limit) {
      id
      datetime
      title
      precautions
      type
    }
  }
`;
export const getEarthquake = /* GraphQL */ `
  query GetEarthquake($id: String!) {
    getEarthquake(id: $id) {
      id
      datetime
      seismicIntensity
      spot
      typeid
    }
  }
`;
export const listEarthquakes = /* GraphQL */ `
  query ListEarthquakes($limit: Int!) {
    listEarthquakes(limit: $limit) {
      id
      datetime
      seismicIntensity
      spot
      type
    }
  }
`;
export const listEarthquakeOne = /* GraphQL */ `
  query ListEarthquakeOne {
    listEarthquakeOne {
      id
      datetime
      seismicIntensity
      spot
      typeid
    }
  }
`;
export const getEarthquakeCode = /* GraphQL */ `
  query GetEarthquakeCode($id: String!) {
    getEarthquakeCode(id: $id) {
      id
      code
      maxi
      eid
    }
  }
`;
export const listEarthquakeCodes = /* GraphQL */ `
  query ListEarthquakeCodes {
    listEarthquakeCodes {
      id
      code
      maxi
      eid
    }
  }
`;
export const listCustomQuestion = /* GraphQL */ `
  query ListCustomQuestion($customtype: String!) {
    listCustomQuestion(customtype: $customtype) {
      questionId
      title
      answerPattern {
        IndexNum
        text
        detailId
      }
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: String!) {
    getQuestion(id: $id) {
      id
      question
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions {
    listQuestions {
      id
      question
    }
  }
`;
export const getQuestionDetail = /* GraphQL */ `
  query GetQuestionDetail($id: String!) {
    getQuestionDetail(id: $id) {
      id
      questionId
      indexNum
      text
    }
  }
`;
export const listQuestionDetails = /* GraphQL */ `
  query ListQuestionDetails {
    listQuestionDetails {
      id
      questionId
      indexNum
      text
    }
  }
`;
export const getUserSetting = /* GraphQL */ `
  query GetUserSetting($mail: String!) {
    getUserSetting(mail: $mail) {
      id
      name
      mail
      phone
      adminFlag
      employeeNumber
    }
  }
`;
export const listUserSettinglight = /* GraphQL */ `
  query ListUserSettinglight {
    listUserSettinglight {
      id
      name
      mail
      phone
      adminFlag
      employeeNumber
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings {
    listUserSettings {
      id
      name
      mail
      phone
      mailStatus
      smsStatus
      adminFlag
      employeeNumber
    }
  }
`;
