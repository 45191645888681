/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import BtnSousa from "./BtnSousa";
export default function HowtoSelect(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text39243385: {},
        MyIcon: {},
        Frame39243384: {},
        text39243390: {},
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: {},
      },
      variantValues: { select: "User01" },
    },
    {
      overrides: {
        text39243385: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u306E\u56DE\u7B54\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u88AB\u5BB3\u306E\u540D\u524D\u3092\u30BF\u30C3\u30D7\u3059\u308B\u3068\u30E1\u30F3\u30D0\u30FC\u306E\u56DE\u7B54\u72B6\u6CC1\u3092\u78BA\u8A8D\u3067\u304D\u307E\u3059\u3002\u3055\u3089\u306B\u56DE\u7B54\u72B6\u6CC1\u3092\u30BF\u30C3\u30D7\u3059\u308B\u3068\u56DE\u7B54\u72B6\u6CC1\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: {},
      },
      variantValues: { select: "User03" },
    },
    {
      overrides: {
        text39243385: {
          children:
            "\u56DE\u7B54\u72B6\u6CC1\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u30E1\u30F3\u30D0\u30FC\u304C\u56DE\u7B54\u3057\u305F\u72B6\u6CC1\u3092\u65E5\u6642\u306A\u3069\u3092\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: { height: "157px" },
      },
      variantValues: { select: "User04" },
    },
    {
      overrides: {
        text39243385: {
          children:
            "\u6700\u65B0\u306E\u88AB\u5BB3\u72B6\u6CC1\u3068\u904E\u53BB\u306E\u88AB\u5BB3\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u72B6\u6CC1\u78BA\u8A8D\u30DA\u30FC\u30B8\u3067\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: { height: "133px" },
      },
      variantValues: { select: "User02" },
    },
    {
      overrides: {
        text39243385: {
          children: "\u30E1\u30F3\u30D0\u30FC\u306E\u8FFD\u52A0",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u7BA1\u7406\u8005\u8A2D\u5B9A\u30DA\u30FC\u30B8\u3067\u30E1\u30F3\u30D0\u30FC\u306E\u8FFD\u52A0\u3068\u7DE8\u96C6\u3092\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u307E\u305F\u7BA1\u7406\u8005\u306E\u767B\u9332\u3082\u3053\u3061\u3089\u3067\u3067\u304D\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: { height: "157px" },
      },
      variantValues: { select: "Kanri02" },
    },
    {
      overrides: {
        text39243385: {
          children:
            "\u5B89\u5426\u767B\u9332\u9805\u76EE\u3092\u30AB\u30B9\u30BF\u30E0\u3059\u308B",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u5730\u9707\u4EE5\u5916\u3067\u3082\u3001\u30E1\u30F3\u30D0\u30FC\u306E\u5B89\u5426\u306E\u78BA\u8A8D\u304C\u53EF\u80FD\u3067\u3059\u3002\u305D\u306E\u5834\u5408\u3001\u7BA1\u7406\u8005\u304C\u5B89\u5426\u78BA\u8A8D\u9805\u76EE\u9001\u4FE1\u30DA\u30FC\u30B8\u3067\u30AB\u30B9\u30BF\u30DE\u30A4\u30BA\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: {},
      },
      variantValues: { select: "Kanri03" },
    },
    {
      overrides: {
        text39243385: {
          children:
            "\u901A\u77E5\u3059\u308B\u9707\u5EA6\u3068\u89B3\u6E2C\u5024\u3092\u8A2D\u5B9A\u3059\u308B",
        },
        MyIcon: {},
        Frame39243384: {},
        text39243390: {
          children:
            "\u7BA1\u7406\u8005\u8A2D\u5B9A\u30DA\u30FC\u30B8\u3067\u30E1\u30F3\u30D0\u30FC\u306B\u901A\u77E5\u3059\u308B\u6700\u4F4E\u9707\u5EA6\u3001\u89B3\u6E2C\u5024\u3092\u8A2D\u5B9A\u3067\u304D\u307E\u3059\u3002",
        },
        Frame39243389: {},
        btnSousa: {},
        Rectangle: {},
        HowtoSelect: { height: "157px" },
      },
      variantValues: { select: "Kanri01" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="column"
      width="288px"
      height="181px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="8px 0px 0px 0px"
      backgroundColor="rgba(245,245,245,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "HowtoSelect")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame39243384")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="安否登録をする"
          {...getOverrideProps(overrides, "text39243385")}
        ></Text>
        <MyIcon
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="circle-chevron-up-solid 1"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame39243389")}
      >
        <Text
          fontFamily=""
          fontSize="12px"
          fontWeight=""
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="地震が起きたら、登録しているメールアドレスにvoozyからメールが届きます。&#xA;googleにログインをして安否の登録をしましょう。"
          {...getOverrideProps(overrides, "text39243390")}
        ></Text>
      </Flex>
      <BtnSousa
        display="flex"
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="14px"
        padding="8px 16px 8px 16px"
        backgroundColor="rgba(43,133,255,1)"
        {...getOverrideProps(overrides, "btnSousa")}
      ></BtnSousa>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(224,224,224,1)"
        {...getOverrideProps(overrides, "Rectangle")}
      ></View>
    </Flex>
  );
}
