/*
  概要：安否確認ページで使用するuseContext
*/
import React, { createContext, useContext, useState } from "react";

const ReportContext = createContext({});


export const ReportProvider = (props) => {
  const { children } = props;
  const [TextBox, setTextBox] = useState(""); //テキストが入る部分
  const [report, setReport]   = useState([]); //report = [{questionId: 1, IndexNum: 0}, {questionId:2, IndexNum: 0}, {questionId:3, IndexNum: 0}]
  const [LatestEarthquakeInfo, setLatestEarthquakeInfo] = useState(null);//最新地震情報
  const [Custommode, setCustommode] = useState(false);//Customデータかどうかを判断する
  const [NextQuestionId, setNextQuestionId] = useState(null);

  const value = {
    TextBox             , setTextBox, 
    report              , setReport ,
    NextQuestionId      , setNextQuestionId,
    LatestEarthquakeInfo, setLatestEarthquakeInfo,
    Custommode          , setCustommode
  }

  return (
    <ReportContext.Provider value={value}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => useContext(ReportContext);