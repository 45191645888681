/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function MyText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { text: {}, MyText: {} }, variantValues: { status: "small" } },
    {
      overrides: {
        text: {
          fontSize: "14px",
          lineHeight: "14px",
          height: "14px",
          children: "medium",
        },
        MyText: { height: "14px" },
      },
      variantValues: { status: "medium" },
    },
    {
      overrides: {
        text: {
          fontSize: "16px",
          lineHeight: "16px",
          height: "16px",
          children: "large",
        },
        MyText: { height: "16px" },
      },
      variantValues: { status: "large" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="100%"
      height="12px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "MyText")}
    >
      <Text
        fontFamily=""
        fontSize="12px"
        fontWeight=""
        color="rgba(0,0,0,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="100%"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="small"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </View>
  );
}
