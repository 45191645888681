import React from "react";
import './Animation.css';


export function RingLoading() {
    return (
        <div className = "ring">
            <span className = "ringspan"></span>
        </div>
    );
}