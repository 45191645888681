/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer($id: String!) {
    deleteAnswer(id: $id) {
      id
      answer
      userId
      questionId
      createdAt
      summary
      comment
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($createAnswerInput: [CreateAnswerInput]) {
    createAnswer(createAnswerInput: $createAnswerInput) {
      id
      answer
      userId
      questionId
      createdAt
      summary
      comment
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($updateAnswerInput: UpdateAnswerInput!) {
    updateAnswer(updateAnswerInput: $updateAnswerInput) {
      id
      answer
      userId
      questionId
      createdAt
      summary
      comment
    }
  }
`;
export const deleteCustom = /* GraphQL */ `
  mutation DeleteCustom($id: String!) {
    deleteCustom(id: $id) {
      id
      datetime
      title
      precautions
      typeid
    }
  }
`;
export const createCustom = /* GraphQL */ `
  mutation CreateCustom($createCustomInput: CreateCustomInput!) {
    createCustom(createCustomInput: $createCustomInput) {
      id
      datetime
      title
      precautions
      typeid
    }
  }
`;
export const updateCustom = /* GraphQL */ `
  mutation UpdateCustom($updateCustomInput: UpdateCustomInput!) {
    updateCustom(updateCustomInput: $updateCustomInput) {
      id
      datetime
      title
      precautions
      typeid
    }
  }
`;
export const deleteEarthquake = /* GraphQL */ `
  mutation DeleteEarthquake($id: String!) {
    deleteEarthquake(id: $id) {
      id
      datetime
      seismicIntensity
      spot
      typeid
    }
  }
`;
export const createEarthquake = /* GraphQL */ `
  mutation CreateEarthquake($createEarthquakeInput: CreateEarthquakeInput!) {
    createEarthquake(createEarthquakeInput: $createEarthquakeInput) {
      id
      datetime
      seismicIntensity
      spot
      typeid
    }
  }
`;
export const updateEarthquake = /* GraphQL */ `
  mutation UpdateEarthquake($updateEarthquakeInput: UpdateEarthquakeInput!) {
    updateEarthquake(updateEarthquakeInput: $updateEarthquakeInput) {
      id
      datetime
      seismicIntensity
      spot
      typeid
    }
  }
`;
export const deleteEarthquakeCode = /* GraphQL */ `
  mutation DeleteEarthquakeCode($id: String!) {
    deleteEarthquakeCode(id: $id) {
      id
      code
      maxi
      eid
    }
  }
`;
export const createEarthquakeCode = /* GraphQL */ `
  mutation CreateEarthquakeCode(
    $createEarthquakeCodeInput: CreateEarthquakeCodeInput!
  ) {
    createEarthquakeCode(
      createEarthquakeCodeInput: $createEarthquakeCodeInput
    ) {
      id
      code
      maxi
      eid
    }
  }
`;
export const updateEarthquakeCode = /* GraphQL */ `
  mutation UpdateEarthquakeCode(
    $updateEarthquakeCodeInput: UpdateEarthquakeCodeInput!
  ) {
    updateEarthquakeCode(
      updateEarthquakeCodeInput: $updateEarthquakeCodeInput
    ) {
      id
      code
      maxi
      eid
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($id: String!) {
    deleteQuestion(id: $id) {
      id
      question
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($createQuestionInput: CreateQuestionInput!) {
    createQuestion(createQuestionInput: $createQuestionInput) {
      id
      question
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion($updateQuestionInput: UpdateQuestionInput!) {
    updateQuestion(updateQuestionInput: $updateQuestionInput) {
      id
      question
    }
  }
`;
export const deleteQuestionDetail = /* GraphQL */ `
  mutation DeleteQuestionDetail($id: String!) {
    deleteQuestionDetail(id: $id) {
      id
      questionId
      indexNum
      text
    }
  }
`;
export const createQuestionDetail = /* GraphQL */ `
  mutation CreateQuestionDetail(
    $createQuestionDetailInput: CreateQuestionDetailInput!
  ) {
    createQuestionDetail(
      createQuestionDetailInput: $createQuestionDetailInput
    ) {
      id
      questionId
      indexNum
      text
    }
  }
`;
export const updateQuestionDetail = /* GraphQL */ `
  mutation UpdateQuestionDetail(
    $updateQuestionDetailInput: UpdateQuestionDetailInput!
  ) {
    updateQuestionDetail(
      updateQuestionDetailInput: $updateQuestionDetailInput
    ) {
      id
      questionId
      indexNum
      text
    }
  }
`;
export const deleteUserSetting = /* GraphQL */ `
  mutation DeleteUserSetting($id: String!) {
    deleteUserSetting(id: $id) {
      id
      name
      mail
      phone
      adminFlag
      employeeNumber
    }
  }
`;
export const createUserSetting = /* GraphQL */ `
  mutation CreateUserSetting($createUserSettingInput: CreateUserSettingInput!) {
    createUserSetting(createUserSettingInput: $createUserSettingInput) {
      id
      name
      mail
      phone
      adminFlag
      employeeNumber
    }
  }
`;
export const updateUserSetting = /* GraphQL */ `
  mutation UpdateUserSetting($updateUserSettingInput: UpdateUserSettingInput!) {
    updateUserSetting(updateUserSettingInput: $updateUserSettingInput) {
      id
      name
      mail
      phone
      adminFlag
      employeeNumber
    }
  }
`;
