/* eslint-disable react-hooks/exhaustive-deps */

/*
    概要：管理者ページ表示時に実行する関数
*/
import { useCallback, useState } from "react";
import { LocalArea_reverse } from "../setting/PrefectureJIS";
import { useMessage } from "./useMessage";
import { fetchCurrentUserInfo, fetchSettingSeismicIntensity, fetchEarthQuaqeSettingSpot, fetchListUserSetting} from "./misc/common";
import { useLoginUser } from "../hooks/providers/useLoginUserProvider";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { adminChcker } from '../hooks/misc/common';


const MAX_FETCH = 2; //Fetchを行う回数の最大
let   fetch_num = 0; //Fetchを実行した回数

export const useAdminData = () => {
    const [seismicIntensity, setseismicIntensity] = useState(""); // 設定震度値
    const [spot, setspot] = useState("");                         // 設定震度場所
    const [UserList, setUserList] = useState("");                 // 全ユーザ情報
    const [loading, setLoading] = useState(false);                // loadingを管理
    const { showMessage } = useMessage();                         // error文
    const { loginUser, setLoginUser } = useLoginUser();           
    const history = useHistory();

    /*
        概要：設定震度値、震度観測地、ユーザ情報の取得をします
    */
    const GetData = useCallback(async() => {
        
        // Loading画面の表示
        setLoading(true);//ローディング画面開始
        // 初期化
        fetch_num = 0;

        // Authの認証ユーザの情報を取得
        let   resCurrentAuthUser = await Auth.currentAuthenticatedUser();
        const               mail = resCurrentAuthUser.attributes.email;

        if(resCurrentAuthUser === null)
        {
            showMessage({message:"不明なユーザ"});
            // Loading画面の終了
            setLoading(false);
            // 画面を戻る
            history.push("/");
            return;
        }
        else
        {
            await FetchData(mail);
        }   
    })


    /*
        概要：データの取得を行います。
    */
    const FetchData = async(mail) => {
        let do_final_flag = false;
        
        // 設定震度を取得.設定震度観測地情報を取得.ユーザ情報を取得
        await Promise.all([
            fetchCurrentUserInfo(mail)     ,  //ユーザ情報を取得
            fetchSettingSeismicIntensity() ,  //設定震度を取得
            fetchEarthQuaqeSettingSpot()   ,  //設定震度観測地情報を取得
            fetchListUserSetting()            //全ユーザ情報を取得
        ]).then((response) => {
            console.log(response);
            let [userInfo, settingSeismicIntensity, settingEarthQuaqeSpot, settingListUsers] = response;

            // ログインユーザ情報の保存
            setLoginUser(userInfo.data.getUserSetting);

            // 管理者権限であるかどうかの確認処理
            const checkedRet = adminChcker(userInfo.data.getUserSetting);
            if(checkedRet.status !== "admin")
            {
                showMessage({message:checkedRet.message});
                history.push("/");
                return;
            }
          
        
            // 震度値の保存
            let param_seismicIntensity_value = JSON.parse(settingSeismicIntensity.data.param_value);
            setseismicIntensity(param_seismicIntensity_value);

            // 観測値保存
            let spotConved = {"北海道・東北": [], "関東": [], "東海": [], "関西": [], "中国・四国": [], "九州・沖縄": []}//近畿→関西、中部→東海
            let res_prefectures_list = settingEarthQuaqeSpot.data.param_value;
            let prefectures_list_json = JSON.parse(JSON.parse(res_prefectures_list));
            prefectures_list_json.forEach((spot)=>{
                spotConved[LocalArea_reverse[spot]].push(spot)
            })
            setspot(spotConved);

            //　ユーザ情報の保存
            setUserList(settingListUsers.data.listUserSettings);

            do_final_flag = true

        }).catch((e)=>{
            //Errorの表示
            console.error(e);
            let message_text = "Invoke API Error";

            if(typeof e.errors !== "undefined")
            {
                if(( fetch_num < MAX_FETCH)&&
                (e.errors[0].errorType === "Lambda:Unhandled")&&
                (e.errors[0].message   === "An error occurred (BadRequestException) when calling the ExecuteStatement operation: Communications link failure\n\nThe last packet sent successfully to the server was 0 milliseconds ago. The driver has not received any packets from the server.; SQLState: 08S01"))
                {
                    fetch_num++;
                    setTimeout(()=>{FetchData(mail)}, 4000);
                    message_text = "Start DB Running";
                }
            }

            showMessage({message: message_text});
            

        }).finally(()=>{
            if(do_final_flag)
            {
                // Loading画面の終了
                setLoading(false);
            }
        })
    }


    /*
        概要：震度の設定値を取得します
    */
    const fetchAndSetSeismicIntensity = async() => {
        await Promise.all([
            fetchSettingSeismicIntensity()
        ]).then((response)=>{
            let param_seismicIntensity_value = JSON.parse(response[0].data.param_value);
            setseismicIntensity(param_seismicIntensity_value);
        })
        .catch((e)=>{
            //Errorの表示
            console.error(e);
            throw new Error("Invoke API Error");
        })
    }

    /*
        概要：震度の観測地の設定値を取得します
    */
    const fetchAndSetSpot = async() => {
        let spotConved = {"北海道・東北": [], "関東": [], "東海": [], "関西": [], "中国・四国": [], "九州・沖縄": []}//近畿→関西、中部→東海

        await Promise.all([
            fetchEarthQuaqeSettingSpot()
        ]).then((response)=>{
            console.log(response)
            let res_prefectures_list = response[0].data.param_value;
            let prefectures_list_json = JSON.parse(JSON.parse(res_prefectures_list));
            prefectures_list_json.forEach((spot)=>{
                spotConved[LocalArea_reverse[spot]].push(spot)
            })
            setspot(spotConved);
        }).catch((e)=>{
            //Errorの表示
            console.error(e);
            throw new Error("Invoke API Error");
        })
    }
    
    /*
        概要：データベースからユーザ情報をリストで取得する
    */
    const fetchAndSetSettingUsers = async() =>{
        await Promise.all([
            fetchListUserSetting()
        ]).then((response)=>{
            console.log(response);
            setUserList(response[0].data.listUserSettings);
            console.log(response[0].data.listUserSettings);
        }).catch((e)=>{
            //Errorの表示
            console.error(e);
            throw new Error("Invoke API Error");
        })
    }

    

    return { GetData, fetchAndSetSettingUsers, fetchAndSetSeismicIntensity, fetchAndSetSpot, seismicIntensity, spot, UserList, loading, setLoading};
};
