/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function BtnText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, BtnText: {} },
      variantValues: { status: "Default" },
    },
    {
      overrides: {
        text: { color: "rgba(43,133,255,1)" },
        BtnText: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { status: "Cancel" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(43,133,255,1)"
      borderRadius="4px"
      padding="12px 23px 12px 23px"
      backgroundColor="rgba(43,133,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "BtnText")}
    >
      <Text
        fontFamily=""
        fontSize="16px"
        fontWeight=""
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="1.45px"
        width="240px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="button"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
