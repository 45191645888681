/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import MyIcon from "./MyIcon";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function HeaderJokyo(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="62px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="16px 98px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "HeaderJokyo")}
    >
      <MyIcon
        width="20px"
        height="20px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="menu"
        {...getOverrideProps(overrides, "MyIcon")}
      ></MyIcon>
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 2003")}
      >
        <Text
          fontFamily="``"
          fontSize="12px"
          fontWeight="``"
          color="rgba(160,160,160,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="60px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="安否登録"
          {...getOverrideProps(overrides, "text38743519")}
        ></Text>
        <Flex
          padding="0px 0px 0px 0px"
          width="60px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 188")}
        >
          <Text
            fontFamily="``"
            fontSize="12px"
            fontWeight="``"
            color="rgba(0,0,0,1)"
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="60px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="25%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="状況確認"
            {...getOverrideProps(overrides, "text38743531")}
          ></Text>
          <Icon
            width="4px"
            height="4px"
            viewBox={{ minX: 0, minY: 0, width: 4, height: 4 }}
            paths={[
              {
                d: "M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z",
                fill: "rgba(43,133,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="20px"
            left="28px"
            {...getOverrideProps(overrides, "Ellipse 7")}
          ></Icon>
        </Flex>
      </Flex>
    </Flex>
  );
}
