/* 
    概要：安否確認ページのボタン コンポーネント
*/

import React, { memo, useState } from 'react';
import { useEffect } from 'react';
import {
    MyText, //テキスト
} from '../../../ui-components';
import { Remarks } from '../../molecules/remarks/remarks';
import { monotonicFactory } from 'ulid';

import { useReport } from '../../../hooks/providers/useReportProvider';
import { useSafeConfrimData } from '../../../hooks/useSafeConfirmData';

import { IconContentButton } from '../../atoms/button/IconContentButton';

import { ContentButton } from '../../atoms/button/ContentButton';
import { useOtherData } from '../../../hooks/providers/useOtherDataProvider';


export const SafetyBottons = memo((props) => {
    /*
        データ形式：
            report = [{questionId: 1, answer: 0}, {questionId:2, answer: 0}, {questionId:3, answer: 0}]
    */
    const {report, setReport,NextQuestionId, setNextQuestionId} = useReport();
    const {listQuestion}       = useOtherData();//データの取得
    
    /*
        概要：次のQuestionIdを探します。
    */
    const NextQuestionIdSearch = () => {
        //reportの内容によって表示するものを判断します。
        const branchQuestion = "1"; //回答の分岐点となるquestionId
        let serchIndex     = serchIndexFunc(branchQuestion, report);
        if(serchIndex !== -1 || serchIndex === null){
            let branchAnswer = report[serchIndex].answer;
            listQuestion.forEach(function(Question){
                if(Question.questionId === branchQuestion)
                {
                    Question.answerPattern.forEach(function(answer){
                        if(answer.IndexNum === branchAnswer)
                        {                          
                            setNextQuestionId(answer.NextQuestionId);    
                        }
                    })
                }
            })
        }
    }

    useEffect(()=>{
        NextQuestionIdSearch();
    },[report])

    if(report !== undefined){
        const FirstQuestion = 0;

        return (
            <>
                <CreateButtons
                    report     = {report}
                    Question   = {listQuestion}
                    QuestionId = {listQuestion[FirstQuestion].questionId}
                    setReport  = {setReport}
                />
                     
                <CreateButtons
                    report     = {report}
                    Question   = {listQuestion}
                    QuestionId = {NextQuestionId}
                    setReport  = {setReport}
                />  
                
                <QuestionIdComponent
                    listQuestion            = {listQuestion}
                    report                  = {report}
                    QuestionId              = {NextQuestionId}
                    QuestionIdComponentDict = {QuestionIdComponentDict}
                />
            </>
        )
    }
    else
    {
        return null;
    }
});

/**関数群********************************************************************************************************************************************/

/*
    概要：
        存在しない場合は-1を返します。
        それ以外の場合はindex番号を返します。
    引数：
        QuestionNumber : 質問項目番号
        report         : 回答内容の連想配列
*/
const serchIndexFunc = (QuestionNumber, report) => {
    if(report === undefined)
    {
        return null;
    }
    const serchIndex = report.findIndex(
        (repo) => repo.questionId === QuestionNumber
    );
    return serchIndex;
}

/*
    概要：
        存在しない場合は-1を返します。
        それ以外の場合は"IndexNum"のvalue値を返します。
    引数：
        QuestionNumber : 質問項目番号
        report         : 回答内容の連想配列
*/
const serchIndexNum = (QuestionNumber, report) => {
    const serchIndex = report.findIndex(
        (repo) => repo.questionId === QuestionNumber 
    );
    if(serchIndex !== -1)
    {
        return report[serchIndex]["answer"];
    }
    else
    {
        return -1;
    }    
}

/*
    概要：
*/
const serchComponentIndex = (QuestionNumber, QuestionIdComponentDict) => {
    const serchIndex = QuestionIdComponentDict.findIndex(
        (dict) => dict.questionId === QuestionNumber 
    ); 
    return serchIndex;
}

/*
    概要：
        reportの回答内容の変更または追加処理を行います
    引数：
        id    : 質問項目番号
        value : それぞれの選択番号
        report: 回答内容を保存する連想配列
*/
const reportChange = (id, indexNum, value, report, setReport) => {
    const BranchQuestionNum = "1";//分岐される質問項目番号
    const insertElem = {
        questionId: id,
        IndexNum  :indexNum,
        answer    : value
    }
    const serchIndex = serchIndexFunc(insertElem["questionId"], report);
    if (serchIndex === -1)
    {
        //追加
        setReport([...report, insertElem]); 
    } 
    else if(insertElem["questionId"] === BranchQuestionNum && report.length > 1)
    {
        if(report[0]["answer"] !== insertElem["answer"])
        {
            //追加
            setReport([insertElem]); 
        }
    }
    else                   
    {
        //変更
        setReport(
            report.map((repo, index) => (index === serchIndex ? insertElem : repo))
        );
    }
};

/*
    概要：
        reportをdeleteする関数
    引数：
        id    : 質問項目番号
        report: 回答内容を保存する連想配列
*/
const reportDelete = (id, report) => {
    //削除
    report = report.filter((repo) => repo["questionId"] !== id)
    return report;
};

/*
    概要:
        answerPatternのリストから指定したIndexNumがあるtextを取得します。
*/
const serchText = (answer, list) =>{
    const serchIndex = list.findIndex(
        (repo) => repo.detailId === answer
    );
    if(serchIndex !== -1)
    {
        return list[serchIndex]["text"];
    }
    else
    {
        return undefined;
    }
}

/*
    概要：
        TypeからIconListのIndex番号を特定します。
*/
const serchType = (Type) => {
    const serchIndex = IconList.findIndex(
        (repo) => repo["Type"] === Type
    );
    return serchIndex;
}

/*
    概要：reportの中の指定のquestionIdは何番目のIndexに存在しているのかを返します。
*/
const serchAnswerIndex = (QuestionId, report) =>{
    if(report === undefined)
    {
        return null;
    }

    const serchIndex = report.findIndex(
        (repo) => repo.questionId === QuestionId
    );
    return serchIndex;
}

/*
    概要：
        <br>を</br>に変換します
    引数：
        comment: 文字列
*/
const JudgeComment =(comment)=>{
    const ulid  = monotonicFactory();//ulidの生成
    let items=[]
    while(comment.includes('<br>')){
        let idx = comment.indexOf('br')
        let text=comment.slice(0, idx-1);
        comment = comment.slice(idx + 3);
        items.push(text)
        items.push(<br key={ulid()}/>)
    }
    items.push(comment)
    return(items);
}

/**コンポーネント群********************************************************************************************************************************************/

/*
    概要：
        Questionからコンポーネントを生成します
    引数：
        QuestionIdで変化します。
*/
const CreateButtons = (props) => {
    const { Question, QuestionId, report, setReport } = props;

    if(QuestionId === null || QuestionId === undefined)
    {
        return null;
    }

    const AnswerIndex         = serchAnswerIndex(QuestionId, report);
    const serchQuestionIndex  = serchIndexFunc(QuestionId, Question);
    const Title               = Question[serchQuestionIndex]["title"];
    const defaultBGColor      = "#FFFFFF";
    let   PropertyList        = [];

    //ボタンの幅を求めます。
    const ButtonsBetweenSpace = 2;
    const divWidth            = 100;
    const ButtonsNum          = Question[serchQuestionIndex].answerPattern.length;
    let   ButtonWidth         = (divWidth - (ButtonsNum -1)* ButtonsBetweenSpace )/ButtonsNum;

      
    Question[serchQuestionIndex].answerPattern.forEach(function(answer, index){
        let iconIndex  = serchType(answer.icon);
        let iconStatus = null;
        if(iconIndex !== -1)
        {
            iconStatus = IconList[iconIndex]["beforeIcon"];
        }
        PropertyList.push({
            "bgColor"    : defaultBGColor            ,
            "textColor"  : answer.color              ,
            "Content"    : JudgeComment(answer.text) , 
            "IconStatus" : iconStatus                , 
            "IndexNum"   : answer.IndexNum           ,
            "icon"       : answer.icon               ,
            "detailid"   : answer.id
        })
    })

    if(report.length !== 0 && AnswerIndex !== -1)
    {
        PropertyList.forEach(function(property, index){
            if(report[AnswerIndex].IndexNum === property.IndexNum)
            {
                let iconIndex  = serchType(property.icon);
                let iconStatus = null;
                if(iconIndex !== -1)
                {
                    iconStatus = IconList[iconIndex]["AfterIcon"];
                }
                let tmp                = property["bgColor"];
                property["bgColor"]    = property["textColor"];
                property["textColor"]  = tmp; 
                property["IconStatus"] = iconStatus;
            }
        })
    }

    return (
        <div>
            <MyText
                overrides = {
                    {
                        "text":{
                            children: Title
                        }
                    }
                }
                status       = {"small"}
                margin       = {"auto"}
                marginBottom = {"8px"}
                width        = {"90%"}
                key          = {QuestionId}
            />
            <div style = {{display: "Flex", justifyContent: "space-between", margin:"auto", width:"90%", marginBottom:"24px"}} key={`${QuestionId}DIV`}>
                {
                    PropertyList.map((property)=>{
                        if(property.icon !==null){
                            return(
                                <React.Fragment key={`${property.detailid} - ${property.IndexNum}`}>
                                    <IconContentButton
                                        bgColor     = {property["bgColor"]}
                                        textColor   = {property["textColor"]}
                                        Content     = {property["Content"]}
                                        IconStatus  = {property["IconStatus"]}
                                        onClick={()=>{reportChange(
                                            QuestionId, 
                                            property.IndexNum,
                                            property.detailid,
                                            report,
                                            setReport
                                        )}}
                                        width = {`${ButtonWidth}%`}
                                        key = {property.text}
                                    />
                                </React.Fragment>
                            );
                        }
                        else{
                            return(
                                <React.Fragment key={`${property.detailid} - ${property.IndexNum}`}>
                                    <ContentButton
                                        bgColor     = {property["bgColor"]}
                                        textColor   = {property["textColor"]}
                                        Content     = {property["Content"]}
                                        IconStatus  = {property["IconStatus"]}
                                        onClick={()=>{reportChange(
                                            QuestionId, 
                                            property.IndexNum,
                                            property.detailid,
                                            report,
                                            setReport
                                        )}}
                                        width = {`${ButtonWidth}%`}
                                        key = {property.text}
                                    />
                                </React.Fragment>
                            );
                        }
                        
                    })
                }
            </div>
        </div>
    )
}

/*
    概要：
        カスタムの追加事項で入力した内容を表示します。
*/
const AdditionItems = memo(() => {
    // const {Custommode, LatestEarthquakeInfo} = useSafeConfrimData();//データの取得
    // const {LatestEarthquakeInfo, Custommode} = props;
    const {LatestEarthquakeInfo, Custommode} = useReport();
    if(Custommode)
    {
        return(
            <>
            {
                LatestEarthquakeInfo.precautions.map((precaution)=>{
                    return(
                        <div style = {{width:"90%", margin:"auto",marginTop:"8px"}}>
                            <MyText
                                overrides = {
                                    {
                                        "text":{
                                            children: "※"+precaution
                                        }
                                    }
                                }
                                status = {"small"}
                            />      
                        </div>
                    );
                })
            }
            </>
        )
    }
    else
    {
        return (
            <>
                <MyText
                    overrides = {
                        {
                            "text":{
                                children: "※余震に気を付けてください"
                            }
                        }
                    }
                    status       = {"small"}
                    width        = {"90%"}
                    margin       = {"auto"}
                    marginTop    = {"16px"}
                    marginBottom = {"8px"}
                />
                <MyText
                    overrides = {
                        {
                            "text":{
                                children: "※無理はしないでください"
                            }
                        }
                    }
                    status    = {"small"}
                    width     = {"90%"}
                    margin    = {"auto"}
                />
        </>
        );
    }
});

/*
    概要：追加のコンポーネントの描画をします。
*/
const QuestionIdComponent = (props) => {
    const {QuestionId, QuestionIdComponentDict, report, listQuestion} = props;
    const FirstQuestion = 0;
    if((QuestionId === null || QuestionId === undefined)&&(report.length !== 0))
    {
        return null;
    }

    let component = null;
    let Index     = null;
    if(report.length === 0)
    {
        Index = serchComponentIndex(listQuestion[FirstQuestion].questionId, QuestionIdComponentDict);
    }
    else
    {
        Index = serchComponentIndex(QuestionId, QuestionIdComponentDict);
    }
    component = QuestionIdComponentDict[Index].Component
    return (
        <>
            {component}
        </>
    )
}


/**辞書*********************************************************************************************************************************************************/
const IconList = [
    {
        "Type"       : "Buji",
        "beforeIcon" : "buji_g", 
        "AfterIcon"  : "buji_w"
    },
    {
        "Type"       : "Higai",
        "beforeIcon" : "higai_r",
        "AfterIcon"  : "higai_w"
    },
    {
        "Type"       : "eye-slash",
        "beforeIcon" : "eye-slash_r",
        "AfterIcon"  : "eye-slash_w"
    },
    {
        "Type"       : "eye",
        "beforeIcon" : "eye_g",
        "AfterIcon"  : "eye_w"     
    }
]

const QuestionIdComponentDict = [
    {
        "questionId": "1",
        "Component" : <Remarks/>
    },
    {
        "questionId": "2",
        "Component" : <AdditionItems/> 
    },
    {
        "questionId": "3",
        "Component" : <Remarks/>
    }
]

