/* eslint-disable react-hooks/exhaustive-deps */
/*
    概要：安否確認項目送信ページ
*/
import React, {useState, useEffect, memo} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css'//←react-bootstrap
import { Form } from 'react-bootstrap';//←react-bootstrap
import { useHistory } from 'react-router-dom';
import { monotonicFactory } from 'ulid';
import { createCustom } from '../../../graphql/mutations';
import {
    BtnAdd,
    ListAttention,
    LayoutTuchikakunin,
    TourokuOK,
    Input02,
    BtnBuji,//Buttonbuji
    BtnHigai,//Buttonhigai
    TxtAnpihoukoku,//安否報告をしてください//Text01
    TxtAttention,//余震に気をつけてください。無理はしないでください//AttentionTop01
    Top01,//震度を表示する部分
    HeaderAnpi,
    RemarkText, 
}
from '../../../ui-components';

import {
    BtnText
}
from "../../../ui-components";
import { useMessage } from "../../../hooks/useMessage";
import { RingLoadingModalWhite } from "../../../components/organisms/loading/RingLoadingModalWhite";
import { useAdminSubmitContext } from '../../../hooks/providers/useAdminSubmitProvider';
import { MultiButton } from '../../molecules/buttons/MultiButton';
import { TopHeader } from '../../molecules/top/TopHeader';
import { adminChcker } from "../../../hooks/misc/common";
import { useLoginUser } from "../../../hooks/providers/useLoginUserProvider";

export const AdminSubmit = () =>{
    const { setTitleTime, PreviewFlag, CompleteviewFlag, setCompleteViewFlag, loading, setInsertTime}  = useAdminSubmitContext();
    const { loginUser, setLoginUser } = useLoginUser();
    const { showMessage } = useMessage();  // error文
    const history = useHistory();

    //初期描画時にタイトルに入力される時間を入れる。
    useEffect(()=>{
        // 今の時刻を取得します
        let time = NowJstTime();
        setInsertTime(time);
        setTitleTime(getStringFromDate(time));

        // 管理者であるかどうかの確認処理
        const checkedRet = adminChcker(loginUser);
        if(checkedRet.status !== "admin")
        {
            showMessage({message:checkedRet.message});
            history.push("/");
        }
    },[])


  return (
    <>
    {loading ? (
        <RingLoadingModalWhite />
    ):(
        <div>
            {(() => {
                if(!PreviewFlag && !CompleteviewFlag)
                {
                    {/*安否項目送信入力ページ*/}
                    return(<AdminSubmitForm/>)  
                }
                else if(PreviewFlag)
                {
                    {/*プレビュー画面*/}
                    return (<PreviewModal/>)
                }
                else if(CompleteviewFlag) 
                {
                    {/*送信完了画面*/}
                    return (
                        <CompleteView
                            setCompleteViewFlag = {setCompleteViewFlag}
                        />
                    )
                }  
            })()}
        </div>
    )}
    </>
  );
};



/**関数群********************************************************************************************************************************************************/
/*
    概要：今の日本時間を取得する処理
*/
const NowJstTime = () => {
    /*今の日本時刻を取得する処理*/
    const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));

    let year   = ( '0000' + jstNow.getFullYear()).slice( -4 );
    let month  = ( '00'   + (jstNow.getMonth() + 1)).slice( -2 );
    let date   = ( '00'   + jstNow.getDate()).slice( -2 );
    let hour   = ( '00'   + jstNow.getHours()).slice( -2 );
    let minute = ( '00'   + jstNow.getMinutes()).slice( -2 );
    let second = ( '00'   + jstNow.getSeconds()).slice( -2 );
    return (year+month+date+hour+minute+second)//20220902111008
}

/*
    概要：日付から文字列に変換する関数
*/
const  getStringFromDate = (date) => {
    let year_str   = date.substr(0,4);
    let month_str  = date.substr(4,2);
    let day_str    = date.substr(6,2);
    let hour_str   = date.substr(8,2);
    let minute_str = date.substr(10,2);

    let format_str = 'YYYY/MM/DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);

    return format_str;//20220902111008
}

/*
    概要：APIGatewayを通じてメールを送信する関数
*/
const AdminSubmitRestFunc  = async(Title, showMessage) =>{
    const apiName = "SafetyConfirmRest";
    const path    = "/adminSubmit";

    const myInit = {
        response: true,
        queryStringParameters: {
            "Title": Title
        }
    };

    await API.get(apiName, path, myInit)
    .then(response => {
        if(response.data.ResultStatus===false)
        {
            //Lambda関数内でエラーが発生した時など
            throw new Error(response.data.ResultLog);
        }
    })
    .catch(e => {
        //APIGatewayを通過する部分でエラーが発生したときなど
        console.error(e);
        showMessage({message: "Invoke API Error"});
    });
}

/*
    概要：注意事項の変更、追加
*/
const PrecautionChange = (id, value, Precautions, setPrecautions) => {
    setPrecautions({...Precautions, [id]: value})
}

/*
    概要：注意事項の生成
*/
const PrecautionAdd = (id, Precautions, setPrecautions) => {
    setPrecautions({...Precautions, [id]: ""})
}

/*
    概要：注意事項の削除する関数
*/
const PrecautionDelete = (id, Precautions, setPrecautions) => {
    let NewPrecautions = JSON.stringify(Precautions);
    NewPrecautions = JSON.parse(NewPrecautions);
    delete NewPrecautions[id];
    setPrecautions(NewPrecautions);
}

/*
    概要：注意事項の複数削除する関数
*/
const PrecautionListDelete = (idList, Precautions, setPrecautions) => {
    let NewPrecautions = JSON.stringify(Precautions);
    NewPrecautions = JSON.parse(NewPrecautions);
    idList.forEach((id)=>{
        delete NewPrecautions[id]
    });
    setPrecautions(NewPrecautions);
}
    
/*
    概要： 登録ボタンをクリックしたときに、注意事項部分が空欄であるときには、削除します。
*/
const deleteblankPrecaution = (Precautions, setPrecautions) => {
    let deletePrecautionIds = [];
    Object.keys(Precautions).forEach((value)=>{
        if(Precautions[value] === ""){//注意事項欄の部分が空欄だった場合に処理
            deletePrecautionIds.push(value);
        }
    });
    PrecautionListDelete(deletePrecautionIds, Precautions, setPrecautions);
}
    
    
    
/*
    概要：入力漏れの確認
*/
const InputCheck = (Title) => {
    if(Title === ""){ 
        return false
    }
    else{
        return true
    }
}

/*
    概要：
        登録ボタンクリック時の処理
        入力漏れがなければ次へ進む.入力漏れがあれば表示する.
*/
const RegisterBtnClickFunc = (args) => {
    const {Title, setInputError, Precautions, setPrecautions, setPreviewFlag} = args;
    if(!InputCheck(Title)){//入力漏れがあり
        setInputError(true);
    }
    else{//入力漏れがなし  
        deleteblankPrecaution(Precautions, setPrecautions);
        setPreviewFlag(true);
        setInputError(false)
    }
}


 /*
    概要：
        customテーブルにデータを登録する処理
 */
const CustomCreate = async(Title, Precautions, InsertTime) => {
    const ulid  = monotonicFactory();//ulidの生成
    let id      = ulid();
  
    //Precautionsのvalueの値をリスト配列に入れます
    let Precautions_array = [];
    Object.keys(Precautions).map((id)=>{
        Precautions_array.push(Precautions[id]);
    })
   
    let datetime   = String(InsertTime);
    let CreateCustomInput = {
        "id"         : id,
        "datetime"   : datetime,
        "title"      : Title,
        "precautions": JSON.stringify(Precautions_array),
        "typeid"     : "2"
    }
    
    try{
        await API.graphql(graphqlOperation(createCustom, {createCustomInput: CreateCustomInput}));
    }
    catch(e){
        console.error(e);
        throw new Error("データ登録時エラー");
    } 
}

/*
    概要：
        みなさんに通知される方の登録ボタンをクリックしたときの処理
        データベースに登録する。
        通知を全員に送信する。
*/
const NoticeBtnFunc = async(Title, Precautions, InsertTime, setloading, showMessage, setCompleteViewFlag, setPreviewFlag) => {
    // ローディング画面表示
    setloading(true);
    try{
        // データベースへの登録処理
        await CustomCreate(Title, Precautions, InsertTime);

        // RestAPIでユーザへと通知を送る
        await AdminSubmitRestFunc(Title, showMessage);

        // 登録完了モーダルを表示
        setCompleteViewFlag(true);
        // プレビュー画面を閉じます
        setPreviewFlag(false);
    }
    catch(e){
        console.error(e);
        showMessage({message:e.messsage});
    }
    finally{
        // ローディング画面閉じる
        setloading(false);
    }
}


/**コンポーネント群***********************************************************************************************************************************************/

/*
    概要：登録完了画面コンポーネント(送信完了画面)
*/
const CompleteView = memo((props) => {
    const {setCompleteViewFlag} = props;
    const history = useHistory();
    /*
        概要：ホームへ戻るボタンをクリックしたときの処理
    */
    const Gohome = () => {
        setCompleteViewFlag(false)
        history.push("/safetyconfirm")
    }

    return (
        <div style={{display:"flex", flexDirection:"column", height:"100vh", background:"#fff", width:"100%"}}>
            <TourokuOK
                marginTop    = {"190px"}
                marginLeft   = {"auto"}
                marginRight  = {"auto"}
            />
            <div style={{width:"100%", minHeight:"fit-content", marginTop:"auto",marginBottom:"22px"}}>
                <BtnText
                    overrides={
                        {
                            "text":{
                                children: "ホームへ戻る"
                            }
                        }
                    }
                    width = {"90%"}
                    marginLeft = {"auto"}
                    marginRight= {"auto"}
                    onClick = {()=>{Gohome()}}
                />
            </div>
        </div>
    )
});

/*
    概要： タイトル入力部分
*/
//T.B.D.タイトルの時間部分が変更できないようにした関係で時間部分が動かない。
const TitleForm = (props) => {
    const {Title, setTitle, TitleTime} = props;
    return(
        <div>
            <Input02
                overrides = {
                    {
                        "text36392805":{
                            children: [
                                <span style={{display:"flex", justifyContent:"space-between", width:"90vw",gap: "6px 5px"}} key="admin">
                                    <span style={{display:"inline-flex", font: "inherit", width:"150px", backgroundColor:"#F5F5F5", whiteSpace:"nowrap", paddingTop:"5px"}}>{TitleTime}</span>
                                    <input type="text" placeholder="" value={Title} onChange={(e) => setTitle(e.target.value)} style = {{font: "inherit", border: "none", outline: "none", width:"90vw",backgroundColor:"#F5F5F5"}}/>
                                </span>
                            ]
                        },
                        "hissu":{
                            display:"none"
                        }
                    }
                }
                status = "EmployeeNumber"
                kome ="on"
                width = {"90vw"}
                style = {{margin:"auto",marginBottom:"40px"}}
            />
        </div>
    )
}

/*
    概要： 注意事項の入力欄
*/
const PrecautionForm = (props) => {
    const {Precautions, setPrecautions}   = props;
    const ulid = monotonicFactory();//ulidの生成

    return(
        <div>
            <div style = {{"display":"Flex"}}>
                <BtnAdd
                    width        = {"90%"}
                    marginLeft   = {"auto"}
                    marginRight  = {"auto"} 
                    marginBottom = {"8px"}
                    property1    = "Attention"
                    onClick = {()=>{PrecautionAdd(ulid(), Precautions, setPrecautions)}}
                />
            </div>

            <div style= {{height:"fit-content", minHeight:"50vh"}}>
                {
                    Object.keys(Precautions).map((ID)=>
                        <div key = {ID} style={{marginBottom:"5px"}}>
                            <ListAttention
                                overrides={
                                    {
                                        "MyIcon":{
                                            onClick: ()=>{PrecautionDelete(ID, Precautions, setPrecautions)}
                                        },
                                        "text":{
                                            display:"block",
                                            fontSize:"12px",
                                            marginBottom:"13px",
                                            children : [<input type="text" key = {ID+"ListAttentiontext"} placeholder="注意事項を入力" value={Precautions[ID]} onChange={(e) => PrecautionChange(ID, e.target.value, Precautions, setPrecautions)} style = {{border: "none", outline: "none", width:"80vw", backgroundColor:"white"}}/>]
                                        }
                                    }
                                }
                                width={"90%"}
                                margin={"0 auto"}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

/*
    概要：安否項目送信の入力ページ
*/
const  AdminSubmitForm = () => {
    const {Title, setTitle, TitleTime, Precautions, setPrecautions, setPreviewFlag}  = useAdminSubmitContext();
    const [InputError, setInputError] = useState(false)//入力漏れ
    const history = useHistory();

    useEffect(()=>{
        if(Title !== "" && InputError)
        {
            setInputError(false);
        }
    },[Title])

    return (
    <div>
        {/*ヘッダー部分*/}
        <TopHeader
            text        = "安否確認項目送信"
            clickFunc   = {()=>{history.push("/safetyconfirm")}}
            status      = {"Anpikoumoku"}
            paddingTop  = "16px"
            marginBottom= "32px"
        />
        {/*タイトル入力欄*/}
        <TitleForm
            Title     = {Title}
            setTitle  = {setTitle}
            TitleTime = {TitleTime}
        />
        {/*注意事項入力欄*/}
        <PrecautionForm
            Precautions    = {Precautions}
            setPrecautions = {setPrecautions}
        />
        {/*登録ボタン*/}
        <MultiButton
            CallBackFunc   = {RegisterBtnClickFunc}
            omission       = {InputError}
            text           = {"登録"}
            pattern        = {"default"}
            Title          = {Title}
            setInputError  = {setInputError}
            setPreviewFlag = {setPreviewFlag}
            Precautions    = {Precautions}
            setPrecautions = {setPrecautions}
        />
    </div>
    )
}


/*  
    概要：プレビューページ
*/
const PreviewModal = () => {
    const {Title, Precautions, setCompleteViewFlag, setPreviewFlag, setloading, InsertTime} = useAdminSubmitContext();
    const { showMessage } = useMessage();

    return (
        <div>
            {/*プレビュー画面*/}
            <PreviewComponent/>
            {/*こちらの内容がみなさんに通知されますの画面*/}
            <LayoutTuchikakunin
                overrides={
                    {
                        "btnSmall34992829":{//修正
                            onClick: () => {setPreviewFlag(false)}
                        },
                        "btnSmall34992828":{//登録
                            onClick: () => {
                                NoticeBtnFunc(
                                    Title, 
                                    Precautions, 
                                    InsertTime, 
                                    setloading, 
                                    showMessage, 
                                    setCompleteViewFlag,
                                    setPreviewFlag
                                )
                            }
                        }
                    }
                }       
                width = {"100%"}
                position = "absolute"
                bottom = "0px"
            />
        </div>
    )
}


/*
    概要：Previewコンポーネントにタイトルと注意事項を入れる
*/
const PreviewComponent = () => {
    const {Title, TitleTime, Precautions}  = useAdminSubmitContext();

    const PreviewTitle = TitleTime + " "+ Title;

    return (
        <div style = {{backgroundColor:"#F5F5F5",width:"100%"}}>
            <div >
                <header>
                    <HeaderAnpi
                        width={"100vw"}//画像幅レスポンス
                    />
                </header>
                <div style = {{paddingTop:"25px"}}>
                    <Top01
                        width  = {"90%"}
                        style = {{margin:"auto", marginBottom:"24px"}}
                        overrides = {
                            {
                                "2022/04/15 11:50 \u611B\u77E5\u770C \u9707\u5EA65\u5F37":{
                                    children: PreviewTitle
                                }
                            }
                        }
                    />
                    <TxtAnpihoukoku
                        width = {"90%"}
                        style = {{margin:"auto", marginBottom:"8px"}}
                    />
                    <div style = {{display: "Flex", justifyContent: "space-between", margin:"auto", width:"90%", marginBottom:"24px"}}>
                        <BtnBuji
                            width = {"48.6%"}
                        />
                        <BtnHigai
                            width = {"48.6%"}
                        />
                    </div>
                    <div>
                        <Form style={{width:"100%"}}>
                            <RemarkText
                                style = {{marginBottom: "8px",marginLeft: "5%",marginTop: "8px"}}
                            />
                            <Form.Group className='lg' controlId="exampleForm.ControlInput1" style={{width: "90%", margin: "auto"}}>
                                <Form.Control 
                                    type="text"
                                    as="textarea"
                                    name="comment"
                                    rows={2}
                                    style={{width:"100%"}}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div>
                        {
                            Object.keys(Precautions).map((id)=>
                                <TxtAttention
                                    overrides={
                                        {
                                            "\u203B \u4F59\u9707\u306B\u6C17\u3092\u3064\u3051\u3066\u304F\u3060\u3055\u3044 \u203B \u7121\u7406\u306F\u3057\u306A\u3044\u3067\u304F\u3060\u3055\u3044":
                                            {
                                                children: "※ "+Precautions[id]
                                            }
                                        }
                                    }
                                    height = {"18px"}
                                    width  = {"90%"}
                                    margin = {"auto"}
                                    key    = {id}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}