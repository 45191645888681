/*
    概要：管理者ページのコンポーネント
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect } from "react";
import { DeleteUserSetting } from "../../organisms/AdminLayout/DeleteUserSetting";//ユーザ削除モーダル
import { EditUserSetting } from "../../organisms/AdminLayout/EditUserSetting";//ユーザ編集モーダル
import { InsertUserSetting } from "../../organisms/AdminLayout/InsertUserSetting";//ユーザ追加モーダル
import { useHistory } from "react-router-dom";
import {
  TxtShindo,       //震度
  InputShindochi,  //震度値
  TxtKnansokuchi,  //観測値
  InputKansokuchi, //観測地を選択する
  TxtUser,         //ユーザ
  BtnAdd ,
}
from '../../../ui-components';
import { LocalArea_reverse } from "../../../setting/PrefectureJIS";
import { UserSettingTable } from "../../organisms/AdminLayout/UserSettingTable";
import { ObservationSelect } from "../../organisms/AdminLayout/ObservationSelect";
import { useAdminData } from "../../../hooks/useAdminData";
import { monotonicFactory } from 'ulid';
import { ObservationSelected } from "../../organisms/AdminLayout/ObservationSelected";
import { EarthquakeIntensitySelectModal } from "../../organisms/modal/EarthquakeIntensitySelectModal";
import { useLoginUser } from "../../../hooks/providers/useLoginUserProvider";
import { RingLoadingModalWhite } from "../../organisms/loading/RingLoadingModalWhite";
import { TopHeader } from "../../molecules/top/TopHeader";
import { useAdminContext} from "../../../hooks/providers/useAdminProvider";

export const Admin = memo(() => {
    const ulid = monotonicFactory()//ulidの生成
    const { GetData, fetchAndSetSettingUsers, fetchAndSetSeismicIntensity, fetchAndSetSpot, seismicIntensity, spot, UserList, loading, setLoading } = useAdminData();//customhook
    const {
        InsertShow    , setInsertShow    ,
        DeleteShow    , setDeleteShow    ,
        EditShow      , setEditShow      ,
        InsertKey     , setInsertKey     ,
        EditKey       , setEditKey       , 
        DeleteSetting , setDeleteSetting ,
        EditSetting   , setEditSetting   ,
        EarthquakeIntensitySelectShow, setEarthquakeIntensitySelectShow,
        ObservationSelectShow        , setObservationSelectShow
    } = useAdminContext();


    const [open, setopen] = useState(false);//描画の許可
    const { loginUser } = useLoginUser();//useContext


    //ユーザ追加のモーダルのkeyを破壊する
    const BrokenInsertKey = () => {
        setInsertKey(ulid())
    }

    //ユーザ編集のモーダルのkeyを破壊する
    const BrokenEditKey = () => {
        setEditKey(ulid())
    }

    //初期描画時に実行する
    useEffect(()=>{
        GetData()
    },[])

    useEffect(()=>{
        if(seismicIntensity&&spot&&UserList){
            setopen(true);
        }
    },[seismicIntensity, spot, UserList])


 
  

  if(open){
    return (
      <>
        {loading ? (
          <RingLoadingModalWhite/>
        ) : (
            <>
            {(() => {
                if(!(InsertShow || DeleteShow || EditShow || ObservationSelectShow)){
                    return (
                        <>
                            {/*管理者一覧ページ*/}
                            <AdminListPage
                                seismicIntensity = {seismicIntensity}
                                spot             = {spot}
                                UserList         = {UserList}
                            />
                            {/*震度値選択モーダル*/}
                            <EarthquakeIntensitySelectModal
                                EarthquakeIntensitySelectShow = {EarthquakeIntensitySelectShow}
                                setEarthquakeIntensitySelectShow = {(flag)=>setEarthquakeIntensitySelectShow(flag)}
                                setLoading = {(state) => setLoading(state)}
                                fetchAndSetSeismicIntensity = {()=>fetchAndSetSeismicIntensity()}
                                seismicIntensity = {seismicIntensity}
                            />
                        </>
                    )
                }
                else if(InsertShow)
                {
                    return (
                        <>
                            {/*ユーザ追加モーダル*/}
                            <InsertUserSetting
                                key = {InsertKey}
                                InsertShow= {InsertShow}
                                setInsertShowFalse={() => setInsertShow(false)}
                                fetchAndSetSettingUsers= {() => fetchAndSetSettingUsers()}
                                setLoading = {(state) => setLoading(state)}
                                BrokenInsertKey = {()=>BrokenInsertKey()}
                            />
                        </>
                    )

                }
                else if(DeleteShow)
                {
                    return (
                        <>
                            {/*ユーザ削除モーダル*/}
                            <DeleteUserSetting
                                DeleteShow= {DeleteShow}
                                setDeleteShowFlase={() => setDeleteShow(false)}
                                DeleteSetting={DeleteSetting}
                                fetchAndSetSettingUsers= {()=> fetchAndSetSettingUsers()}
                                setLoading = {(state) => setLoading(state)}
                            />
                        </>
                    )
                }
                else if(EditShow)
                {   
                    return(
                        <>
                            {/*ユーザ編集モーダル*/}
                            <EditUserSetting
                                key = {EditKey}
                                EditShow= {EditShow}
                                setEditShowFalse={() => setEditShow(false)}
                                EditSetting= {EditSetting}
                                fetchAndSetSettingUsers= {()=> fetchAndSetSettingUsers()}
                                setLoading = {(state) => setLoading(state)}
                                BrokenEditKey = {()=> BrokenEditKey()}
                            />
                        </>
                    )
                }
                else if(ObservationSelectShow)
                {
                    return (
                        <>
                            {/*観測地選択モーダル*/}
                            <ObservationSelect
                                ObservationSelectShow = {ObservationSelectShow}
                                setobservationSelectShow = {(flag)=>setObservationSelectShow(flag)}
                                spot = {spot}
                                fetchAndSetSpot = {()=> fetchAndSetSpot()}
                                setLoading = {(state) => setLoading(state)}
                            />
                        </>
                    )
                }
                else
                {
                    return null;
                }
            })()}
            </>
        )}
        </>
    );
  }
  else{
    return <RingLoadingModalWhite/>;
  }
});


/*コンポーネント群******************************************************************************************************************************/
const AdminListPage = (props) => {
    const {seismicIntensity, spot, UserList} = props;
    const {
        InsertShow                   , setInsertShow                   ,
        DeleteShow                   , setDeleteShow                   ,
        EditShow                     , setEditShow                     ,
        InsertKey                    , setInsertKey                    ,
        EditKey                      , setEditKey                      ,
        DeleteSetting                , setDeleteSetting                ,
        EditSetting                  , setEditSetting                  ,
        EarthquakeIntensitySelectShow, setEarthquakeIntensitySelectShow,
        ObservationSelectShow        , setObservationSelectShow
    } = useAdminContext();
    const history = useHistory();

    /*
        概要：編集モーダルの表示、非表示の管理と引数の受け渡し
    */
    const setEditShowAndArgs = (flag, Args) =>{
        setEditShow(flag)
        setEditSetting(Args)
    }

    /*
        概要：削除モーダルの表示、非表示の管理と引数の受け渡し
    */
    const setDeleteShowAndArgs = (flag, Args) =>{
        setDeleteShow(flag)
        setDeleteSetting(Args)
    }

 
    return (
        <div style = {{display: "Flex", flexDirection: "column", height: "fit-content", minHeight:"100vh", backgroundColor: "#F5F5F5"}}>
            {/*ヘッダー*/}
            <TopHeader
                clickFunc    = {()=>{history.push("/safetyconfirm")}}
                status       = {"KanriSetting"}
                paddingTop   = {"16px"}
                width        = {"90vw"}
                marginLeft   = {"auto"}
                marginRight  = {"auto"}
                marginBottom = {"24px"}
                marginTop    = {"0px"}
                text         = {"管理者設定"}
            />
            <main>
                {/*震度設定コンポーネント*/}
                <SeismicIntensityComponent
                    setEarthquakeIntensitySelectShow = {setEarthquakeIntensitySelectShow}
                    seismicIntensity                 = {seismicIntensity}
                />
       
                {/*観測地設定コンポーネント*/}
                <ObservationComponent
                    setObservationSelectShow = {setObservationSelectShow}
                    spot                     = {spot}
                />
     
                {/*ユーザリストコンポーネント*/}
                <div style = {{display:"Flex", width:"90vw", margin:"auto", justifyContent: "space-between", marginBottom:"8px"}}>
                    <TxtUser
                    />
                    <BtnAdd
                        onClick = {()=>{setInsertShow(true)}}
                    />
                </div>

                {/*ユーザ一一覧部分*/}
                <UserSettingTable
                    InsertShow= {InsertShow}
                    setInsertShow={(flag) => setInsertShow(flag)}
                    DeleteShow= {DeleteShow}
                    setDeleteShow={(flag) => setDeleteShow(flag)}//ユーザIDだけ渡す
                    EditShow= {EditShow}
                    setEditShow={(flag) => setEditShow(flag)}//ユーザのデータをすべて渡す
                    UserList= {UserList}
                    setEditShowAndArgs={(flag, Args) => setEditShowAndArgs(flag, Args)}
                    setDeleteShowAndArgs={(flag, Args) => setDeleteShowAndArgs(flag, Args)}
                />    
            </main>
        </div>
    )
}

/*
    概要：観測地コンポーネント
*/
const ObservationComponent = (props) => {
    const {setObservationSelectShow, spot} = props;
    let observation = ""
    if(spot === ""){
        //観測地コンポーネント(観測地が設定されていないとき)
        observation = <InputKansokuchi
                        setObservationSelectShow = {(flag) => setObservationSelectShow(flag)}
                        style = {{marginLeft:"5%", marginBottom:"34px"}}
                        spot = {spot}
                    />
    }
    else{
        //観測地コンポーネント(観測地の登録がされているとき)
        observation = <ObservationSelected
                        setObservationSelectShow = {(flag) => setObservationSelectShow(flag)}
                        spot = {spot}  
                    />
    }
    return (
        <div style = {{marginBottom:"32px"}}>
            {/*通知する観測地*/}
            <TxtKnansokuchi
                width       = {"90vw"}
                marginLeft  = {"auto"}
                marginRight = {"auto"}
                display     = {"block"}
                marginBottom= {"8px"}
            />
            {observation}
        </div>
        
    )
}

/*
    概要：震度値コンポーネント
*/
const SeismicIntensityComponent = (props) => {
    const {setEarthquakeIntensitySelectShow, seismicIntensity} = props;
    /*震度値の設定値からコンポーネントにどちらを使用するか判定する*/
    let seismicIntensityComponent = ""
    //震度値が設定されていないとき
    if(seismicIntensity === ""){
        seismicIntensityComponent = 
            <InputShindochi
                property1 = "Default"
                width = {"90vw"}
                style = {{margin: "auto"}}
                overrides = {
                    {
                    InputShindochi: {
                            onClick:()=>{setEarthquakeIntensitySelectShow(true)}
                        }
                    }
                }
            />
    }
    //設定されている震度値を取得したとき
    else{
        seismicIntensityComponent = 
            <InputShindochi
                property1 = "select"
                width = {"90vw"}
                style = {{margin: "auto"}}
                overrides = {
                    {
                        InputShindochi:{
                        onClick : () => {setEarthquakeIntensitySelectShow(true)}
                        },
                        text:{
                            children: seismicIntensity
                        }
                    }
                }
            />
    }
    return (
        <div style = {{marginBottom: "24px"}}>
            {/*通知する最低震度地*/}
            <TxtShindo
                width  = {"90vw"}
                margin = {"auto"}
            />
            {seismicIntensityComponent}
        </div>
    )

}


