/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import BtnOk from "./BtnOk";
export default function Layoutsituationcheckdetailicon01(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="184px"
      height="206px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Layoutsituationcheckdetailicon01")}
    >
      <View
        width="184px"
        height="206px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        borderRadius="16px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 36")}
      ></View>
      <Flex
        gap="32px"
        direction="column"
        width="104px"
        height="142px"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="15.53%"
        bottom="15.53%"
        left="21.74%"
        right="21.74%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 457")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 456")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 453")}
          >
            <MyIcon
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="higai_k"
              {...getOverrideProps(overrides, "MyIcon33141528")}
            ></MyIcon>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="自分に被害あり"
              {...getOverrideProps(
                overrides,
                "\u81EA\u5206\u306B\u88AB\u5BB3\u3042\u308A"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 455")}
          >
            <MyIcon
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="higai-family"
              {...getOverrideProps(overrides, "MyIcon33141530")}
            ></MyIcon>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="家族に被害あり"
              {...getOverrideProps(
                overrides,
                "\u5BB6\u65CF\u306B\u88AB\u5BB3\u3042\u308A"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 454")}
          >
            <MyIcon
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="higai-house"
              {...getOverrideProps(overrides, "MyIcon33141531")}
            ></MyIcon>
            <Text
              fontFamily=""
              fontSize="12px"
              fontWeight=""
              color="rgba(0,0,0,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="家に被害あり"
              {...getOverrideProps(
                overrides,
                "\u5BB6\u306B\u88AB\u5BB3\u3042\u308A"
              )}
            ></Text>
          </Flex>
        </Flex>
        <BtnOk
          width="80px"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "btnOk")}
        ></BtnOk>
      </Flex>
    </View>
  );
}
