/*
    概要：HowtoUsePagesで使用する機能のないページ。ユーザ一覧を表示。
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect,memo} from 'react';
import { monotonicFactory } from 'ulid';
import {
    ListKanri,
    ListKanriDetail,
}
from '../../../ui-components';
import { ConvPhoneNumber } from "../../../hooks/misc/common";

export const UserSettingTable = memo((props) =>{
  const ulid = monotonicFactory()//ulidの生成
  const UserListInit = {
    adminFlag: true,
    employeeNumber: 0,
    id: "1",
    mail:"take101@trafficsim.co.jp",
    mailStatus: true,
    name: "山田太郎",
    phone: "+819038589402",
    smsStatus: true
  }
  const [UserListOpen, setUserListOpen] = useState({1:UserListInit});//ユーザリストが開いているかどうかを管理する。
  const [update, setupdate] = useState(false);

  //ユーザの情報をリストで表示する
  const UserListDisplay = () =>{
      let UserInfoId   = null;
      let UserDeleteId = null;
      let UserEditId   = null;

      return(
          props.UserList.map((value,index)=>{
              if(index===0){
                UserInfoId = "UserInfo"
                UserDeleteId = "UserDelete"
                UserEditId = "UserEdit"
              }
              return(
                  <div key={ulid()}>
                      {/*ユーザ一覧画面の名前のみ*/}
                      <ListKanri
                          overrides = {
                              {
                                  "\u7AF9\u4E0B \u535A\u4E4B":{
                                      "children": value["name"]
                                  }
                              }
                          }
                          width = {"90vw"}
                          style = {UserListOpen[[value.id]] ? {display:"none", margin:"auto", marginBottom:"4px"}:{margin:"auto", marginBottom:"4px"}}
                          key={ulid()}
                      />
                      {/*ユーザ一覧画面の名前、メールアドレス、電話番号、削除、編集*/}
                      <ListKanriDetail
                          id={UserInfoId}
                          overrides = {
                              {
                                  "\u7AF9\u4E0B \u535A\u4E4B":{//名前
                                      children: value["name"]
                                  },
                                  "take101@trafficsim.co.jp":{//メールアドレス
                                      children: value["mail"]
                                  },
                                  "090-3858-9402":{//電話番号
                                      children: ConvPhoneNumber(value["phone"])
                                  },
                                  "Frame 1953":{//削除
                                        id: UserDeleteId
                                  },
                                  "Frame 194":{//編集
                                        id: UserEditId
                                  },
                              }
                          }
                          width = {"90vw"}
                          style = {UserListOpen[[value.id]] ? {display:"block", margin:"auto", marginBottom:"4px"}:{display:"none", margin:"auto", marginBottom:"4px"}}
                          key={ulid()}
                      />
                  </div>
              )
          })
  
      )
  }



  if (props.UserList) {
      return (
          <div id="UserList">
              <UserListDisplay/>
          </div>
      );
  } 
  else {
      return null;
  }
})