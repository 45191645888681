/*
    概要：Admin.jsの観測地選択部分(表示されている部分のコンポーネント)
*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, memo} from 'react';
import { useHistory } from 'react-router-dom';
import {LocalArea} from '../../../setting/PrefectureJIS'
import {
    BtnRegion,
    BtnPrefecture
}
from '../../../ui-components';

export const ObservationSelected = memo((props) =>{
    const {spot, setObservationSelectShow} = props;
    const [Areas, setAreas] = useState("");
    const [prefectures,setprefectures] = useState("")

    useEffect(()=>{
        ObservationFunc()
    },[spot])

    //取得した観測地からエリアと県で分ける
    const ObservationFunc = () => {
        let Areas       = [];
        let prefectures = [];
        Object.keys(spot).map((Area) => {
            if(spot[Area].length === LocalArea[Area].length){//一つの地域の県をすべて選択したのなら
                Areas.push(Area);
            }
            else{
                spot[Area].map((prefecture) => {
                    prefectures.push(prefecture);
                })
            }
        })
        setAreas(Areas);
        setprefectures(prefectures);
    }

    if(Areas&&prefectures){
        return (
            <div style={{width: "90%", margin: "auto"}} onClick = {()=>{setObservationSelectShow(true)}}>
                {(() => {
                    return(
                        [
                            Areas.map((Area)=>
                                <BtnRegion
                                    key = {Area} 
                                    overrides={
                                        {
                                            "\u95A2\u6771":{
                                                children: Area
                                            }
                                        }
                                    }
                                    display = {"inline-block"}
                                    style = {{marginLeft:"4px", marginBottom:"4px"}}
                                />
                            ),
                            prefectures.map((prefecture)=>
                                <BtnPrefecture
                                    key = {prefecture}
                                    overrides={
                                        {
                                            "\u611B\u77E5":{
                                                children: prefecture
                                            }
                                        }
                                    }
                                    display = {"inline-block"}
                                    style = {{marginLeft:"4px", marginBottom:"4px"}}
                                />
                            )
                        ]
                    )           
                })()}     
            </div>
        );
    }
    else{
        return null
    }
})