/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ListJoukyoukakunin01(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        day: {},
        name: {},
        MyIcon37242838: {},
        MyIcon37252848: {},
        "Frame 170": {},
        "Rectangle 30": {},
        MyIcon37242841: {},
        "Frame 1952": {},
        "Frame 2000": {},
        ListJoukyoukakunin01: {},
      },
      variantValues: { bg: "gray" },
    },
    {
      overrides: {
        day: {},
        name: {},
        MyIcon37242838: {},
        MyIcon37252848: {},
        "Frame 170": {},
        "Rectangle 30": {},
        MyIcon37242841: {},
        "Frame 1952": {},
        "Frame 2000": {},
        ListJoukyoukakunin01: { backgroundColor: "rgba(255,255,255,1)" },
      },
      variantValues: { bg: "white" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="10px 16px 10px 16px"
      backgroundColor="rgba(245,245,245,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ListJoukyoukakunin01")}
    >
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 2000")}
      >
        <Flex
          gap="17px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 170")}
        >
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(0,0,0,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="80px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="04/15 11:50"
            {...getOverrideProps(overrides, "day")}
          ></Text>
          <Text
            fontFamily=""
            fontSize="12px"
            fontWeight=""
            color="rgba(0,0,0,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="90px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="苗字苗字 名前"
            {...getOverrideProps(overrides, "name")}
          ></Text>
          <MyIcon
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="higai_k"
            {...getOverrideProps(overrides, "MyIcon37242838")}
          ></MyIcon>
          <MyIcon
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="line"
            {...getOverrideProps(overrides, "MyIcon37252848")}
          ></MyIcon>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="21px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 1952")}
        >
          <View
            width="1px"
            height="18px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(224,224,224,1)"
            {...getOverrideProps(overrides, "Rectangle 30")}
          ></View>
          <MyIcon
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="comment_b"
            {...getOverrideProps(overrides, "MyIcon37242841")}
          ></MyIcon>
        </Flex>
      </Flex>
    </Flex>
  );
}
