/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function DamageSituationDetail(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "DamageSituationDetail")}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        borderRadius="4px"
        padding="16px 16px 16px 16px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "detail")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "DamageSituation_detail")}
        >
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1994")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054672")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="回答者数"
                {...getOverrideProps(overrides, "text37054673")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054674")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054675")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054676")}
                ></Text>
              </Flex>
            </Flex>
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              {...getOverrideProps(overrides, "line37054677")}
            ></View>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1995")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054679")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="無事"
                {...getOverrideProps(overrides, "text37054680")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054681")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054682")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054683")}
                ></Text>
              </Flex>
            </Flex>
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              {...getOverrideProps(overrides, "line37054684")}
            ></View>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1996")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054686")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="自分の健康被害"
                {...getOverrideProps(overrides, "text37054687")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054688")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054689")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054690")}
                ></Text>
              </Flex>
            </Flex>
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              {...getOverrideProps(overrides, "line37054691")}
            ></View>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1997")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054693")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="家族の健康被害"
                {...getOverrideProps(overrides, "text37054694")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054695")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054696")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054697")}
                ></Text>
              </Flex>
            </Flex>
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              {...getOverrideProps(overrides, "line37054698")}
            ></View>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1998")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054700")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="家被害"
                {...getOverrideProps(overrides, "text37054701")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054702")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054703")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054704")}
                ></Text>
              </Flex>
            </Flex>
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              {...getOverrideProps(overrides, "line37054705")}
            ></View>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1999")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 199337054707")}
            >
              <Text
                fontFamily=""
                fontSize="12px"
                fontWeight=""
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="未回答"
                {...getOverrideProps(overrides, "text37054708")}
              ></Text>
              <Flex
                gap="4px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 199237054709")}
              >
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="40px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="00"
                  {...getOverrideProps(overrides, "number37054710")}
                ></Text>
                <Text
                  fontFamily=""
                  fontSize="12px"
                  fontWeight=""
                  color="rgba(0,0,0,1)"
                  lineHeight="20px"
                  textAlign="right"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="人"
                  {...getOverrideProps(overrides, "text37054711")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "line37054534")}
      ></View>
    </Flex>
  );
}
