/*AdminSubmitLayout*/
import { memo } from "react";

export const AdminSubmitLayout = memo((props) => {
  const { children } = props;
  return (
    <div style = {{backgroundColor:"#F5F5F5",minHeight:"100vh"}}>
      {children}
    </div>
  );
});

