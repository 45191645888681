/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Icon, View } from "@aws-amplify/ui-react";
export default function BtnToggle(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { "Rectangle 21": {}, "Ellipse 1": {}, BtnToggle: {} },
      variantValues: { toggle: "off" },
    },
    {
      overrides: {
        "Rectangle 21": { backgroundColor: "rgba(1,185,133,1)" },
        "Ellipse 1": { left: "22px" },
        BtnToggle: {},
      },
      variantValues: { toggle: "on" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="42px"
      height="22px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "BtnToggle")}
    >
      <View
        width="42px"
        height="22px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        borderRadius="11px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(160,160,160,1)"
        {...getOverrideProps(overrides, "Rectangle 21")}
      ></View>
      <Icon
        width="18px"
        height="18px"
        viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
        paths={[
          {
            d: "M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="2px"
        left="2px"
        {...getOverrideProps(overrides, "Ellipse 1")}
      ></Icon>
    </View>
  );
}
