// https://www.wantedly.com/users/26190108/post_articles/345447
import React, { createContext, useContext, useState } from "react";
import { AlertBar } from "../../components/atoms/card/AlertBar";

const ToastContext = createContext({});

// Toast情報を保持するcontext
export const ToastProvider = (props) => {
  const { children } = props;
  const [showToast, setshowToast] = useState(false);
  const [messageToast, setmessageToast] = useState("");

  return (
    <ToastContext.Provider value={{ showToast, setshowToast, messageToast, setmessageToast }}>
      {children}
      {showToast ? (
        <div>
            <AlertBar 
            />
        </div>
      ):(
        null
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
